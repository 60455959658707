.header {
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  @extend %default-transition;

  &.scrolled {
    height: var(--header-height-scrolled);
    background-color: var(--color-blue-dark);
    box-shadow: 0 5px 10px rgba(1, 1, 61, 0.2);

    .header__list {
      padding: 0;
    }

    .header__links {
      background-color: var(--color-blue-dark);
      box-shadow: 0 5px 10px rgba(1, 1, 61, 0.2);
    }
  }

  &.scrolled + .header__layer {
    top: calc(var(--header-height-scrolled));
    height: calc(100% - var(--header-height-scrolled));
  }

  .cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    position: relative;
    display: inline-block;
    width: adaptive-calc(231px, 146px);

    @include media(360) {
      width: 125px;
    }

    &:hover {
      img {
        transform: scale(1.02);
      }
    }

    img {
      width: 100%;
      height: auto;
      backface-visibility: hidden;
      will-change: transform;
      transition: all 0.7s;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__list {
    position: relative;
    // right: adaptive-calc(-25px, 0px);
    display: flex;
    height: 100%;

    padding-top: adaptive-calc(22px, 0px);
    @extend %default-transition;
  }

  &:not(&.scrolled) {
    &:not(&.open-menu) {
      .header__list {
        margin-right: 24px;
      }
    }
    .header__list-item:not(.header__list-item.active) {
      &:hover {
        .header__links {
          display: block !important;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &__list-item {
    height: 100%;
    display: inline-block;
    margin-right: adaptive-calc(40px, 30px);

    &:last-child {
      margin-right: 0;
    }

    &.active {
      span {
        color: var(--color-yellow-2);

        &::before {
          transform: rotate(90deg);
        }
      }

      .header__links {
        opacity: 1;
        visibility: visible;
      }
    }

    .upload-icon {
      margin-left: 7px;
      color: var(--color-yellow);
      font-size: 14px;

      @include media(1281) {
        font-size: 22px;
      }
    }

    .header__list-link {
      font-size: 17px;
      font-weight: 700;
      color: var(--color-white);
      @extend %default-transition;

      &:hover {
        color: var(--color-yellow-2);
      }

      @include media(1281) {
        font-size: adaptive-calc(33px, 22px);
        line-height: 1em;
        margin-bottom: 17px;
      }
    }

    div {
      position: relative;
      display: flex;
    }

    span {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 15px;
      cursor: pointer;
      @extend %default-transition;

      a {
        font-size: 17px;
        font-weight: 700;
        color: var(--color-white);
        @extend %default-transition;

        &:hover {
          color: var(--color-yellow-2);
        }
      }

      &:before {
        position: absolute;
        right: 0;
        content: "";
        border-left: 8px solid var(--color-yellow-2);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        @extend %default-transition;
      }
    }
  }

  //&__list-block:hover{
  //  transition: max-height .4s ease-in-out;
  //  .header__links{
  //    opacity: 1;
  //    max-height: 300px;
  //    transition: all .4s ease-in-out;
  //
  //    li{
  //      a{
  //        pointer-events: auto;
  //      }
  //    }
  //  }
  //}

  &__links {
    position: absolute;
    top: 100%;
    left: -8px;

    width: max-content;

    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    padding: 8px;

    @extend %default-transition;

    //max-height: 0px;
    //transition: all .4s ease-in-out;
    //
    //li{
    //  a{
    //    pointer-events: none;
    //  }
    //}

    li {
      width: 100%;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        white-space: nowrap;
        font-size: 16px;
        font-weight: 600;
        color: var(--color-white);
        @extend %default-transition;

        &:hover {
          color: var(--color-yellow-2);
        }
      }
    }
  }

  &__burger {
    position: relative;
    display: none;
    align-items: center;
    width: 41px;
    height: 19px;

    &::before,
    &::after,
    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-white);
      @extend %default-transition;
    }

    &::before {
      content: "";
      top: 0;
      will-change: transform;
    }

    &::after {
      content: "";
      bottom: 0;
      will-change: transform;
    }
  }
}

.header__layer {
  position: fixed;
  z-index: 11;
  top: var(--header-height);
  left: 0;
  display: none;
  width: 100%;
  height: calc(100% - var(--header-height));
  background-color: rgba(1, 1, 61, 0.5);
  opacity: 0;
  visibility: hidden;
  @extend %default-transition;
}

.footer {
  position: relative;
  width: 100%;
  background-color: var(--color-blue-dark);
  padding: adaptive-calc(88px, 54px) 0 adaptive-calc(64px, 65px) 0;

  .cont {
    display: flex;
    flex-wrap: wrap;
  }

  &__block {
    position: relative;
    width: 50%;
  }

  &__logo {
    width: fit-content;
    margin-bottom: adaptive-calc(40px, 21px);

    &:hover {
      img {
        transform: scale(1.05);
      }
    }

    img {
      width: adaptive-calc(294px, 146px);
      height: auto;
      will-change: transform;
      @extend %default-transition;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__left {
    width: 50%;
  }

  &__links {
    width: fit-content;
    margin-bottom: 29px;

    a {
      width: fit-content;
      font-size: 18px;
      line-height: 1.3333333333333333em;
      color: var(--color-white);
      margin-bottom: 24px;
      @extend %default-transition;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: var(--color-yellow-2);
      }
    }
  }

  &__text {
    width: 100%;

    & > a {
      width: fit-content;

      &:hover {
        p {
          color: var(--color-yellow-2);
        }
      }
    }

    p {
      width: fit-content;

      font-size: 18px;
      font-weight: 400;
      line-height: 1.6em;
      color: var(--color-white);
      @extend %default-transition;
    }
  }

  &__right {
    position: relative;
    top: -3px;
    width: 50%;
    padding-left: 4px;
  }

  &__menu {
    width: 100%;
    margin-bottom: 30px;

    li {
      width: fit-content;
      margin-bottom: 9px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-size: adaptive-calc(23px, 18px);
      font-weight: 300;
      color: var(--color-white);
      @extend %default-transition;

      &:hover {
        color: var(--color-yellow-2);
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    width: 100%;

    li {
      display: inline-block;
      margin-right: adaptive-calc(26px, 22px);

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      display: inline-block;
      will-change: transform;
      @extend %default-transition;
      transition: all 0.3s;

      i {
        display: flex;
        font-size: 20px;
        color: var(--color-yellow-2);
        transition: all 0.3s;
        @extend %default-transition;
      }

      &:hover i::before {
        transition: all 0.3s;
        color: var(--color-red);
      }

      &:active {
        transform: translateY(5px);
      }
    }
  }

  &__policy {
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: adaptive-calc(91px, 42px);
    margin-right: -10px;
    margin-bottom: -10px;

    div,
    a {
      position: relative;
      display: inline-block;
      width: fit-content;
      font-size: 14px;
      line-height: 2.1666666666666665em;
      color: var(--color-white);

      margin-right: 10px;
      margin-bottom: 10px;
    }

    div {
      left: -4px;
      margin-right: 62px;
    }

    .footer__policy-link-list {
      display: flex;
      flex-wrap: wrap;

      margin-bottom: -10px;
      margin-right: -10px;
      // flex-direction: column;
    }

    a {
      margin-bottom: 20px;
      margin-right: 10px;

      font-size: 13px;
      padding-left: 3px;
      @extend %default-transition;

      &:hover {
        color: var(--color-yellow-2);
      }
    }
  }

  &__form {
    width: 50%;
    padding: adaptive-calc(3px, 0px) 0 0 8px;

    div,
    h2 {
      color: var(--color-white);
    }

    div {
      width: 100%;
      font-size: adaptive-calc(44px, 24px);
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: -0.45px;
    }

    h2 {
      font-weight: 600;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-top: adaptive-calc(28px, 53px);

      input,
      textarea {
        width: 49%;
        height: 36px;
        font-size: 18px;
        line-height: 1.3333333333333333em;
        color: var(--color-white);
        border: none;
        border-bottom: 2px solid var(--color-white);
        background-color: transparent;
        padding: 0 0 12px 0;
        margin-top: 30px;
        @extend %default-transition;
        @include transparent-bg-input($textColor: #fff);

        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }

        &::placeholder {
          @extend %default-transition;
        }

        &:focus {
          border-color: var(--color-yellow-2);
        }

        &:focus::placeholder {
          color: var(--color-yellow-2);
        }
      }

      textarea {
        width: 100%;
        height: adaptive-calc(84px, 141px);
      }

      button.size-2 {
        font-size: adaptive-calc(18px, 12px);
        font-weight: 600;
        color: var(--color-white);
        margin: adaptive-calc(34px, 26px) 0 0 auto;

        span {
          margin-left: adaptive-calc(15px, 12px);
        }
      }
    }
  }

  &__credits {
    max-width: 150px;
    width: 100%;
    margin: 50px auto 0;
    height: auto;

    transition: transform 300ms ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}
.header.scrolled {
  :root {
    --header-height: 140px;
    --header-height-scrolled: 112px;
  }

  .header__socials {
    padding-top: 0;
  }

  & + .header__layer {
    display: block;
  }

  .header__menu {
    margin-left: auto;
    margin-right: 20px;
  }

  .header__list {
    top: var(--header-height-scrolled);
    height: calc(100% - var(--header-height-scrolled));
    padding: 59px 20px 59px 50px;
    margin-right: 0;
    width: 580px;

    @include media(769) {
      width: 100%;
    }
  }

  &.open-menu {
    .header__list {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      display: flex;
      flex-direction: column;
      margin-right: 0;
      align-items: flex-start;
    }

    .header__burger {
      &::before {
        top: 9px;
        transform: rotate(45deg);
      }

      &::after {
        bottom: 9px;
        transform: rotate(-45deg);
      }

      span {
        opacity: 0;
        visibility: hidden;
        left: 20px;
      }
    }
  }

  &.open-menu + .header__layer {
    opacity: 1;
    visibility: visible;
  }

  .header__list {
    position: fixed;
    // top: var(--header-height);
    right: 0;
    display: block;
    // width: 580px;
    // height: calc(100% - var(--header-height));
    padding: 59px 20px 59px 50px;
    background-color: var(--color-blue-dark);
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    will-change: transform;
    transform: translateX(580px);
    @extend %default-transition;
  }

  .header__list-item {
    width: fit-content;
    height: auto;
    margin-right: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    div {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &:not(&.active) {
      span:hover {
        &::before {
          transform: scale(1.5);
        }
      }
    }

    span {
      padding-right: 28px;
      margin-bottom: 17px;

      a {
        // font-size: 33px;
        font-size: adaptive-calc(33px, 22px);
        line-height: 1em;
      }

      &::before {
        border-left-width: 12px;
        border-top-width: 7px;
        border-bottom-width: 7px;
        margin-top: 5px;

        transition: transform ease 250ms;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .header__links {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    display: none;
    // text-align: center;
    padding: 0;
    opacity: 1;
    visibility: visible;
    transition: none;

    li {
      margin-bottom: 9px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: adaptive-calc(33px, 22px);

        font-weight: 400;
      }
    }
  }

  .header__list-link {
    font-size: adaptive-calc(33px, 22px);
    line-height: 1em;
    margin-bottom: 17px;
  }

  .upload-icon {
    font-size: 22px;
  }

  .header__burger {
    display: flex;
  }
}

.header__socials {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: adaptive-calc(22px, 0px);

  @include media(1281) {
    padding-top: 0;
  }

  li {
    font-size: 20px;

    &:not(:last-child) {
      margin-right: 14px;
    }

    @include media(551) {
      font-size: 16px;
    }
  }

  a {
    &:hover {
      i {
        &::before {
          color: var(--color-green);
        }
      }
    }
  }

  i {
    &::before {
      color: var(--color-white);

      transition: color 250ms ease;
    }
  }
}
