@use "sass:math";

@media only screen and (max-width: 1400px) {
  .show {
    &__text-block {
      max-width: 860px;
    }
  }
}

@media only screen and (max-width: 1281px) {
  .show {
    &__text-block {
      flex-direction: column;
      max-width: 700px;
      text-align: center;
      align-items: center;
    }

    &__title {
      max-width: 100%;
    }

    &__text {
      max-width: 100%;
    }
  }

  .career {
    &__inner {
      margin-left: 60px;
    }
  }
}

@media only screen and (max-width: 1025px) {
  .career {
    &__inner {
      margin-left: 40px;
    }
  }
}

@media only screen and (max-width: 901px) {
  .title-box {
    h2 {
      width: 100%;
      margin-bottom: adaptive-calc(12px, 9px);
    }
    &__cont {
      width: 100%;
      padding: 0;
    }
  }

  .career {
    &__inner {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }

    &__text {
      max-width: 100%;
      margin-right: 0px;
      margin-left: 40px;
    }
    &__video {
      max-width: 460px;
      margin-top: 35px;
      clip-path: polygon(
        7% 0,
        94% 0,
        100% 12%,
        100% 80%,
        100% 100%,
        6% 100%,
        0 90%,
        0 0
      );
    }
    .plyr--video {
      clip-path: polygon(
        7% 0,
        94% 0,
        100% 12%,
        100% 80%,
        100% 100%,
        6% 100%,
        0 90%,
        0 0
      );
    }
  }
}

@media only screen and (max-width: 769px) {
  .show {
    .show-dec {
      display: none;
    }
  }

  .career {
    .career-dec {
      display: none;
    }
    &__text {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 651px) {
}

@media only screen and (max-width: 551px) {
}

@media only screen and (max-width: 450px) {
  .show {
    .plyr video {
      // height: 210px;
    }
  }

  .career {
    .plyr video {
      // height: 200px;
    }
  }
}

@media only screen and (max-width: 421px) {
  .show {
    .plyr video {
      // height: 190px;
    }
  }
  .career {
    .plyr video {
      // height: 180px;
    }
  }
}

@media only screen and (max-width: 361px) {
  .show {
    .plyr video {
      // height: 170px;
    }
  }

  .career {
    .plyr video {
      // height: 170px;
    }
  }
}
