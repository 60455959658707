@use "sass:math";

@media only screen and (max-width: 1281px) {
}

@media only screen and (max-width: 1025px) {
  .details {
    &__inner {
      flex-direction: column-reverse;
    }
    &__descr {
      max-width: 100%;
      margin-right: 0px;
    }
    &__info {
      display: flex;
      max-width: 100%;
      margin-top: 0px;
      margin-bottom: 50px;
    }
    &__boxes {
      margin-top: 0;
    }
    &__person-box {
      margin-right: 110px;
    }

    &__box-local {
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 901px) {
  .details {
    &__info-card {
      // max-width: 235px;
    }
    &__person-box {
      margin-right: 70px;
    }

    &__local-img {
      img {
        max-width: 100%;
      }
    }
  }

  .community-signup {
    &__inner {
      flex-direction: column;

      .signup-section__title {
        width: 100%;
      }
    }

    &__form-inner {
      margin: 10px 0px 0 0px;
      width: 100%;
    }
  }

  .head-resource {
    .head__cont {
      flex-direction: column;

      h1 {
        width: 100%;
      }
    }

    &__box {
      margin-top: 20px;
      margin-left: 0px;
    }
  }

  .resource {
    &__inner {
      margin-top: 50px;
      flex-direction: column;
    }
    &__descr {
      max-width: 100%;
      margin-right: 0px;
      margin-top: initial;
    }

    &__info {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      max-width: 100%;
      right: 0px;
      width: 100%;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: adaptive-calc(32px, 22px);
      padding-bottom: 30px;
      flex-direction: row;
    }
    &__info-card {
      margin-right: 0;
      margin-top: 0;
      // max-width: 278px;
      /*width: 100%;*/

    }

    &__box {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 769px) {
  .details {
    &__person-box {
      margin-right: 40px;
    }
  }

  .resource {
    &__inner {
      margin-top: 50px;
      flex-direction: column;
    }
    &__descr {
      max-width: 100%;
      margin-right: 0px;
      margin-top: initial;
    }

    &__info {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      max-width: 100%;
      right: 0px;
    }
    &__info-card {
      /*margin-right: 70px;
      margin-top: 40px;*/
      max-width: 278px;
      width: 100%;
    }

    &__box {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 651px) {
  .details {
    &__info {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 50px;
    }
    &__person-box {
      // display: flex;
      margin-right: 30px;
    }
    &__info-card {
      margin-right: 40px;
    }

    &__boxes {
      display: flex;
    }

    &__box-candidate {
      margin-top: 20px;
      margin-right: 30px;
    }
  }

  .resource {
    &__info {
      margin-top: 20px;
    }
    /*&__info-card {
      margin-right: 30px;
      margin-top: 30px;
    }*/

    &__box {
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 551px) {
  .details {
    &__boxes {
      flex-direction: column;
      max-width: 100%;
      width: 100%;
    }
    &__box-local {
      max-width: 100%;
      width: 100%;
    }
    &__local-img {
      img {
        width: 100%;
      }
    }
    &__box-candidate {
      max-width: 100%;
    }
    &__info-card {
      margin-bottom: 20px;
    }
    &__box-candidate {
      margin-top: 20px;
      margin-right: 0px;
      width: 100%;
    }
  }

  .resource {
    &__info {
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
    }
   /* &__info-card {
      margin-right: 0px;
      margin-top: 30px;
    }*/

    &__box {
      margin-top: 30px;
      max-width: 278px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 421px) {
  .details {
    &__person-box {
      margin-right: 0;
      flex-direction: column;
    }
  }

  .resource {
    &__info-card {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 361px) {
}
