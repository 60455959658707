// Input Wrap
//  <div class="input-wrap">
//  	<p>Name</p>
//      <input type="text" placeholder="Enter your name">
//  </div>

.input-wrap {
  p {
    padding-bottom: 5px;
  }

  input,
  textarea {
    width: 100%;
  }
}

// Default Input / Textarea

input,
textarea {
  position: relative;

  border-radius: 0;

  padding: 5px 10px 15px;
  width: 100%;
  resize: none;
  background-color: transparent;
  border-bottom: 2px solid var(--white);
  color: var(--white);
  transition: 0.3s ease-in-out all;
  font-family: var(--font-main);
  font-size: 18px;

  &::placeholder {
    color: var(--white);
  }

  &:focus {
    border-color: var(--primary);
  }

  @include transparent-bg-input();
}

textarea {
  height: 100px;
}

// Select 2

.select-item {
  select {
    // display: none;
  }
}

.select2-results__option {
  font-family: var(--font-main);
  @extend %default-transition;
  background-color: var(--color-white);
  color: var(--color-blue-dark) !important;
  &:hover {
    background-color: var(--color-yellow-2) !important;
    color: var(--color-blue-dark) !important;
  }
}
.select2-container--default .select2-results__option--selected {
  background: var(--blue);
}
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background: var(--blue);
  color: var(--white);
}
.select2-container--default .select2-results > .select2-results__options {
  background: var(--black);
  color: var(--white);
  border: none;
}
.select2-container--open .select2-dropdown--below {
  border: none;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  border: 2px solid var(--color-blue-2);
  border-top: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--white);
}

.signup-section__form {
  .select2-container {
    display: inline-flex;
    align-items: center;

    max-width: 200px;

    margin-right: 20px;

    .selection {
      width: 100%;
    }
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    &::before {
      display: inline-block;
      transition: transform 250ms ease;
    }
  }

  .select2-container--open {
    .select2-selection__arrow {
      &::before {
        transform: rotate(90deg);
      }
    }
  }

  .select2-selection--single {
    padding: 0 !important;
  }

  .select2-selection__rendered {
    color: var(--color-blue-dark) !important;
  }

  .select2-selection__arrow {
    top: 0 !important;
  }
}

form {
  .select2-container {
    width: 100% !important;
  }

  .select2-container--default .select2-selection--single {
    background: transparent;
    border: none;
    border-bottom: solid 2px var(--white);
    border-radius: 0;
    height: initial;
    // color: var(--white);
    color: var(--color-blue-dark);

    border-radius: 0;
    font-family: var(--font-main);
    font-size: 18px;
    padding: 0;

    .select2-selection__rendered {
      // color: var(--white);
      color: var(--color-blue-dark);

      line-height: initial;
      padding-left: 0;
    }

    .select2-selection__placeholder {
      // color: var(--white);
      color: var(--color-blue-dark);
    }

    .select2-selection__arrow {
      width: 16px;
      height: 100%;
      top: 8px;

      &::before {
        font-family: "icomoon";
        content: "\e905";
        font-size: 12px;
        color: #00a217;
      }
      b {
        display: none;
      }

      @include media(551) {
        &::before {
          // font-size: 8px;
        }
      }
    }
  }

  &.dark {
    .select2-container {
      &:not(:last-child) {
        margin-right: 16px;

        @include media(961) {
          margin-right: 0;
        }
      }
    }

    .select2-container--default .select2-selection--single {
      color: var(--white);

      .select2-selection__placeholder,
      .select2-selection__rendered {
        color: var(--white);
      }
    }
  }
}

.popup-dropdown {
  z-index: 9999999;
}

// Custom checkbox

.checkbox {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  span {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-size: 18px;
    position: relative;

    &::before {
      box-sizing: border-box;
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid var(--white);
      margin-right: 4px;
    }

    &::after {
      content: "";
      position: absolute;
      left: 4px;
      width: 5px;
      height: 12px;
      opacity: 0;
      transform: rotate(45deg) scale(0);
      border-right: 2px solid var(--black);
      border-bottom: 2px solid var(--black);
      transition: all 0.3s ease;
      transition-delay: 0.15s;
    }
  }
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked + span::before {
      background-color: var(--white);
      animation: jelly 0.6s ease;
    }

    &:checked + span::after {
      opacity: 1;
      transform: rotate(45deg) scale(1);
    }
  }
}

@keyframes jelly {
  from {
    transform: scale(1, 1);
  }

  30% {
    transform: scale(1.25, 0.75);
  }

  40% {
    transform: scale(0.75, 1.25);
  }

  50% {
    transform: scale(1.15, 0.85);
  }

  65% {
    transform: scale(0.95, 1.05);
  }

  75% {
    transform: scale(1.05, 0.95);
  }

  to {
    transform: scale(1, 1);
  }
}

.slider-custom {
  .sc {
    &__slider {
      margin-bottom: adaptive-calc(15px, 9px);
      -webkit-appearance: none; /* Override default CSS styles */
      appearance: none;
      width: 100%;
      background: var(--white); /* Grey background */
      outline: none; /* Remove outline */
      -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
      transition: opacity 0.2s;
      border: none;
      height: 1px; /* Specified height */
      padding: 0;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: adaptive-calc(20px, 15px);
        height: adaptive-calc(20px, 15px);
        background: var(--white);
        cursor: pointer;
        border-radius: 50%;
      }

      &::-moz-range-thumb {
        width: 9px;
        height: 9px;
        background: var(--white);
        cursor: pointer;
      }
    }

    &__text {
    }
  }
}
