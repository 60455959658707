@use "sass:math";

html {
  font-size: 16px;

  @include media(1450) {
    font-size: 14px;
  }

  @include media(1025) {
    font-size: 12px;
  }

  @include media(551) {
    font-size: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: var(--color-blue) var(--popup-light-gray);
}

body * {
  scrollbar-width: thin;
  scrollbar-color: var(--color-blue) var(--popup-light-gray);
}

// html::-webkit-scrollbar,
body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

// html::-webkit-scrollbar-track,
body *::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}

// html::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-thumb {
  background-color: var(--color-blue);
}

.fp-watermark {
  display: none;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  color: var(--color-blue-dark);

  &.lock {
    overflow: hidden;
  }
}

.snackbar-container {
  z-index: 99999999 !important;
}

#tidio-chat-iframe {
  top: auto !important;
  right: 9px !important;
  bottom: 70px !important;
  left: auto !important;
}

.grecaptcha-badge {
  z-index: 999 !important;
}

.plyr {
  cursor: pointer;
}

.plyr__video-wrapper {
  // @include photo-aspect-ratio(16, 9);
}

#typed-header {
  height: calc(adaptive-calc(70px, 33px) * 1.2 * 2);

  @include media(551) {
    height: calc(adaptive-calc(70px, 33px) * 1.7 * 3);
  }

  @include media(405) {
    height: calc(adaptive-calc(70px, 33px) * 1.7 * 4);
  }
}

.swiper-wrapper {
  left: 0 !important;
}

.swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  border: 1px solid var(--color-white);
  background-color: transparent;
  margin: 0 11px 0 0 !important;
  opacity: 1;
  @extend %default-transition;

  &:last-child {
    margin-right: 0 !important;
  }
}

.swiper-pagination-bullet:hover,
.swiper-pagination-bullet-active {
  background-color: var(--color-white);
}

#calendly-wrap {
  display: none;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  &.active {
    display: block;
  }

  .calendly-close {
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 999999;

    color: var(--color-white);

    cursor: pointer;
  }
}

#site {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

%default-transition {
  transition: all 0.3s;
}

.cont {
  margin: 0 auto;
  max-width: 1350px;
  width: perc(1350);
}

section {
  position: relative;
}

.dec {
  pointer-events: none;

  img {
    position: absolute;
    display: inline-block;
    width: auto;
    height: auto;
  }
}

.dec-small {
  pointer-events: none;

  img {
    position: absolute;
    display: inline-block;
    max-width: adaptive-calc(304.16px, 150px);
    height: auto;
  }
}
.dec-big {
  pointer-events: none;

  img {
    position: absolute;
    display: inline-block;
    max-width: adaptive-calc(281.39px, 130px);
    height: auto;
  }
}

.dec-three {
  pointer-events: none;

  img {
    position: absolute;
    display: inline-block;
    max-width: adaptive-calc(336px, 150px);
    height: auto;
  }
}

//decor
.decor {
  pointer-events: none;

  img {
    position: absolute;
    display: inline-block;
    width: auto;
    height: auto;
  }
}

//banner
.banner {
  position: relative;
  width: 100%;
  background-color: var(--color-blue-dark);
  padding: adaptive-calc(298px, 165px) 0 adaptive-calc(155px, 236px) 0;

  .decor {
    pointer-events: none;

    img {
      bottom: -1px;
      right: -2px;
      transform-origin: right bottom;
      max-height: 450px;
    }
  }

  &__cont {
    position: relative;
    max-width: 100% !important;

    .title {
      font-weight: 600;
      color: var(--color-white);
      margin-bottom: adaptive-calc(32px, 20px);

      mark {
        padding: 0 2px 0 4px;
        background-color: var(--color-green);
        color: var(--color-white);
      }
    }

    .btn {
      color: var(--color-white);
    }
  }
}

//tab
.tab {
  position: relative;
  width: 100%;
  padding: adaptive-calc(101px, 66px) 0 adaptive-calc(53px, 66px) 0;

  .decor {
    pointer-events: none;

    img {
      top: 0;
      left: 0;
      transform-origin: top left;
    }
  }

  &__block {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 962px;
    margin: 0 auto;
  }

  &__nav {
    display: inline-block;
    padding-left: 10px;
    padding-right: 20px;

    h2,
    .tab__head {
      cursor: pointer;
      font-weight: 600;
      // color: #ffffff;
      @extend %default-transition;

      opacity: 0.4;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover,
      &.active {
        opacity: 1;
        // color: var(--color-green-2);

        &.yellow {
          color: var(--color-yellow);
        }
        &.blue {
          color: var(--color-blue);
        }
        &.green {
          color: var(--color-green);
        }
        &.red {
          color: var(--color-red);
        }
      }

      img {
        width: 183px;
        max-width: 100%;
        max-height: 62px;
      }
    }

    &.partnerships {
      max-height: 330px;
      overflow: auto;

      .tab__head {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }

  &__swiper {
    overflow: hidden;

    width: 100%;
    max-width: 572px;

    @include media(769) {
      overflow: visible;
    }

    .swiper-pagination-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      // margin-top: adaptive-calc(33px, 30px);

      &.hidden-for-pc {
        display: none;
        @include media(768) {
          display: flex;
        }
      }
    }

    .swiper-pagination {
      position: relative;
      bottom: auto;
      left: unset !important;
      transform: unset !important;

      width: fit-content;
      margin: 0 14px;
      // margin-top: adaptive-calc(33px, 30px);
    }

    .tab__swiper-btn {
      color: var(--color-white);
      font-size: 16px;
    }

    &.partnerships {
      max-width: 650px;

      padding-left: adaptive-calc(76px, 55px);
    }

    .btn-block {
      .btn {
        &:not(:last-child) {
          @include media(350) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__item {
    width: 100%;
    // display: none;
    padding-top: 10px;

    color: var(--color-white);

    &:first-child {
      // display: block;
    }

    h2,
    .tab__head {
      display: none;
      font-weight: 500;
      // color: var(--color-green-2);
      margin-bottom: 10px;

      &.yellow {
        color: var(--color-yellow);
      }
      &.blue {
        color: var(--color-blue);
      }
      &.green {
        color: var(--color-green);
      }
      &.red {
        color: var(--color-red);
      }
    }

    .tab__head {
      img {
        width: 100%;
        height: 62px;
        object-fit: contain;
      }
    }

    .tab__item-text {
      overflow: auto;
      max-height: 400px;

      margin-bottom: adaptive-calc(34px, 20px);
      padding-right: 7px;

      color: var(--color-blue-dark);
      line-height: 1.7777777778em;
      font-family: var(--font-main);
      font-size: adaptive-calc(18px, 14px);

      p {
        color: var(--color-blue-dark);
        line-height: 1.7777777778em;
        font-family: var(--font-main);
        font-size: adaptive-calc(18px, 14px);
        &:not(:last-child) {
          margin-bottom: adaptive-calc(34px, 20px);
        }
      }
    }

    .partnerships-card {
      font-family: var(--font-main);
    }

    .partnerships-card__text {
      overflow: auto;

      height: 224px;

      margin-bottom: 41px;
      padding-right: 7px;

      font-size: 18px;
      line-height: 1.78;
      color: var(--color-white);

      @include media(601) {
        margin-bottom: 20px;
        height: 235px;
      }

      *,
      p {
        color: var(--color-white);
      }

      &::before {
        content: "“";
        position: absolute;
        right: calc(100% + 20px);
        font-size: adaptive-calc(133px, 75px);
        line-height: 0.8;
        color: var(--color-yellow);

        @include media(601) {
          right: calc(100% + 10px);
        }
      }

      .simplebar-offset {
        padding-right: 15px;
      }

      .simplebar-scrollbar {
        &::before {
          background-color: var(--color-yellow);
        }
        &.simplebar-visible {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    .partnerships-card__info-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media(601) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .partnerships-card__partner-info {
      color: var(--color-green-2);

      font-size: adaptive-calc(18px, 15px);
    }

    .partnerships-card__raised-wrap {
      display: flex;
      align-items: center;

      line-height: 1;
    }

    .partnerships-card__raised-num {
      margin-right: adaptive-calc(23px, 15px);

      font-size: adaptive-calc(40px, 25px);
      font-weight: 500;
    }

    .partnerships-card__raised-text {
      font-size: adaptive-calc(35px, 20px);
      font-weight: 300;
    }

    .btn-block {
      padding-left: 8px;
    }
  }
}

//cards
.cards {
  position: relative;
  width: 100%;
  padding: adaptive-calc(132px, 32px) 0 adaptive-calc(164px, 76px) 0;

  .decor {
    pointer-events: none;

    img {
      bottom: 0;
      right: 0;
      transform-origin: bottom right;
    }
  }

  .cont {
    max-width: 1160px;
  }

  &__swiper {
    width: 100%;
    overflow: hidden;

    .swiper-slide {
      height: auto;
    }

    .swiper-pagination {
      bottom: 40px;
    }
  }

  &__item {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: adaptive-calc(24px, 20px) adaptive-calc(18px, 16px)
      adaptive-calc(34px, 24px) adaptive-calc(18px, 20px);
    background-color: var(--color-blue-dark);
    clip-path: polygon(0 0, 85% 0, 100% 22%, 100% 100%, 15% 100%, 0 78%);

    &::before {
      position: absolute;
      content: "";
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background-color: var(--color-white);
      clip-path: polygon(0 0, 85% 0, 100% 22%, 100% 100%, 15% 100%, 0 78%);
      opacity: 0;
      visibility: hidden;
      @extend %default-transition;
    }

    &:hover {
      h3,
      p,
      .btn {
        color: var(--color-blue-dark);
      }

      .btn {
        span.icon-dark {
          &::before {
            border-left-color: var(--color-white);
          }
        }
      }

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }

    div {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      height: 100%;

      object {
        margin-top: auto;
      }
    }

    h3,
    p,
    .btn {
      color: var(--color-white);
      @extend %default-transition;
    }

    h3 {
      font-weight: 500;
      line-height: 1.6em;
      margin-bottom: adaptive-calc(17px, 7px);
    }

    p {
      line-height: 1.3333333333333333em;
      margin-bottom: adaptive-calc(26px, 18px);
    }

    .btn {
      padding-right: adaptive-calc(18px, 2px);
      margin-top: auto;
      margin-left: auto;

      span.icon-dark {
        &::before {
          @extend %default-transition;
        }
      }
    }
  }
}

//say
.say {
  position: relative;
  width: 100%;
  padding: adaptive-calc(70px, 67px) 0 adaptive-calc(77px, 84px) 0;
  background-color: var(--color-blue-dark);

  .decor {
    pointer-events: none;

    img {
      &:first-child {
        top: -1px;
        left: -1px;
        transform-origin: top left;
      }

      &:last-child {
        bottom: -1px;
        right: -1px;
        transform-origin: bottom right;
      }
    }
  }

  &__block {
    width: 100%;
    max-width: 964px;
    text-align: center;
    margin: 0 auto;
  }

  &__title {
    font-weight: 400;
    letter-spacing: -0.2px;
    color: var(--color-white);
    margin-bottom: adaptive-calc(74px, 34px);
  }

  &__swiper {
    width: 100%;
    max-width: 768px;
    overflow: hidden;
    margin: 0 auto adaptive-calc(80px, 90px) auto;

    .swiper-pagination-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: adaptive-calc(33px, 30px);
    }

    .swiper-pagination {
      position: relative;
      bottom: auto;
      left: unset !important;
      transform: unset !important;
      // margin-top: adaptive-calc(33px, 30px);
    }

    .say__swiper-btn {
      color: var(--color-white);
    }
  }

  &__item {
    width: 100%;
    text-align: center;

    p {
      position: relative;
      color: var(--color-white);
      margin-bottom: adaptive-calc(63px, 32px);

      span {
        color: var(--color-blue-dark);
        background-color: var(--color-green-2);
        padding: 3px;
        font-weight: 600;
      }
    }
  }

  &__item-bottom {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  &__item-img {
    // width: 46%;
    text-align: right;

    img {
      position: relative;
      top: adaptive-calc(-2px, 0px);
      left: adaptive-calc(-2px, -4px);
      display: inline-block;
      width: adaptive-calc(141px, 94px);
    }
  }

  &__item-info {
    display: inline-block;
    text-align: left;
    padding-left: adaptive-calc(41px, 17px);

    &:first-child {
      text-align: center;
      padding-left: 0;
    }

    div {
      font-size: adaptive-calc(17px, 14px);
      color: var(--color-white);
      margin-bottom: adaptive-calc(2px, 6px);

      &:first-child {
        font-weight: 700;
        color: var(--color-yellow);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__total-swiper {
    width: 100%;

    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: adaptive-calc(65px, 40px);
      grid-row-gap: adaptive-calc(35px, 20px);
    }

    &.invert {
      .say__total-item {
        p {
          color: var(--color-blue-dark);
        }
      }
    }
  }

  &__total-item {
    width: 100%;
    text-align: center;

    div {
      font-size: 40px;
      font-weight: 500;
      line-height: 1.0833333333333333em;
      color: var(--color-white);

      &.red {
        color: var(--color-red);
      }

      &.green {
        color: var(--color-green-2);
      }

      &.blue {
        color: var(--color-blue);
      }
    }

    p {
      font-size: 30px;
      line-height: 1.4444444444444444em;
      font-weight: 300;
      color: var(--color-white);
    }
  }
}

//jobs

.jobs {
  position: relative;
  width: 100%;
  padding: adaptive-calc(124px, 72px) 0 adaptive-calc(134px, 116px) 0;

  &__head {
    margin-bottom: adaptive-calc(100px, 58px);
  }

  &__control {
    margin-top: adaptive-calc(65px, 40px) !important;
  }

  &__swiper {
    .swiper-pagination {
      //display: none;
    }

    .swiper-slide {
      height: auto;
    }

    .swiper-pagination-bullet {
      border-color: var(--color-blue-dark);
    }

    .swiper-pagination-bullet:hover,
    .swiper-pagination-bullet-active {
      background-color: var(--color-blue-dark);
    }
  }

  &__card {
    position: relative;
    width: 100%;
    min-height: 380px;
    height: 100%;
    // background-color: var(--color-blue-dark);
    background: linear-gradient(
      var(--color-blue-dark) 0%,
      var(--color-blue-dark) 99%,
      transparent
    );
    padding: adaptive-calc(33px, 39px) adaptive-calc(18px, 23px)
      adaptive-calc(102px, 76px) adaptive-calc(18px, 23px);
    clip-path: polygon(-1px 0px, 84% 0, 100% 13%, 100% 100%, 0 101%, -1px 100%);

    &::before {
      position: absolute;
      content: "";
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background-color: var(--color-white);
      clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 83%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      .jobs__card-cont {
        transition: all 0.3s ease-in-out;
        h4,
        a,
        h5,
        p {
          color: var(--color-blue-dark);
          transition: all 0.3s ease-in-out;
        }
      }

      .btn {
        color: var(--color-blue-dark);
        transition: all 0.3s ease-in-out;

        span.icon-dark {
          &::before {
            border-left-color: var(--color-white);
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &::before {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease-in-out;
      }
    }

    img {
      position: absolute;
      bottom: -1px;
      left: -1px;
      display: inline-block;
      width: calc(100% + 2px);
      max-width: unset;
      height: auto;
    }

    .btn-block {
      justify-content: flex-start;
    }

    .btn {
      color: var(--color-white);
      /*margin-right: adaptive-calc(26px, 22px);*/
      margin-right: 10px;
      @extend %default-transition;

      &:last-child {
        margin-right: 0;
      }

      span.icon-dark {
        &::before {
          @extend %default-transition;
        }
      }
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 1;
  }

  &__card-cont {
    width: 100%;
    margin-bottom: adaptive-calc(26px, 29px);
    flex-grow: 1;

    /*.jobs__del-city.jobs__card-city {
      display: none;
    }*/

    h4,
    h5,
    div,
    a {
      color: var(--color-white);
      transition: all 0.3s ease-in-out;
    }

    h4,
    a {
      font-weight: 500;
      margin-bottom: adaptive-calc(26px, 22px);
      font-size: adaptive-calc(25px, 18px);
      font-weight: 700;
      line-height: 1.8em;
    }

    h5 {
      font-weight: 400;
      margin-bottom: adaptive-calc(12px, 14px);
      @extend %default-transition;
    }

    p {
      font-size: adaptive-calc(15px, 14px);
      line-height: 1.6em;
      font-weight: 300;
      color: var(--color-white);
      margin-bottom: adaptive-calc(4px, 6px);
      @extend %default-transition;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.jobs__del-city.jobs__card-cont {
  margin-bottom: adaptive-calc(35px, 29px);

  h5 {
    letter-spacing: -1px;
    font-weight: 400;
    font-size: 23px;
    margin-bottom: 21px;
    line-height: 1.5;
    @extend %default-transition;
  }
  a,
  h4 {
    font-size: adaptive-calc(25px, 18px);
    font-weight: 500;
    margin-bottom: 21px;
    color: var(--color-white);
    line-height: 1.5;
    @extend %default-transition;
  }
  p {
    font-size: adaptive-calc(17px, 14px);
    line-height: 1.6em;
    font-weight: 300;
    color: var(--color-white);
    margin-bottom: 13px;
    @extend %default-transition;

    /*    &:last-child {
      margin-bottom: 0;
    }*/
  }
}

//video
.video {
  position: relative;
  width: 100%;
  padding: adaptive-calc(152px, 40px) 0 adaptive-calc(110px, 129px) 0;

  .decor {
    pointer-events: none;

    img {
      &:first-child {
        top: 0;
        left: -1px;
        transform-origin: top left;
        width: 230px;
      }

      &:last-child {
        bottom: 0;
        right: 0;
        transform-origin: bottom right;
        width: 150px;
      }
    }
  }

  &__head {
    position: relative;
    width: 100%;
    max-width: 768px;
    margin-left: auto;
    margin-bottom: adaptive-calc(82px, 50px);

    h2 {
      font-weight: 600;
      margin-bottom: adaptive-calc(10px, 9px);
    }

    p {
      width: 94%;
      margin-bottom: 28px;

      font-family: var(--font-main);
      font-size: 18px;
    }
  }

  &__swiper {
    width: 100%;
    overflow: hidden;

    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 16px;
      grid-column-gap: 16px;
    }

    .swiper-pagination {
      display: none;
    }

    .swiper-pagination-bullet {
      border-color: var(--color-blue-dark);
    }

    .swiper-pagination-bullet:hover,
    .swiper-pagination-bullet-active {
      background-color: var(--color-blue-dark);
    }
  }

  &__card {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    &:hover {
      .video__img {
        &::after {
          opacity: 0;
        }
      }
      img {
        transform: scale(1.05);
      }

      .btn {
        &.yellow {
          color: var(--color-yellow-2);
        }

        &.red {
          color: var(--color-red);
        }

        &.blue {
          color: var(--color-blue);
        }
      }
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 46%;
    overflow: hidden;
    margin-bottom: adaptive-calc(12px, 10px);

    &.yellow {
      // background-color: var(--color-yellow-2);
    }

    &.red {
      // background-color: var(--color-red);
    }

    &.blue {
      // background-color: var(--color-blue);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      background: rgba(0, 0, 0, 0.3);

      transition: opacity 400ms;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      will-change: transform;
      transition: all 0.7s;
    }
  }

  &__cont {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    div {
      width: 100%;
      font-size: adaptive-calc(20px, 16px);
      line-height: 1.2em;
      color: var(--color-blue-dark);
      margin-bottom: 6px;

      &.yellow {
        color: var(--color-yellow-2);
      }

      &.red {
        color: var(--color-red);
      }

      &.blue {
        color: var(--color-blue);
      }
    }

    h4 {
      margin-bottom: adaptive-calc(12px, 14px);
    }

    .btn.size-2 {
      margin-top: auto;

      span {
        margin-left: adaptive-calc(7px, 11px);
      }
    }
  }
}

//Styles for Team page ----------------------------------------------

.head-team {
  .select2-container--default .select2-selection--single {
    color: var(--color-white);
  }

  &__cont {
    h1 {
      padding-top: adaptive-calc(250px, 170px) !important;
    }
  }

  &__form {
    display: flex;
    align-items: flex-end;
    max-width: 686px;
    margin-top: 27px;

    .select2-container--default .select2-selection--single {
      border-bottom: none;
    }

    input,
    textarea {
      @extend %default-transition;
      @include transparent-bg-input($textColor: #fff);
    }

    input {
      max-width: 278px;
      width: 100%;
      color: var(--color-white);
      border-bottom: 1px solid var(--color-white);
      margin-right: 16px;
      padding: 9px 2px 10px;

      &::placeholder {
        color: var(--color-white);
      }

      &:focus {
        // border-bottom: 1px solid var(--color-white);
        border-color: var(--color-yellow-2);

        &::placeholder {
          color: var(--color-yellow-2);
        }
      }
    }

    button,
    a {
      color: var(--color-white);
      cursor: pointer;
      margin-left: 16px;
    }

    .select2-container {
      border-bottom: 1px solid var(--color-white);
    }

    .select2-container--default .select2-selection--single {
      padding: 11px 0 15px 0px;
    }
    .select2-container--default
      .select2-selection--single
      .select2-selection__rendered {
      max-width: 280px;
      width: 100%;
    }
    .select2-container--default
      .select2-selection--single
      .select2-selection__arrow {
      right: 0px;

      &::before {
        display: inline-block;
        transition: transform 250ms ease;
      }
    }
    .select2-container--open {
      .select2-selection__arrow {
        &::before {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.team-grid {
  margin-top: adaptive-calc(66px, 50px);
  margin-bottom: adaptive-calc(159px, 50px);

  &__inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-flow: dense;
    grid-column-gap: 16px;
    grid-row-gap: 63px;
    justify-items: center;
    // align-items: center;
  }

  &__card {
    max-width: 278px;
    width: 100%;

    a.team-card {
      display: flex;
      flex-direction: column;
      height: 100%;

      .team-card__links {
        flex: 1;
        align-items: flex-end;
      }
    }

    &:hover {
      .team-card__img {
        transform: scale(1.05);
      }
    }
  }
}

.no-results {
  display: block;
  width: 100%;
}

.show-team {
  padding: 0px 0 adaptive-calc(116px, 70px);
  position: relative;
  .show__title {
    max-width: 200px;
  }
  .decor {
    pointer-events: none;

    img {
      width: adaptive-calc(195.5px, 100px);
      height: auto;
      bottom: 0;
      left: 0px;
      transform-origin: bottom left;
    }
  }

  &__text {
    p {
      margin-bottom: 41px;
    }
  }

  &__video {
    margin-top: adaptive-calc(70px, 30px) !important;
  }
}

//Styles for Team page ----------------------------------------------

//Styles for Hiring Talent page ----------------------------------------------

.cards-hiring {
  position: relative;
  padding: adaptive-calc(98px, 30px) 0px adaptive-calc(164px, 50px);
  .decor {
    pointer-events: none;

    img {
      top: -43px;
      right: -7px;
      transform-origin: bottom right;
      width: 198.72px;
      height: auto;
    }
  }

  .swiper-pagination {
    bottom: 10px;
  }

  &__swiper {
    .swiper-pagination-bullet {
      border-color: var(--color-blue-dark);
    }

    .swiper-pagination-bullet:hover,
    .swiper-pagination-bullet-active {
      background-color: var(--color-blue-dark);
    }
  }
}

.say-hiring {
  padding: adaptive-calc(112px, 67px) 0 adaptive-calc(30px, 84px) 0;

  &__swiper {
    margin: 0 auto adaptive-calc(77px, 20px) auto;
  }
}

.clients-hiring {
  margin: adaptive-calc(124px, 50px) 0px adaptive-calc(92px, 50px);
}

.video-hiring {
  padding: adaptive-calc(135px, 20px) 0 adaptive-calc(220px, 138px) !important;
}

//Styles for Hiring Talent page ----------------------------------------------

//Styles for Team Member page ----------------------------------------------

.head-member {
  clip-path: polygon(
    0 0,
    100% 0,
    100% 0,
    100% 70%,
    90% 100%,
    0 100%,
    0 100%,
    0 0
  );

  &__cont {
    p {
      margin-top: 15px;
      color: var(--color-green-2);
      font-size: adaptive-calc(30px, 22px);
      line-height: 1;
    }
  }
}

.member {
  margin-top: 42px;
  margin-bottom: adaptive-calc(115px, 50px);

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__text-block {
    max-width: 572px;

    a {
      margin-top: adaptive-calc(50px, 20px);
    }
  }

  &__text {
    p {
      margin-bottom: adaptive-calc(30px, 15px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__info {
    position: relative;
    max-width: adaptive-calc(377px, 50px);
    width: 100%;
    right: adaptive-calc(94px, 0px);
    top: adaptive-calc(-305px, -100px);
  }
  &__photo {
    max-width: 377px;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      clip-path: polygon(
        16% 0,
        100% 0,
        100% 0,
        100% 83%,
        84% 100%,
        0 100%,
        0 100%,
        0 16%
      );
    }
  }

  &__contact {
    margin-top: 27px;

    &.team-card__contact {
      margin-top: 10px;

      a {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: var(--color-red);
      font-size: adaptive-calc(21px, 18px);
      line-height: adaptive-calc(24px, 18px);
      font-weight: 400;
      transition: all 0.3s;
      margin-bottom: adaptive-calc(17px, 10px);

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: var(--color-yellow-2);
      }
    }
  }

  &__soc {
    display: flex;
    align-items: baseline;
    margin-top: adaptive-calc(25px, 10px);

    a {
      margin-right: 14px;

      .icon-email {
        font-size: 22px;
      }

      i {
        font-size: 20px;
        transition: all 0.3s;
        &:before {
          color: var(--color-yellow-2);
        }
      }

      &:hover i::before {
        color: var(--color-red);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    margin-top: adaptive-calc(14px, 5px);

    a,
    button {
      margin-top: 10px;
    }

    .btn {
      letter-spacing: -1px;
    }
  }

  &__sectors {
    margin-top: adaptive-calc(25px, 15px);
    p {
      font-size: adaptive-calc(16px, 14px);
    }
  }
}

.jobs-member {
  margin: adaptive-calc(115px, 50px) 0px adaptive-calc(145px, 50px);

  &__head {
    align-items: flex-end;
    margin-bottom: 34px;
    h2 {
      letter-spacing: -1px;
    }
  }

  &__cont {
    .btn-block {
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }

  .swiper-slide {
    opacity: 0.5;
  }

  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide-next,
  .swiper-slide-prev {
    opacity: 1;
  }

  &__control {
    margin-top: adaptive-calc(60px, 40px) !important;
  }
}

.resent {
  margin-bottom: adaptive-calc(169px, 40px);

  &__slider.swiper {
    overflow: visible;
  }
  &__slider {
    max-width: 376px;
    margin-top: adaptive-calc(-4px, 0px);
  }
}

//Styles for Team Member page ----------------------------------------------

//Styles for Looking-for-work page ----------------------------------------------

.show-work {
  margin: adaptive-calc(84px, 50px) 0px adaptive-calc(94px, 50px);

  .show__text-block {
    flex-direction: column;
  }

  .show-work-dec {
    pointer-events: none;

    img {
      position: absolute;
      top: 34px;
      left: 0;
      max-width: adaptive-calc(218px, 88px);
      height: auto;
    }
  }

  &__title {
    max-width: 100% !important;

    margin-bottom: 15px;

    text-align: center;
  }

  &__text {
    max-width: 100% !important;

    margin-top: 0 !important;

    a {
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      position: relative;
      padding: 2px;
      background: var(--color-yellow);
    }
  }

  &__slider {
    max-width: 769px;
    margin-top: adaptive-calc(66px, 30px);
  }
}

.resources-slider__nav {
  display: flex;
  justify-content: center;

  margin-top: 20px;

  .swiper-pagination-bullet {
    border-color: var(--color-blue-dark);

    &.swiper-pagination-bullet-active {
      background-color: var(--color-blue-dark);
    }
  }
}

.team-s-work {
  padding-bottom: adaptive-calc(157px, 20px) !important;

  &__box {
    margin-left: adaptive-calc(98px, 0px);
    margin-bottom: 64px;

    h2 {
      max-width: 280px;
      span {
        background-color: var(--color-green);
        padding: 1px 2px;
      }
    }

    @media only screen and (max-width: 900px) {
      h2 {
        max-width: 100%;
        span {
          background-color: var(--color-green);
          padding: 1px 2px;
        }
      }
    }
  }

  &__cont {
    width: 61% !important;
    padding-left: 75px !important;
    p {
      margin-bottom: adaptive-calc(40px, 17px) !important;
    }

    @media only screen and (max-width: 900px) {
      width: 100% !important;
      padding-left: 0px !important;
      h2 {
        max-width: 100%;
        span {
          background-color: var(--color-green);
          padding: 1px 2px;
        }
      }
    }
  }

  &__control {
    margin-top: adaptive-calc(60px, 35px) !important;
  }
}

.jobs-work {
  margin-bottom: adaptive-calc(126px, 40px);
  .title-box {
    margin-bottom: 76px;
  }

  .swiper-slide {
    opacity: 0.5;

    transition: opacity 300ms ease;
  }

  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide-next,
  .swiper-slide-prev {
    opacity: 1;
  }

  .slider-control {
    margin-top: adaptive-calc(44px, 35px);
  }
}

.career-work {
  margin-top: adaptive-calc(165px, 50px);
}

.say-work {
  margin: adaptive-calc(151px, 50px) 0 adaptive-calc(53px, 30px);
  position: relative;

  .decor-2 {
    pointer-events: none;

    img {
      position: absolute;

      &:first-child {
        width: adaptive-calc(140px, 35px);
        height: auto;
        bottom: -1px;
        left: -1px;
        transform-origin: top left;
      }

      &:last-child {
        width: adaptive-calc(140px, 35px);
        height: auto;
        top: -1px;
        right: -5px;
        transform-origin: bottom right;
      }
    }
  }
}

.video-sec-work {
  padding: adaptive-calc(135px, 50px) 0 adaptive-calc(200px, 100px) !important;
}

//Styles for Looking-for-work page ----------------------------------------------

//Styles for Solutions page ----------------------------------------------

.show-solution {
  padding: adaptive-calc(58px, 50px) 0px adaptive-calc(47px, 30px);
}

.sol-career-revers {
  margin-top: adaptive-calc(100px, 50px);
  margin-bottom: adaptive-calc(50px, 20px);

  .career__inner {
    flex-direction: row-reverse;
    margin-left: 0px;
  }
  .career__text {
    margin-right: 0px;
    margin-left: 40px;
    margin-top: 50px;

    h2,
    h3,
    h4 {
      margin-bottom: adaptive-calc(36px, 9px);
    }
  }
}

.sol-career {
  margin-top: adaptive-calc(110px, 30px);
  padding-bottom: adaptive-calc(100px, 110px);
  position: relative;
  .decor {
    pointer-events: none;

    img {
      width: adaptive-calc(195.5px, 100px);
      height: auto;
      bottom: 0;
      left: 0px;
      transform-origin: bottom left;
    }
  }
}

//Styles for Solutions page ----------------------------------------------

.modal__backdrop {
  /*position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 13;

   display: flex;
   align-items: center;
   justify-content: center;

   padding: 40px;

   background-color: rgba(1, 1, 61, 0.5);

   opacity: 0;
   visibility: hidden;

   transition: opacity ease 250ms, visibility ease 250ms;

   @include media(501) {
      padding: 20px;
   }

   &.is-open {
      opacity: 1;
      visibility: visible;
   }*/
}

.modal {
  position: relative;
  overflow: hidden;

  width: 90vw !important;
  max-width: 1200px;
  height: auto !important;
  max-height: 100%;

  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 140px) 0,
    100% 140px,
    100% 100%,
    140px 100%,
    0 calc(100% - 140px)
  );
  clip-path: polygon(
    0 0,
    calc(100% - 140px) 0,
    100% 140px,
    100% 100%,
    140px 100%,
    0 calc(100% - 140px)
  );

  padding: adaptive-calc(74px, 55px) adaptive-calc(196px, 75px) 55px !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);

  background-color: var(--color-white);

  font-family: var(--font-main);

  @include media(1440) {
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 80px) 0,
      100% 80px,
      100% 100%,
      80px 100%,
      0 calc(100% - 80px)
    );
    clip-path: polygon(
      0 0,
      calc(100% - 80px) 0,
      100% 80px,
      100% 100%,
      80px 100%,
      0 calc(100% - 80px)
    );
  }

  @include media(769) {
    padding: 55px 30px 20px !important;
    clip-path: polygon(0 0, 94% 0, 100% 6%, 100% 100%, 6% 100%, 0 94%);
  }

  @include media(550) {
    max-width: 89%;
  }

  &--applied {
    height: 80vh;
    // padding: adaptive-calc(240px, 55px) adaptive-calc(196px, 75px)
    //    adaptive-calc(240px, 55px);
  }

  .apply-form {
    p {
      font-size: adaptive-calc(20px, 16px);
    }

    &__input,
    &__textarea {
      color: var(--color-blue-dark);

      &::placeholder {
        color: var(--color-blue-dark);
      }

      &:focus {
        border-color: var(--color-blue-dark);
      }
    }

    &__textarea {
      &[data-autosize] {
        transition: none;
        height: 36px;
        overflow: hidden;

        @include media(551) {
          height: 64px;
        }
      }
    }

    &__checkboxes-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      @include media(551) {
        grid-template-columns: repeat(1, 1fr);
      }

      p {
        @include media(415) {
          font-size: 14px;
        }
      }
    }
  }

  .checkBox {
    grid-column-start: 1;
    grid-column-end: -1;
    .check-title {
      color: var(--color-blue-dark);
      font-family: var(--font-main);
      font-size: 18px;
      padding-left: 25px;

      &:before {
        border: 1px solid var(--color-blue-dark);
        width: 18px;
        height: 18px;
      }

      a {
        display: inline-block;
        position: relative;
        transition: all 0.3s ease;
        color: var(--color-blue);

        &:before {
          content: "";
          position: absolute;
          right: 0;
          left: auto;
          bottom: 2px;
          height: 1px;
          width: 0;
          background: var(--color-blue);
          transition: all 0.3s ease;
        }

        &:hover {
          color: var(--color-blue-dark);
          transition: all 0.3s ease;
          &:before {
            background: var(--color-blue-dark);
            left: 0;
            right: auto;
            width: 100%;
          }
        }
      }
    }

    input:checked + .check-title::before {
      background: var(--color-red);
    }
  }
}

.modal__close {
  position: absolute;
  top: 74px;
  right: 98px;

  display: flex;
  align-items: center;

  transition: color ease 250ms;

  &:hover {
    color: var(--color-yellow);
  }

  @include media(1281) {
    top: 30px;
  }

  @include media(769) {
    right: 50px;
  }

  @include media(651) {
    top: 20px;
  }
}

.modal__close-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 23px;
  height: 23px;

  margin-left: 20px;

  font-size: 11px;

  color: var(--color-white);
  background-color: var(--color-yellow);
}

.modal__title {
  margin-bottom: 15px;

  font-size: adaptive-calc(32px, 22px);
  font-weight: 600;
  line-height: 1.2;

  @include media(415) {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.modal__text {
  padding-bottom: adaptive-calc(20px, 10px);

  p {
    font-size: 14px;
  }
}

.modal__descr {
  color: var(--color-blue);

  font-size: 20px;
}

.modal__btn-wrap {
  display: flex;
  flex-wrap: wrap;

  margin-top: 55px;
}

.apply-form__alert {
  margin-bottom: 15px;

  color: var(--color-blue-2);

  font-size: 18px;
  font-weight: 400;
}

.apply-form__fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 16px;
  margin-bottom: 33px;

  @include media(551) {
    grid-template-columns: 1fr;

    margin-bottom: 0;

    &--cv {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0;

      & > * {
        margin-bottom: 30px;

        @include media(415) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.buttons-upload-container {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  width: 100%;

  @include media(415) {
    gap: 10px;
  }

  .btn {
    margin: 0 !important;
    padding-bottom: 0;
    border: none;
    flex-wrap: nowrap;
    cursor: pointer;
    justify-content: center !important;

    & > * {
      text-decoration: none;
      margin-right: 0;
    }

    .apply-form__file-title {
      display: none;
    }

    .apply-form__file-name {
      display: none;
    }

    input {
      display: none;
    }
  }
}

.apply-form__input,
.apply-form__textarea {
  padding: 0 0 8px;
  border-bottom: 1px solid var(--color-blue-dark);
}

.apply-form__file,
.apply-form__textarea {
  grid-column-start: 1;
  grid-column-end: -1;
}
.apply-form__file {
  display: flex;
  flex-wrap: wrap;

  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-blue-dark);

  font-size: 18px;

  cursor: pointer;

  &:hover {
    .apply-form__file-choose {
      color: var(--color-blue);
    }
  }

  input {
    display: none;
  }

  @include media(551) {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
  }
}

.apply-form__file-title {
  margin-right: adaptive-calc(36px, 15px);

  white-space: nowrap;
}

.apply-form__file-choose {
  margin-right: adaptive-calc(40px, 15px);

  white-space: nowrap;
  text-decoration: underline;

  transition: color 300ms ease;
}

.apply-form__file-name {
  @include max-line-leng(1);

  @include media(451) {
    // display: none;

    .cv_file_name {
      @include max-line-leng(1);
    }
  }
}

.apply-form__btn {
  margin-left: auto;
}

body.safari {
  .modal__slider {
    .swiper-slide {
      padding-right: 0 !important;
    }
  }
}

.modal__slider {
  overflow: hidden;
  // padding-bottom: 10px;
  width: 100%;

  .swiper-wrapper {
    padding-bottom: 20px;
    align-items: center;

    @include media(415) {
      padding-bottom: 10px;
    }
  }

  .swiper-slide {
    // max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }
}

.modal__content {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  // padding-right: 10px;
  max-height: 85vh;
  max-height: calc(var(--vh, 1vh) * 85);
  margin-top: 30px;

  .modal__slider {
    margin-top: -10px;
  }

  @include media(1024) {
    margin-top: 0;

    .modal__slider {
      margin-top: 0;
    }
  }

  @include media(401) {
    padding-right: 5px;
  }
}

.modal__slider-nav-wrap {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;

  width: fit-content;

  /*margin: -24px auto 0;*/
  margin: 0 auto 0;

  @include media(769) {
    margin: 15px auto 0;
  }
}

.modal__slider-nav-btn {
  font-size: 10.5px;

  color: var(--color-blue-dark);

  transition: opacity ease 250ms;

  &:hover {
    opacity: 0.75;
  }
}

.modal__slider-prev-btn {
  margin-right: 14px;
}

.modal__slider-nav {
  margin-right: 14px;

  .swiper-pagination-fraction {
    span {
      transition: all 0.25s ease;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .swiper-pagination-current {
    cursor: pointer;
  }

  .swiper-pagination-total {
    cursor: pointer;
    opacity: 0.7;
  }

  .swiper-pagination-bullet {
    width: 13px;
    height: 13px;

    border: 1px solid var(--color-blue-dark);
    border-radius: 50%;

    transition: transform ease 250ms;

    &:hover {
      transform: scale(1.1);
    }

    &-active {
      background-color: var(--color-blue-dark);
    }
  }
}

.modal__decor {
  position: absolute;
  // bottom: 0;
  left: 0;
  z-index: 1;
}

.page404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  background-color: var(--color-blue-dark);
  color: var(--color-white);

  font-family: var(--font-main);
}

.page404__title {
  margin-bottom: 10px;

  font-size: adaptive-calc(42px, 30px);
}

.page404__btn {
  .btn {
    color: var(--color-white);
  }
}

.page404__subtitle {
  margin-bottom: adaptive-calc(40px, 30px);
  width: 90%;
  max-width: 550px;

  font-size: adaptive-calc(25px, 20px);
  text-align: center;
}

.statistics-section {
  margin-bottom: adaptive-calc(120px, 30px);
}

.why-matters {
  position: relative;

  padding: adaptive-calc(120px, 40px) 0;

  background-color: var(--color-blue-dark);

  .decor {
    pointer-events: none;

    .decor-img {
      position: absolute;

      width: adaptive-calc(140px, 35px);

      &.top {
        top: -1px;
        right: -1px;
      }

      &.bottom {
        bottom: -1px;
        left: -1px;
      }
    }
  }
}

.why-metters__title {
  margin-bottom: 45px;

  color: var(--white);
  text-align: center;

  span {
    background-color: var(--color-red);
  }
}

.why-matters__swiper {
  position: relative;
  overflow: hidden;

  padding-bottom: 45px;

  .swiper-pagination {
    bottom: 0;
  }
}

.why-matters__slide {
  font-size: adaptive-calc(18px, 14px);
  max-width: 768px;

  margin: 0 auto;

  color: var(--color-white);

  font-family: var(--font-main);
  text-align: center;
}

.five-actions {
  // padding: adaptive-calc(120px, 80px) 0 adaptive-calc(200px, 80px);
  padding: adaptive-calc(120px, 80px) 0 adaptive-calc(150px, 100px);

  font-family: var(--font-main);
  text-align: center;

  @include media(769) {
    padding-bottom: 55px;
  }
}

.five-actions__title {
  margin-bottom: 25px;

  font-size: adaptive-calc(34px, 22px);
}

.five-actions__descr {
  max-width: 572px;

  // margin: 0 auto adaptive-calc(145px, 50px);
  margin: 0 auto;

  font-size: 18px;
  line-height: 1.78;
}

.five-actions__list {
  position: relative;

  display: flex;
  justify-content: space-between;

  width: 86%;

  margin: 0 auto;
  padding: adaptive-calc(89px, 50px) 0;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;

    display: block;

    width: 100%;
    height: 4px;

    background: var(--color-blue-dark);
  }

  @include media(769) {
    flex-direction: column;
    align-items: center;

    &::before {
      top: 50px;
      left: 50%;
      transform: translateX(-50%);

      width: 4px;
      height: calc(100% - 125px);
    }
  }
}

.five-actions__item {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 120px;

  border-radius: 50%;

  color: var(--color-white);

  font-size: 60px;

  cursor: pointer;

  transition: transform ease 300ms;

  // &:hover {
  //   transform: scale(1.1);
  // }

  &.green {
    background-color: var(--color-green);
  }

  &.red {
    background-color: var(--color-red);
  }

  &.yellow {
    background-color: var(--color-yellow);
  }

  &.dark-blue {
    background-color: var(--color-blue-dark);
  }

  &.blue {
    background-color: var(--color-blue);
  }
}

.five-actions__item-wrap {
  position: relative;

  cursor: pointer;

  @include media(1281) {
    &:last-child {
      .five-actions__item-text {
        transform: translateX(-65%);
      }
    }
    &:first-child {
      .five-actions__item-text {
        transform: translateX(-35%);
      }
    }
  }

  @include media(769) {
    margin-bottom: 25px;

    &:last-child,
    &:first-child {
      .five-actions__item-text {
        transform: translate(-50%, -50%);
      }
    }
  }

  &:hover {
    .five-actions__item-text {
      opacity: 1;
      visibility: visible;
    }
  }
}

.five-actions__item-text {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  // overflow: auto;

  width: 335px;
  // max-height: 242px;

  padding: 25px 10px 5px;

  background: var(--color-white);

  opacity: 0;
  visibility: hidden;

  transition: all 400ms ease;

  font-size: adaptive-calc(22px, 16px);
  font-weight: 500;

  * {
    font-size: adaptive-calc(22px, 16px);
    font-weight: 500;
  }

  @include media(769) {
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    // align-items: center;

    min-height: 100%;
    max-height: 100%;

    padding: 10px;
  }
}

.five-actions__item-info-list {
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  // overflow: hidden;

  width: calc(100% + 40px);

  padding: 0 20px;

  background-color: var(--color-white);

  opacity: 0;
  visibility: hidden;

  transition: opacity 300ms ease, visibility 300ms ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  @include media(769) {
    top: -1px;
    left: 0;
    transform: none;

    width: 100%;
    height: 100%;

    padding: 0;
  }
}

.five-actions__item-info-item {
  // position: absolute;
  // top: 50%;
  // left: 0;
  // transform: translateY(-50%);

  display: flex;
  align-items: center;

  justify-content: space-between;

  width: 100%;
  max-height: adaptive-calc(278px, 230px);

  background-color: var(--color-white);

  padding: 0 adaptive-calc(100px, 0px);

  // opacity: 0;
  // visibility: hidden;

  // transition: opacity 300ms ease, visibility 300ms ease;
  // cursor: pointer;

  @include media(769) {
    flex-direction: column;
    top: 0;
    // left: 50%;
    // transform: translateX(-50%);

    justify-content: center;

    width: auto;
    height: 100%;
    max-height: unset;
  }

  &.green {
    .five-actions__item-num {
      background-color: var(--color-green);
    }
  }

  &.red {
    .five-actions__item-num {
      background-color: var(--color-red);
    }
  }

  &.yellow {
    .five-actions__item-num {
      background-color: var(--color-yellow);
    }
  }

  &.dark-blue {
    .five-actions__item-num {
      background-color: var(--color-blue-dark);
    }
  }

  &.blue {
    .five-actions__item-num {
      background-color: var(--color-blue);
    }
  }

  // &.active {
  //   opacity: 1;
  //   visibility: visible;
  // }
}

.five-actions__item-info-item-close {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 29px;
  height: 29px;

  border-radius: 50%;

  background: var(--color-blue-dark);
  color: var(--color-white);
  font-size: 10px;

  cursor: pointer;

  transition: background ease 250ms;

  &:hover {
    background: var(--color-red);
  }

  @include media(769) {
    width: 40px;
    height: 40px;

    font-size: 15px;
  }
}

.five-actions__item-num {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 180px;
  height: 180px;
  flex-shrink: 0;

  margin-right: 30px;
  border-radius: 50%;

  color: var(--color-white);

  font-size: 111px;

  @include media(769) {
    width: 250px;
    height: 250px;

    margin-right: 0;
    margin-bottom: 30px;
  }
}

.five-actions__item-text-wrap {
  width: 100%;
  max-width: 768px;

  text-align: left;
}

.five-actions__item-title {
  margin-bottom: 17px;

  font-size: 26px;
}

.five-actions__item-descr {
  width: 100%;
  max-height: 132px;

  font-size: adaptive-calc(26px, 18px);

  @include media(769) {
    max-height: 370px;
  }

  .simplebar-mask {
    margin-right: 25px;
  }
}

.five-actions__item-info-list-nav {
  position: absolute;
  bottom: adaptive-calc(0px, -40px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  display: flex;

  opacity: 0;
  visibility: hidden;

  transition: opacity 300ms ease, visibility 300ms ease;

  @include media(769) {
    bottom: -20px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.five-actions__nav {
  display: flex;
  align-items: center;

  margin-right: 30px;

  @include media(769) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.five-actions__nav-btn {
  width: 23px;
  height: 23px;

  font-size: 9px;

  transition: transform ease 250ms;
  color: var(--color-white);
  background: var(--color-blue-dark);

  @include media(769) {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  &:hover {
    transform: scale(1.1);
  }

  &.prev {
    margin-right: 10px;
  }

  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      transform: none;
    }
  }
}

.campaigns-section {
  .decor {
    pointer-events: none;

    img.campaigns-section__decor-img:last-child {
      top: 0;
      right: 0;
      left: unset;
    }
  }

  .title-box {
    // justify-content: space-between;
    // h2 {
    //   width: 37%;

    //   margin-right: 10px;
    // }
  }

  .video-sec__cont p {
    max-width: 572px;
  }
}

.campaigns__slider {
  max-width: 376px;
  padding-bottom: 52px;
  margin: 0 auto;
  overflow: visible;

  .campaigns__slide {
    opacity: 0.5;

    transition: opacity ease 750ms;
  }

  .swiper-slide-active {
    opacity: 1;
  }

  .swiper-slide-next,
  .swiper-slide-prev {
    opacity: 1;
  }
}

.events-section {
  padding-bottom: adaptive-calc(127px, 40px);
}

.events-section__title {
  margin-bottom: 28px;
}

.events__swiper {
  overflow: hidden;

  .swiper-slide {
    height: initial;
  }
}

.events__card {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.events__card-img {
  width: 100%;
  object-fit: cover;

  margin-bottom: -72px;
}

.events__card-text-wrap {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 28px 18px 20px;

  clip-path: polygon(
    20% -1px,
    85% -1px,
    100% 32%,
    100% 80%,
    100% 100%,
    15% 100%,
    0 68%,
    0 -1px
  );

  background-color: var(--color-blue-dark);
  color: var(--color-white);

  .btn {
    margin-left: auto;
    margin-top: auto;
  }
}

.events__card-title {
  margin-bottom: 15px;

  font-size: adaptive-calc(32px, 22px);
  font-weight: 500;
}

.events__card-date {
  margin-bottom: 18px;

  font-size: 18px;
}

.modal-radio-button-title {
  font-size: 18px;
  font-weight: 400;
  line-height: unset;
}

.modal-dropdown-list {
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.modal-radio-button-list {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .flex-box {
    display: flex;

    .modal-radio-button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .apply-form__input {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.modal-radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    width: 15px;
    height: 15px;

    margin-right: 10px;
    margin-top: 0 !important;
  }

  div {
    font-size: adaptive-calc(20px, 16px);
  }

  &.with-ethnicity {
    div {
      font-size: 18px;
    }
  }
}

[data-prefer-input] {
  display: none;

  &.active {
    display: block;
  }
}

.swiper-pagination-bullet-active-prev-prev,
.swiper-pagination-bullet-active-prev,
.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-next-next {
  transform: none !important;
}

.swiper-backface-hidden .swiper-slide {
  transform: unset !important;
  backface-visibility: unset !important;
}

.engaging-search-section {
  height: 1339px;

  padding: 120px 0 140px;

  color: var(--color-white);
  background: var(--color-blue-dark);

  @include media(1025) {
    height: auto;
  }

  @include media(769) {
    padding: 50px 0;
  }

  .cont.engaging-search-cont {
    padding-left: adaptive-calc(250px, 0px, 1024);

    @include media(1025) {
      width: 80.8%;
    }
  }
}

.engaging-search-section__title {
  padding-left: adaptive-calc(150px, 0px);
  margin-bottom: adaptive-calc(40px, 24px);

  color: var(--white);
}

.engaging-search__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 159px;

  transition: margin ease 250ms;

  @include media(1025) {
    align-items: flex-start;

    width: 100%;
    max-width: 450px;

    margin: 0 auto;
  }

  &.is-exec {
    margin-top: 50px;

    .engaging-search__text {
      &.interim {
        display: none;
      }

      &.exec {
        display: block;
      }
    }

    .engaging-search__title-interim {
      display: none;
    }

    .engaging-search__title-exec {
      display: block;
    }

    .engaging-search-mob__text {
      &.interim {
        display: none;
      }

      &.exec {
        display: block;
      }
    }

    .engaging-search__select-comment {
      opacity: 1;
      visibility: visible;
    }
  }
}

.engaging-search {
  position: relative;
  // position: absolute;
  // display: none;

  width: 100%;
  height: 428.7px;

  // transform: scale(0);

  transition: transform ease 400ms;

  @include media(1025) {
    display: none;
  }

  &.active {
    // position: relative;
    // display: block;
    // transform: scale(1);

    .engaging-search__item {
      // box-shadow: 0 3px 13px rgba(0, 0, 0, 0.3);
      opacity: 1;

      &:nth-child(2) {
        --rotate-deg: 20deg;
        z-index: 9;
      }

      &:nth-child(3) {
        --rotate-deg: 40deg;
        z-index: 8;
      }
      &:nth-child(4) {
        --rotate-deg: 60deg;
        z-index: 7;
      }
      &:nth-child(5) {
        --rotate-deg: 80deg;
        z-index: 6;
      }
      &:nth-child(6) {
        --rotate-deg: 100deg;
        z-index: 5;
      }
      &:nth-child(7) {
        --rotate-deg: 120deg;
        z-index: 4;
      }
      &:nth-child(8) {
        --rotate-deg: 140deg;
        z-index: 3;
      }
      &:nth-child(9) {
        --rotate-deg: 160deg;
        z-index: 2;
      }
      &:nth-child(10) {
        --rotate-deg: 180deg;
        z-index: 1;
      }

      .engaging-search__text {
        opacity: 1;
      }
    }
  }
}

.engaging-search__item {
  --rotate-deg: 0deg;

  position: absolute;
  // top: 187px;
  bottom: 0;

  width: 159px;
  height: 428.7px;
  opacity: 0;

  color: var(--color-gray);

  transform-origin: right bottom;
  transform: rotate(var(--rotate-deg)) scale(0.75);

  z-index: 10;

  &.is-exec {
    .engaging-search__text {
      &.interim {
        display: none;
      }

      &.exec {
        display: block;
      }
    }
  }

  &:first-child,
  &:last-child {
    height: 479px;

    .engaging-search__text {
      max-width: adaptive-calc(545px, 375px, 1024);
    }
  }

  &:nth-child(2),
  &:nth-child(7) {
    color: var(--color-blue);
  }

  &:nth-child(3),
  &:nth-child(8) {
    color: var(--color-green);
  }

  &:nth-child(4),
  &:nth-child(9) {
    color: var(--color-yellow);
  }

  &:nth-child(5),
  &:nth-child(10) {
    color: var(--color-red);
  }

  transition: transform ease 400ms, box-shadow ease 400ms, opacity ease 700ms;

  svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.engaging-search__text {
  position: absolute;
  bottom: 100%;
  left: 100%;
  transform-origin: left center;
  transform: rotate(calc(var(--rotate-deg) * -1));

  width: max-content;
  max-width: adaptive-calc(443px, 375px, 1025);

  color: var(--color-white);

  font-family: var(--font-main);
  font-size: adaptive-calc(22px, 18px);
  font-weight: 300;

  opacity: 0;

  transition: opacity 400ms ease, transform ease 400ms;

  &.exec {
    display: none;
  }

  strong {
    font-weight: 700;
  }

  * {
    color: var(--color-white);

    font-family: var(--font-main);
    font-size: adaptive-calc(22px, 18px, 1024);
    font-weight: 300;
  }
}

.engaging-search__item-svg {
  overflow: visible;
  filter: drop-shadow(0 3px 13px rgba(0, 0, 0, 0.3));
}

.engaging-search__select-wrap {
  position: relative;

  padding-top: 10px;
  padding-right: adaptive-calc(27px, 12px);

  @include media(1025) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    margin-bottom: 23px;
    padding-right: 0;
  }
}

.engaging-search__select-comment {
  width: fit-content;

  margin-left: auto;
  margin-top: 33px;

  opacity: 0;
  visibility: hidden;

  transition: opacity ease 250ms, visibility ease 250ms;

  @include media(1025) {
    position: absolute;
    bottom: calc(100% + 9px);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
  }
}

.engaging-search__select-comment-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 13px;
  }

  @include media(1025) {
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  img {
    width: 27px;
    height: 27px;
  }
}

.engaging-search__select-comment-item-text {
  margin-left: 11px;

  font-family: var(--font-main);
  font-size: 12px;
  font-weight: 200;
}

.engaging-search__title {
  margin-bottom: adaptive-calc(25px, 15px, 1025);
  font-family: var(--font-main);
  font-size: adaptive-calc(50px, 20px, 1024);
  font-weight: 600;
  line-height: 1.15;
  text-align: right;

  @include media(1025) {
    margin-bottom: 0;

    font-size: 32px;
    text-align: left;
  }

  @include media(501) {
    font-size: 20px;
  }
}

.engaging-search__title-exec {
  display: none;

  white-space: nowrap;
}

.engaging-search__select {
  display: flex;
  justify-content: flex-end;
}

.engaging-search__select-checkbox-wrap {
  cursor: pointer;

  &:hover {
    .engaging-search__select-custom-checkbox {
      &::before {
        background: #807d8a;
      }
    }
  }

  .engaging-search__select-default-checkbox:checked
    ~ .engaging-search__select-custom-checkbox {
    &::before {
      transform: translateY(100%);
    }
  }
}

.engaging-search__select-default-checkbox {
  display: none;
}

.engaging-search__select-custom-checkbox {
  display: flex;
  justify-content: center;

  width: adaptive-calc(30px, 22px);
  height: adaptive-calc(57px, 44px);

  padding: adaptive-calc(2px, 1px);
  border-radius: 50px;

  background: var(--color-white);

  &::before {
    content: "";

    display: block;

    width: 100%;
    height: 50%;

    border-radius: 50%;

    background: #c9c4d9;

    transition: transform ease 250ms, background ease 250ms;
  }
}

.engaging-search__select-variants-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin-right: 15px;
}

.engaging-search__select-variant {
  font-family: var(--font-main);
  font-size: adaptive-calc(21px, 12px, 1024);
  font-weight: 700;
  text-align: right;

  opacity: 0.4;

  transition: opacity ease 250ms;

  &.active {
    opacity: 1;
  }
}

.engaging-search-mob {
  display: none;

  &.active {
    .engaging-search-mob__item {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @include media(1025) {
    display: block;
  }
}

.engaging-search-mob__item {
  display: flex;

  color: var(--color-gray);

  transform: translateX(100%);
  opacity: 0;

  transition: opacity ease 400ms, transform ease 400ms;

  &:not(:last-child) {
    margin-bottom: 9px;
  }

  &:nth-child(2),
  &:nth-child(7) {
    color: var(--color-blue);
  }

  &:nth-child(3),
  &:nth-child(8) {
    color: var(--color-green);
  }

  &:nth-child(4),
  &:nth-child(9) {
    color: var(--color-yellow);
  }

  &:nth-child(5),
  &:nth-child(10) {
    color: var(--color-red);
  }
}

.engaging-search-mob__item-triangle {
  overflow: visible;

  flex-shrink: 0;

  width: adaptive-calc(15px, 10px, 601, 1024);

  margin-top: 0.5em;
  margin-right: 14px;

  font-size: adaptive-calc(20px, 14px, 601, 1024);
}

.engaging-search-mob__text {
  color: var(--color-white);

  font-family: var(--font-main);
  font-size: adaptive-calc(20px, 14px, 601, 1024);

  strong {
    font-weight: 700;
  }

  * {
    color: var(--color-white);

    font-family: var(--font-main);
    font-size: adaptive-calc(20px, 14px, 601, 1024);
    font-weight: 300;
  }

  &.exec {
    display: none;
  }
}

.cards__swiper {
  overflow: visible !important;

  &.cards__swiper-hiring {
    .card-about {
      cursor: pointer;
    }
  }

  .swiper-slide {
    position: relative;
  }
}

.card-about {
  display: flex;
  flex-direction: column;

  clip-path: polygon(
    0 0,
    calc(100% - 58px) 0,
    100% 58px,
    100% 100%,
    58px 100%,
    0 calc(100% - 58px)
  );

  height: 100%;

  padding: 29px 18px 35px;

  color: var(--color-white);
  background: var(--color-blue-dark);

  font-family: var(--font-main);

  // cursor: pointer;

  transition: background ease 250ms;

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    z-index: -1;

    background: var(--color-white);

    opacity: 0;

    transition: opacity ease 250ms;

    clip-path: polygon(
      0 0,
      calc(100% - 57px) 0,
      100% 57px,
      100% 100%,
      57px 100%,
      0 calc(100% - 57px)
    );
  }

  &:hover {
    color: var(--color-blue-dark);

    &::before {
      opacity: 1;
    }

    * {
      color: var(--color-blue-dark);
    }

    .card-about__link,
    .card-about__btn {
      color: var(--color-blue-dark);
    }
  }

  &.active {
    color: var(--color-blue-dark);

    &::before {
      opacity: 1;
    }

    .card-about__hidden-text {
      opacity: 1;
      color: var(--color-blue-dark);
    }

    .card-about__main-text {
      color: var(--color-blue-dark);
    }

    .card-about__btn {
      span {
        transform: rotate(-90deg);
      }
    }
    .card-about__link {
      display: flex !important;
      color: var(--color-blue-dark);
    }

    .card-about__btn {
      display: none;
    }
  }
}

.card-about__title {
  margin-bottom: 20px;

  font-size: 32px;
  font-weight: 500;
}

.card-about__text {
  max-height: adaptive-calc(410px, 300px);

  overflow: auto;

  margin-bottom: 44px;

  font-size: 18px;

  * {
    color: var(--color-white);
  }

  @include media(551) {
    max-height: 244px;
  }
}

.card-about__hidden-text {
  overflow: hidden;
  max-height: 0;
  opacity: 0;

  transition: max-height 250ms ease, max-height 250ms ease;
}

.card-about__main-text {
  @include max-line-leng(5);
}

.card-about__btn,
.card-about__link {
  // margin-top: auto;
  margin-left: auto;
  margin-right: 0 !important;

  color: var(--color-white);

  span {
    transition: transform 250ms ease;
  }
}

.card-about__btn {
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
  &:first-of-type {
    margin-top: auto;
  }
}

.card-about__link {
  display: none !important;
}

.eapps-instagram-feed {
  z-index: 1 !important;
}

.btn.cards__popup-btn {
  margin-top: 10px;
  margin-left: auto;
  margin-right: 0;
}

.apply-form-info {
  position: relative;
}

.apply-form-info__main {
  // display: flex;
  // align-items: center;

  width: fit-content;
}

.apply-form-info__main-text {
  display: inline-block;

  margin-right: adaptive-calc(10px, 5px);

  color: var(--color-blue-2);

  font-size: adaptive-calc(20px, 14px);
  line-height: 1.5;

  cursor: default;
}

.apply-form-info__main-icon {
  display: inline-block;

  color: var(--color-blue-dark);

  transition: color 250ms ease;
  cursor: default;

  // cursor: pointer;

  // &:hover {
  //   color: var(--color-blue-2);
  // }
}

.apply-form-info__hidden-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 999999;

  background: rgba(0, 0, 0, 0.4);

  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.apply-form-info__hidden-close {
  position: absolute;
  top: 15px;
  right: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 23px;
  height: 23px;

  font-size: 11px;

  color: var(--color-white);
  background: var(--color-yellow);
}

.apply-form-info__hidden-text {
  overflow: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  max-height: 90%;
  max-width: 90%;

  // max-height: 175px;
  width: 70%;

  background: var(--color-white);

  font-size: 14px;

  // opacity: 0;
  // visibility: hidden;

  transition: opacity 250ms ease, visibility 250ms ease;

  p {
    text-align: justify;
  }

  a {
    display: inline-block;

    color: var(--color-blue-2);

    &:hover {
      text-decoration: underline;
    }
  }

  #apply-form-info__close {
    position: absolute;
  }
}

.cv-upload-additional-text {
  grid-column: 1/-1;

  // margin-bottom: -16px;

  font-size: 14px;

  @include media(551) {
    margin-bottom: 0px;
  }
}

.transition-none {
  transition: none !important;
}

.font-bold {
  font-weight: 700 !important;
}

.checkBox.cv-checkbox {
  margin: 0;
  // margin: -16px 0 0;
  .check-title {
    font-size: 14px;
  }

  @include media(551) {
    margin: 0;

    &:not(:last-of-type) {
      // margin: -16px 0 0;
    }
  }

  @include media(415) {
    margin: 0;

    &:not(:last-of-type) {
      margin: 0;
    }
  }
}

.clients-slider__pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
}

.clients-slider__pagination {
  transform: none !important;

  // margin: 0 auto;
  margin-left: 10px;

  .swiper-pagination-bullet {
    border-color: var(--color-blue-dark);

    &.swiper-pagination-bullet-active {
      background: var(--color-blue-dark);
    }
  }
}

.say__total-swiper-pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
}

.say__total-swiper-pagination {
  transform: none !important;

  // margin: 0 auto;
  margin: 0 10px;
  width: fit-content !important;
}

.say__total-swiper-btn {
  color: var(--color-white);
}

.apply-form {
  .select2-container--default .select2-selection--single {
    display: flex;
    align-items: center;

    .select2-selection__arrow {
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

.popup-loader__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 111;
  background: rgba(255, 255, 255, 0.5);

  opacity: 0;
  visibility: hidden;

  transition: opacity ease 250ms, visibility ease 250ms;

  &.is-show {
    opacity: 1;
    visibility: visible;
  }
}

.popup-loader {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border: 5px solid var(--color-white);
  border-bottom-color: var(--color-blue-2);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.lets-talk {
  * {
    color: var(--color-white);
  }

  a {
    display: inline-block;
    color: var(--color-yellow-2);

    &:hover {
      text-decoration: underline;
    }
  }
}

// .btn {
//   display: flex;
//   align-items: center;
//   width: fit-content;
//   font-size: min(max(16px + 6 * ((100vw - 768px)/672), 16px), 22px);
//   font-weight: 600;
//   line-height: 1.4090909090909092em;

//   transition: all ease 250ms;
// }

// .btn.blue:hover {
//   color: var(--color-blue);
// }
// .btn.blue span {
//   background-color: var(--color-blue);
// }
// .btn.yellow:hover {
//   color: var(--color-yellow-2);
// }
// .btn.yellow span {
//   background-color: var(--color-yellow-2);
// }
// .btn.red:hover {
//   color: var(--color-red);
// }
// .btn.red span {
//   background-color: var(--color-red);
// }
// .btn.green:hover {
//   color: var(--color-green-2);
// }
// .btn.green span {
//   background-color: var(--color-green-2);
// }

// .btn span {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: min(max(25px + 7 * ((100vw - 768px)/672), 25px), 32px);
//   height: min(max(25px + 7 * ((100vw - 768px)/672), 25px), 32px);
//   background-color: var(--color-blue-dark);
//   margin-left: min(max(24px + -12 * ((100vw - 768px)/672), 12px), 24px);
// }

// .btn span::before {
//   position: absolute;
//   content: "";
//   display: inline-block;
//   border-left: 13px solid var(--color-white);
//   border-top: 7px solid transparent;
//   border-bottom: 7px solid transparent;
//   margin-left: 2px;
// }

.acsb-trigger {
  bottom: 90px !important;
}

.popup {
  &.loading {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;

    background: rgba(255, 255, 255, 0.5);

    opacity: 0;
    visibility: hidden;
    transition: all ease 250ms;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 12;
    transform: translate(-50%, -50%);

    width: 48px;
    height: 48px;
    margin: 0 auto;
    border: 5px solid #fff;
    border-bottom-color: #127fff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    opacity: 0;
    visibility: hidden;
    transition: all ease 250ms;

    @keyframes rotation {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  }
}

.grecaptcha-badge {
  bottom: 85px !important;
}
