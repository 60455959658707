.insight-card {
  display: flex;
  flex-direction: column;

  .photo {
    @include photo-aspect-ratio(16, 9, 250px);
    border-radius: 20px;
    margin-bottom: 20px;

    img {
      @extend %default-transition;
    }
  }

  .top-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .date {
    font-family: var(--font-main);
    font-size: adaptive-calc(14px, 11px);
    color: var(--primary);
    margin-left: auto;
    margin-bottom: 10px;
  }

  h5 {
    @include max-line-leng(2);
  }

  p {
    @include max-line-leng(5);
    margin-bottom: 20px;
  }

  &:hover {
    .photo img {
      transform: scale(1.12);
    }
  }
}

.insight-first-section {

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(168deg,rgba(var(--black-rgb),1) 34%,rgba(var(--primary-rgb),1) 71%);
  }

  .date {
    font-family: var(--font-main);
    font-size: adaptive-calc(18px, 14px);
    color: var(--primary);
    margin-bottom: 10px;
  }

  @include media(769) {
    &[data-with-photo] {
      &::before {
        mix-blend-mode: multiply;
      }
    }
  }
}

.insights-content {
  margin: adaptive-calc(75px, 30px) 0;
}

.insights-result {
  margin-top: adaptive-calc(120px, 70px);
}

.insights-recommendation {
  h3 {
    margin-bottom: adaptive-calc(40px, 20px);
  }
}

