@use "sass:math";

@media only screen and (max-width: 1281px) {
  .testimonials{
    &__slider.swiper{
      max-width: 768px;
    }
  }
}

@media only screen and (max-width: 1025px) {}

@media only screen and (max-width: 901px) {}

@media only screen and (max-width: 769px) {
  .testimonials{
    .testimonials-decor{
      display: none;
    }
    &__slider.swiper{
      max-width: 376px;
    }
  }
}

@media only screen and (max-width: 651px) {
  .insta{
    &__soc{
      flex-direction: column;
      align-items: baseline;

      h2{
        margin-right: 0px;
      }
    }

    &__soc-box{
      margin-top: 15px;
    }
  }
}

@media only screen and (max-width: 551px) {

  .lookout{
    .decor{
      display: none;
    }

  }

  .insta{
    &__grid{
      grid-template-columns: repeat( auto-fit, minmax(140px, 1fr) );
    }
  }
}

@media only screen and (max-width: 421px) {

  .impact{
    &__slider{
      max-width: 100%;
      position: relative;
      height: 200px;
    }

    &__slide-img{
      img{
        clip-path: polygon(0 0,89% 0,100% 21%,100% 100%,100% 100%,11% 100%,0 81%,0 0);
      }
    }

  }

}

@media only screen and (max-width: 361px) {}