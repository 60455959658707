@charset "UTF-8";
/* Color auto generator
function */
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --color-white: #fff;
  --color-blue: #0280ad;
  --color-blue-2: #0f80ad;
  --color-blue-dark: #01013d;
  --color-yellow: #fda200;
  --color-yellow-2: #ffa300;
  --color-yellow-dark: #fea32d;
  --color-green: #00a217;
  --color-green-2: #219e2a;
  --color-red: #a7005c;
  --color-gray: #5e5b72;
  --header-height: 162px;
  --header-height-scrolled: calc(var(--header-height) - 24px);
  --font-main: "Lexend", sans-serif;
  --plyr-color-main: var(--color-blue);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: block;
}

a:hover {
  text-decoration: none;
}

html,
body {
  height: 100%;
}

input,
textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

label,
button,
input[type=submit],
input[type=button] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?tbm1m8");
  src: url("../fonts/icomoon.eot?tbm1m8#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?tbm1m8") format("truetype"), url("../fonts/icomoon.woff?tbm1m8") format("woff"), url("../fonts/icomoon.svg?tbm1m8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter-black:before {
  content: "\e900";
}

.icon-linkedin-black:before {
  content: "\e907";
}

.icon-twitter:before {
  content: "\e908";
  color: #e4b700;
}

.icon-linkedin:before {
  content: "\e901";
  color: #ffa300;
}

.icon-instagram:before {
  content: "\e902";
  color: #ffa300;
}

.icon-youtube:before {
  content: "\e903";
  color: #ffa300;
}

.icon-facebook:before {
  content: "\e90b";
}

.icon-brand:before {
  content: "\e90b";
}

.icon-social:before {
  content: "\e90b";
}

.icon-right:before {
  content: "\e905";
}

.icon-left:before {
  content: "\e906";
}

.icon-info:before {
  content: "\e90e";
}

.icon-upload:before {
  content: "\e90d";
}

.icon-cross:before {
  content: "\e90c";
}

.icon-slider-left:before {
  content: "\e909";
}

.icon-slider-right:before {
  content: "\e90a";
}

.icon-email:before {
  content: "\e904";
  color: #01033d;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: var(--font-main);
  color: var(--color-blue-dark);
}

h1 {
  font-size: min(max(
            22px + 25 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 47px);
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: min(max(
            21px + 23 *
              (
                (100vw - 768px) / 672
              )
          , 21px), 44px);
  font-weight: 700;
  line-height: 1.3em;
}

h3 {
  font-size: min(max(
            18px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 25px);
  font-weight: 700;
  line-height: 2.03125em;
}

h4 {
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  font-weight: 700;
  line-height: 1.8em;
}

h5 {
  font-size: min(max(
            12px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 16px);
  font-weight: 700;
  line-height: 1.1428571429em;
}

p {
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  line-height: 1.7777777778em;
}

.content-element {
  /*blockquote {
    font-style: italic;
    position: relative;
    padding-left: adaptive-calc(25px, 14px);
    margin-bottom: 40px;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: adaptive-calc(4px, 2px);
      border-radius: 10px;
      background: var(--text);
    }
  }

  img {
    border-radius: adaptive-calc(40px, 20px);
    margin-top: adaptive-calc(20px, 10px);
    margin-bottom: adaptive-calc(76px, 35px);
  }*/
}
.content-element *:last-child {
  margin-bottom: 0;
}
.content-element h1,
.content-element h2,
.content-element h3 {
  margin-bottom: 10px;
}
.content-element h1:not(:first-child),
.content-element h2:not(:first-child),
.content-element h3:not(:first-child) {
  margin-top: min(max(
            15px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 20px);
}
.content-element h1 {
  font-size: min(max(
            33px + 37 *
              (
                (100vw - 768px) / 672
              )
          , 33px), 70px);
  font-weight: 700;
  line-height: 1.2em;
}
.content-element h2 {
  font-size: min(max(
            32px + 18 *
              (
                (100vw - 768px) / 672
              )
          , 32px), 50px);
  font-weight: 700;
  line-height: 1.3em;
}
.content-element h3 {
  font-size: min(max(
            22px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 32px);
  font-weight: 700;
  line-height: 2.03125em;
}
.content-element p {
  padding: 0;
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  line-height: 1.7777777778em;
  margin-bottom: min(max(
            15px + 16 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 31px);
}
.content-element a {
  display: inline-block;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;
  color: var(--color-blue);
}
.content-element a:hover {
  text-decoration: underline;
}
.content-element li,
.content-element blockquote {
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  line-height: 1.7777777778em;
  font-family: var(--font-main);
}
.content-element ul,
.content-element ol {
  margin-bottom: min(max(
            15px + 16 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 31px);
}
.content-element ul {
  list-style: none;
}
.content-element ul li {
  position: relative;
  padding-left: 15px;
}
.content-element ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  /*background: url('../images/triangle-li-style.svg') no-repeat center center / contain;*/
  display: block;
  flex-shrink: 0;
  margin-right: 5px;
}
.content-element ol {
  list-style-type: decimal;
  list-style-position: inside;
}

html {
  font-size: 16px;
  scrollbar-width: thin;
  scrollbar-color: var(--color-blue) var(--popup-light-gray);
}
@media only screen and (max-width: 1450px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1025px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 551px) {
  html {
    font-size: 10px;
  }
}

body * {
  scrollbar-width: thin;
  scrollbar-color: var(--color-blue) var(--popup-light-gray);
}

body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body *::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}

body *::-webkit-scrollbar-thumb {
  background-color: var(--color-blue);
}

.fp-watermark {
  display: none;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  color: var(--color-blue-dark);
}
body.lock {
  overflow: hidden;
}

.snackbar-container {
  z-index: 99999999 !important;
}

#tidio-chat-iframe {
  top: auto !important;
  right: 9px !important;
  bottom: 70px !important;
  left: auto !important;
}

.grecaptcha-badge {
  z-index: 999 !important;
}

.plyr {
  cursor: pointer;
}

#typed-header {
  height: calc(min(max(
            33px + 37 *
              (
                (100vw - 768px) / 672
              )
          , 33px), 70px) * 1.2 * 2);
}
@media only screen and (max-width: 551px) {
  #typed-header {
    height: calc(min(max(
            33px + 37 *
              (
                (100vw - 768px) / 672
              )
          , 33px), 70px) * 1.7 * 3);
  }
}
@media only screen and (max-width: 405px) {
  #typed-header {
    height: calc(min(max(
            33px + 37 *
              (
                (100vw - 768px) / 672
              )
          , 33px), 70px) * 1.7 * 4);
  }
}

.swiper-wrapper {
  left: 0 !important;
}

.swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  border: 1px solid var(--color-white);
  background-color: transparent;
  margin: 0 11px 0 0 !important;
  opacity: 1;
}
.swiper-pagination-bullet:last-child {
  margin-right: 0 !important;
}

.swiper-pagination-bullet:hover,
.swiper-pagination-bullet-active {
  background-color: var(--color-white);
}

#calendly-wrap {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
#calendly-wrap.active {
  display: block;
}
#calendly-wrap .calendly-close {
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 999999;
  color: var(--color-white);
  cursor: pointer;
}

#site {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.select2-results__option, .btn, .header.scrolled .header__list, .footer__form form input::placeholder,
.footer__form form textarea::placeholder, .footer__form form input,
.footer__form form textarea, .footer__policy a, .footer__socials a i, .footer__socials a, .footer__menu a, .footer__text p, .footer__links a, .footer__logo img, .header__layer, .header__burger::before, .header__burger::after,
.header__burger span, .header__links li a, .header__links, .header__list-item span:before, .header__list-item span a, .header__list-item span, .header__list-item .header__list-link, .header__list, .header, .team-member-card .photo img, .insight-card .photo img, .head-team__form input,
.head-team__form textarea, .jobs__del-city.jobs__card-cont p, .jobs__del-city.jobs__card-cont a,
.jobs__del-city.jobs__card-cont h4, .jobs__del-city.jobs__card-cont h5, .jobs__card-cont p, .jobs__card-cont h5, .jobs__card .btn span.icon-dark::before, .jobs__card .btn, .cards__item .btn span.icon-dark::before, .cards__item h3,
.cards__item p,
.cards__item .btn, .cards__item::before, .tab__nav h2,
.tab__nav .tab__head, .swiper-pagination-bullet {
  transition: all 0.3s;
}

.cont {
  margin: 0 auto;
  max-width: 1350px;
  width: 93.75%;
}

section {
  position: relative;
}

.dec {
  pointer-events: none;
}
.dec img {
  position: absolute;
  display: inline-block;
  width: auto;
  height: auto;
}

.dec-small {
  pointer-events: none;
}
.dec-small img {
  position: absolute;
  display: inline-block;
  max-width: min(max(
            150px + 154.16 *
              (
                (100vw - 768px) / 672
              )
          , 150px), 304.16px);
  height: auto;
}

.dec-big {
  pointer-events: none;
}
.dec-big img {
  position: absolute;
  display: inline-block;
  max-width: min(max(
            130px + 151.39 *
              (
                (100vw - 768px) / 672
              )
          , 130px), 281.39px);
  height: auto;
}

.dec-three {
  pointer-events: none;
}
.dec-three img {
  position: absolute;
  display: inline-block;
  max-width: min(max(
            150px + 186 *
              (
                (100vw - 768px) / 672
              )
          , 150px), 336px);
  height: auto;
}

.decor {
  pointer-events: none;
}
.decor img {
  position: absolute;
  display: inline-block;
  width: auto;
  height: auto;
}

.banner {
  position: relative;
  width: 100%;
  background-color: var(--color-blue-dark);
  padding: min(max(
            165px + 133 *
              (
                (100vw - 768px) / 672
              )
          , 165px), 298px) 0 min(max(
            236px + -81 *
              (
                (100vw - 768px) / 672
              )
          , 155px), 236px) 0;
}
.banner .decor {
  pointer-events: none;
}
.banner .decor img {
  bottom: -1px;
  right: -2px;
  transform-origin: right bottom;
  max-height: 450px;
}
.banner__cont {
  position: relative;
  max-width: 100% !important;
}
.banner__cont .title {
  font-weight: 600;
  color: var(--color-white);
  margin-bottom: min(max(
            20px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 32px);
}
.banner__cont .title mark {
  padding: 0 2px 0 4px;
  background-color: var(--color-green);
  color: var(--color-white);
}
.banner__cont .btn {
  color: var(--color-white);
}

.tab {
  position: relative;
  width: 100%;
  padding: min(max(
            66px + 35 *
              (
                (100vw - 768px) / 672
              )
          , 66px), 101px) 0 min(max(
            66px + -13 *
              (
                (100vw - 768px) / 672
              )
          , 53px), 66px) 0;
}
.tab .decor {
  pointer-events: none;
}
.tab .decor img {
  top: 0;
  left: 0;
  transform-origin: top left;
}
.tab__block {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 962px;
  margin: 0 auto;
}
.tab__nav {
  display: inline-block;
  padding-left: 10px;
  padding-right: 20px;
}
.tab__nav h2,
.tab__nav .tab__head {
  cursor: pointer;
  font-weight: 600;
  opacity: 0.4;
}
.tab__nav h2:last-child,
.tab__nav .tab__head:last-child {
  margin-bottom: 0;
}
.tab__nav h2:hover, .tab__nav h2.active,
.tab__nav .tab__head:hover,
.tab__nav .tab__head.active {
  opacity: 1;
}
.tab__nav h2:hover.yellow, .tab__nav h2.active.yellow,
.tab__nav .tab__head:hover.yellow,
.tab__nav .tab__head.active.yellow {
  color: var(--color-yellow);
}
.tab__nav h2:hover.blue, .tab__nav h2.active.blue,
.tab__nav .tab__head:hover.blue,
.tab__nav .tab__head.active.blue {
  color: var(--color-blue);
}
.tab__nav h2:hover.green, .tab__nav h2.active.green,
.tab__nav .tab__head:hover.green,
.tab__nav .tab__head.active.green {
  color: var(--color-green);
}
.tab__nav h2:hover.red, .tab__nav h2.active.red,
.tab__nav .tab__head:hover.red,
.tab__nav .tab__head.active.red {
  color: var(--color-red);
}
.tab__nav h2 img,
.tab__nav .tab__head img {
  width: 183px;
  max-width: 100%;
  max-height: 62px;
}
.tab__nav.partnerships {
  max-height: 330px;
  overflow: auto;
}
.tab__nav.partnerships .tab__head:not(:last-child) {
  margin-bottom: 25px;
}
.tab__swiper {
  overflow: hidden;
  width: 100%;
  max-width: 572px;
}
@media only screen and (max-width: 769px) {
  .tab__swiper {
    overflow: visible;
  }
}
.tab__swiper .swiper-pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab__swiper .swiper-pagination-wrap.hidden-for-pc {
  display: none;
}
@media only screen and (max-width: 768px) {
  .tab__swiper .swiper-pagination-wrap.hidden-for-pc {
    display: flex;
  }
}
.tab__swiper .swiper-pagination {
  position: relative;
  bottom: auto;
  left: unset !important;
  transform: unset !important;
  width: fit-content;
  margin: 0 14px;
}
.tab__swiper .tab__swiper-btn {
  color: var(--color-white);
  font-size: 16px;
}
.tab__swiper.partnerships {
  max-width: 650px;
  padding-left: min(max(
            55px + 21 *
              (
                (100vw - 768px) / 672
              )
          , 55px), 76px);
}
@media only screen and (max-width: 350px) {
  .tab__swiper .btn-block .btn:not(:last-child) {
    margin-right: 10px;
  }
}
.tab__item {
  width: 100%;
  padding-top: 10px;
  color: var(--color-white);
}
.tab__item h2,
.tab__item .tab__head {
  display: none;
  font-weight: 500;
  margin-bottom: 10px;
}
.tab__item h2.yellow,
.tab__item .tab__head.yellow {
  color: var(--color-yellow);
}
.tab__item h2.blue,
.tab__item .tab__head.blue {
  color: var(--color-blue);
}
.tab__item h2.green,
.tab__item .tab__head.green {
  color: var(--color-green);
}
.tab__item h2.red,
.tab__item .tab__head.red {
  color: var(--color-red);
}
.tab__item .tab__head img {
  width: 100%;
  height: 62px;
  object-fit: contain;
}
.tab__item .tab__item-text {
  overflow: auto;
  max-height: 400px;
  margin-bottom: min(max(
            20px + 14 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 34px);
  padding-right: 7px;
  color: var(--color-blue-dark);
  line-height: 1.7777777778em;
  font-family: var(--font-main);
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
}
.tab__item .tab__item-text p {
  color: var(--color-blue-dark);
  line-height: 1.7777777778em;
  font-family: var(--font-main);
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
}
.tab__item .tab__item-text p:not(:last-child) {
  margin-bottom: min(max(
            20px + 14 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 34px);
}
.tab__item .partnerships-card {
  font-family: var(--font-main);
}
.tab__item .partnerships-card__text {
  overflow: auto;
  height: 224px;
  margin-bottom: 41px;
  padding-right: 7px;
  font-size: 18px;
  line-height: 1.78;
  color: var(--color-white);
}
@media only screen and (max-width: 601px) {
  .tab__item .partnerships-card__text {
    margin-bottom: 20px;
    height: 235px;
  }
}
.tab__item .partnerships-card__text *,
.tab__item .partnerships-card__text p {
  color: var(--color-white);
}
.tab__item .partnerships-card__text::before {
  content: "“";
  position: absolute;
  right: calc(100% + 20px);
  font-size: min(max(
            75px + 58 *
              (
                (100vw - 768px) / 672
              )
          , 75px), 133px);
  line-height: 0.8;
  color: var(--color-yellow);
}
@media only screen and (max-width: 601px) {
  .tab__item .partnerships-card__text::before {
    right: calc(100% + 10px);
  }
}
.tab__item .partnerships-card__text .simplebar-offset {
  padding-right: 15px;
}
.tab__item .partnerships-card__text .simplebar-scrollbar::before {
  background-color: var(--color-yellow);
}
.tab__item .partnerships-card__text .simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}
.tab__item .partnerships-card__info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 601px) {
  .tab__item .partnerships-card__info-wrap {
    flex-direction: column;
    align-items: flex-end;
  }
}
.tab__item .partnerships-card__partner-info {
  color: var(--color-green-2);
  font-size: min(max(
            15px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 18px);
}
.tab__item .partnerships-card__raised-wrap {
  display: flex;
  align-items: center;
  line-height: 1;
}
.tab__item .partnerships-card__raised-num {
  margin-right: min(max(
            15px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 23px);
  font-size: min(max(
            25px + 15 *
              (
                (100vw - 768px) / 672
              )
          , 25px), 40px);
  font-weight: 500;
}
.tab__item .partnerships-card__raised-text {
  font-size: min(max(
            20px + 15 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 35px);
  font-weight: 300;
}
.tab__item .btn-block {
  padding-left: 8px;
}

.cards {
  position: relative;
  width: 100%;
  padding: min(max(
            32px + 100 *
              (
                (100vw - 768px) / 672
              )
          , 32px), 132px) 0 min(max(
            76px + 88 *
              (
                (100vw - 768px) / 672
              )
          , 76px), 164px) 0;
}
.cards .decor {
  pointer-events: none;
}
.cards .decor img {
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
}
.cards .cont {
  max-width: 1160px;
}
.cards__swiper {
  width: 100%;
  overflow: hidden;
}
.cards__swiper .swiper-slide {
  height: auto;
}
.cards__swiper .swiper-pagination {
  bottom: 40px;
}
.cards__item {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: min(max(
            20px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 24px) min(max(
            16px + 2 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 18px) min(max(
            24px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 24px), 34px) min(max(
            20px + -2 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 20px);
  background-color: var(--color-blue-dark);
  clip-path: polygon(0 0, 85% 0, 100% 22%, 100% 100%, 15% 100%, 0 78%);
}
.cards__item::before {
  position: absolute;
  content: "";
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: var(--color-white);
  clip-path: polygon(0 0, 85% 0, 100% 22%, 100% 100%, 15% 100%, 0 78%);
  opacity: 0;
  visibility: hidden;
}
.cards__item:hover h3,
.cards__item:hover p,
.cards__item:hover .btn {
  color: var(--color-blue-dark);
}
.cards__item:hover .btn span.icon-dark::before {
  border-left-color: var(--color-white);
}
.cards__item:hover::before {
  opacity: 1;
  visibility: visible;
}
.cards__item div {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.cards__item div object {
  margin-top: auto;
}
.cards__item h3,
.cards__item p,
.cards__item .btn {
  color: var(--color-white);
}
.cards__item h3 {
  font-weight: 500;
  line-height: 1.6em;
  margin-bottom: min(max(
            7px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 7px), 17px);
}
.cards__item p {
  line-height: 1.3333333333em;
  margin-bottom: min(max(
            18px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 26px);
}
.cards__item .btn {
  padding-right: min(max(
            2px + 16 *
              (
                (100vw - 768px) / 672
              )
          , 2px), 18px);
  margin-top: auto;
  margin-left: auto;
}
.say {
  position: relative;
  width: 100%;
  padding: min(max(
            67px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 67px), 70px) 0 min(max(
            84px + -7 *
              (
                (100vw - 768px) / 672
              )
          , 77px), 84px) 0;
  background-color: var(--color-blue-dark);
}
.say .decor {
  pointer-events: none;
}
.say .decor img:first-child {
  top: -1px;
  left: -1px;
  transform-origin: top left;
}
.say .decor img:last-child {
  bottom: -1px;
  right: -1px;
  transform-origin: bottom right;
}
.say__block {
  width: 100%;
  max-width: 964px;
  text-align: center;
  margin: 0 auto;
}
.say__title {
  font-weight: 400;
  letter-spacing: -0.2px;
  color: var(--color-white);
  margin-bottom: min(max(
            34px + 40 *
              (
                (100vw - 768px) / 672
              )
          , 34px), 74px);
}
.say__swiper {
  width: 100%;
  max-width: 768px;
  overflow: hidden;
  margin: 0 auto min(max(
            90px + -10 *
              (
                (100vw - 768px) / 672
              )
          , 80px), 90px) auto;
}
.say__swiper .swiper-pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: min(max(
            30px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 33px);
}
.say__swiper .swiper-pagination {
  position: relative;
  bottom: auto;
  left: unset !important;
  transform: unset !important;
}
.say__swiper .say__swiper-btn {
  color: var(--color-white);
}
.say__item {
  width: 100%;
  text-align: center;
}
.say__item p {
  position: relative;
  color: var(--color-white);
  margin-bottom: min(max(
            32px + 31 *
              (
                (100vw - 768px) / 672
              )
          , 32px), 63px);
}
.say__item p span {
  color: var(--color-blue-dark);
  background-color: var(--color-green-2);
  padding: 3px;
  font-weight: 600;
}
.say__item-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.say__item-img {
  text-align: right;
}
.say__item-img img {
  position: relative;
  top: min(max(
            0px + -2 *
              (
                (100vw - 768px) / 672
              )
          , -2px), 0px);
  left: min(max(
            -4px + 2 *
              (
                (100vw - 768px) / 672
              )
          , -4px), -2px);
  display: inline-block;
  width: min(max(
            94px + 47 *
              (
                (100vw - 768px) / 672
              )
          , 94px), 141px);
}
.say__item-info {
  display: inline-block;
  text-align: left;
  padding-left: min(max(
            17px + 24 *
              (
                (100vw - 768px) / 672
              )
          , 17px), 41px);
}
.say__item-info:first-child {
  text-align: center;
  padding-left: 0;
}
.say__item-info div {
  font-size: min(max(
            14px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 17px);
  color: var(--color-white);
  margin-bottom: min(max(
            6px + -4 *
              (
                (100vw - 768px) / 672
              )
          , 2px), 6px);
}
.say__item-info div:first-child {
  font-weight: 700;
  color: var(--color-yellow);
}
.say__item-info div:last-child {
  margin-bottom: 0;
}
.say__total-swiper {
  width: 100%;
}
.say__total-swiper .swiper-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: min(max(
            40px + 25 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 65px);
  grid-row-gap: min(max(
            20px + 15 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 35px);
}
.say__total-swiper.invert .say__total-item p {
  color: var(--color-blue-dark);
}
.say__total-item {
  width: 100%;
  text-align: center;
}
.say__total-item div {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.0833333333em;
  color: var(--color-white);
}
.say__total-item div.red {
  color: var(--color-red);
}
.say__total-item div.green {
  color: var(--color-green-2);
}
.say__total-item div.blue {
  color: var(--color-blue);
}
.say__total-item p {
  font-size: 30px;
  line-height: 1.4444444444em;
  font-weight: 300;
  color: var(--color-white);
}

.jobs {
  position: relative;
  width: 100%;
  padding: min(max(
            72px + 52 *
              (
                (100vw - 768px) / 672
              )
          , 72px), 124px) 0 min(max(
            116px + 18 *
              (
                (100vw - 768px) / 672
              )
          , 116px), 134px) 0;
}
.jobs__head {
  margin-bottom: min(max(
            58px + 42 *
              (
                (100vw - 768px) / 672
              )
          , 58px), 100px);
}
.jobs__control {
  margin-top: min(max(
            40px + 25 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 65px) !important;
}
.jobs__swiper .swiper-slide {
  height: auto;
}
.jobs__swiper .swiper-pagination-bullet {
  border-color: var(--color-blue-dark);
}
.jobs__swiper .swiper-pagination-bullet:hover,
.jobs__swiper .swiper-pagination-bullet-active {
  background-color: var(--color-blue-dark);
}
.jobs__card {
  position: relative;
  width: 100%;
  min-height: 380px;
  height: 100%;
  background: linear-gradient(var(--color-blue-dark) 0%, var(--color-blue-dark) 99%, transparent);
  padding: min(max(
            39px + -6 *
              (
                (100vw - 768px) / 672
              )
          , 33px), 39px) min(max(
            23px + -5 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 23px) min(max(
            76px + 26 *
              (
                (100vw - 768px) / 672
              )
          , 76px), 102px) min(max(
            23px + -5 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 23px);
  clip-path: polygon(-1px 0px, 84% 0, 100% 13%, 100% 100%, 0 101%, -1px 100%);
}
.jobs__card::before {
  position: absolute;
  content: "";
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: var(--color-white);
  clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 83%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.jobs__card:hover .jobs__card-cont {
  transition: all 0.3s ease-in-out;
}
.jobs__card:hover .jobs__card-cont h4,
.jobs__card:hover .jobs__card-cont a,
.jobs__card:hover .jobs__card-cont h5,
.jobs__card:hover .jobs__card-cont p {
  color: var(--color-blue-dark);
  transition: all 0.3s ease-in-out;
}
.jobs__card:hover .btn {
  color: var(--color-blue-dark);
  transition: all 0.3s ease-in-out;
}
.jobs__card:hover .btn span.icon-dark::before {
  border-left-color: var(--color-white);
  transition: all 0.3s ease-in-out;
}
.jobs__card:hover::before {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.jobs__card img {
  position: absolute;
  bottom: -1px;
  left: -1px;
  display: inline-block;
  width: calc(100% + 2px);
  max-width: unset;
  height: auto;
}
.jobs__card .btn-block {
  justify-content: flex-start;
}
.jobs__card .btn {
  color: var(--color-white);
  /*margin-right: adaptive-calc(26px, 22px);*/
  margin-right: 10px;
}
.jobs__card .btn:last-child {
  margin-right: 0;
}
.jobs__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
}
.jobs__card-cont {
  width: 100%;
  margin-bottom: min(max(
            29px + -3 *
              (
                (100vw - 768px) / 672
              )
          , 26px), 29px);
  flex-grow: 1;
  /*.jobs__del-city.jobs__card-city {
    display: none;
  }*/
}
.jobs__card-cont h4,
.jobs__card-cont h5,
.jobs__card-cont div,
.jobs__card-cont a {
  color: var(--color-white);
  transition: all 0.3s ease-in-out;
}
.jobs__card-cont h4,
.jobs__card-cont a {
  font-weight: 500;
  margin-bottom: min(max(
            22px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 26px);
  font-size: min(max(
            18px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 25px);
  font-weight: 700;
  line-height: 1.8em;
}
.jobs__card-cont h5 {
  font-weight: 400;
  margin-bottom: min(max(
            14px + -2 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 14px);
}
.jobs__card-cont p {
  font-size: min(max(
            14px + 1 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 15px);
  line-height: 1.6em;
  font-weight: 300;
  color: var(--color-white);
  margin-bottom: min(max(
            6px + -2 *
              (
                (100vw - 768px) / 672
              )
          , 4px), 6px);
}
.jobs__card-cont p:last-child {
  margin-bottom: 0;
}

.jobs__del-city.jobs__card-cont {
  margin-bottom: min(max(
            29px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 29px), 35px);
}
.jobs__del-city.jobs__card-cont h5 {
  letter-spacing: -1px;
  font-weight: 400;
  font-size: 23px;
  margin-bottom: 21px;
  line-height: 1.5;
}
.jobs__del-city.jobs__card-cont a,
.jobs__del-city.jobs__card-cont h4 {
  font-size: min(max(
            18px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 25px);
  font-weight: 500;
  margin-bottom: 21px;
  color: var(--color-white);
  line-height: 1.5;
}
.jobs__del-city.jobs__card-cont p {
  font-size: min(max(
            14px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 17px);
  line-height: 1.6em;
  font-weight: 300;
  color: var(--color-white);
  margin-bottom: 13px;
  /*    &:last-child {
    margin-bottom: 0;
  }*/
}

.video {
  position: relative;
  width: 100%;
  padding: min(max(
            40px + 112 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 152px) 0 min(max(
            129px + -19 *
              (
                (100vw - 768px) / 672
              )
          , 110px), 129px) 0;
}
.video .decor {
  pointer-events: none;
}
.video .decor img:first-child {
  top: 0;
  left: -1px;
  transform-origin: top left;
  width: 230px;
}
.video .decor img:last-child {
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
  width: 150px;
}
.video__head {
  position: relative;
  width: 100%;
  max-width: 768px;
  margin-left: auto;
  margin-bottom: min(max(
            50px + 32 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 82px);
}
.video__head h2 {
  font-weight: 600;
  margin-bottom: min(max(
            9px + 1 *
              (
                (100vw - 768px) / 672
              )
          , 9px), 10px);
}
.video__head p {
  width: 94%;
  margin-bottom: 28px;
  font-family: var(--font-main);
  font-size: 18px;
}
.video__swiper {
  width: 100%;
  overflow: hidden;
}
.video__swiper .swiper-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}
.video__swiper .swiper-pagination {
  display: none;
}
.video__swiper .swiper-pagination-bullet {
  border-color: var(--color-blue-dark);
}
.video__swiper .swiper-pagination-bullet:hover,
.video__swiper .swiper-pagination-bullet-active {
  background-color: var(--color-blue-dark);
}
.video__card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.video__card:hover .video__img::after {
  opacity: 0;
}
.video__card:hover img {
  transform: scale(1.05);
}
.video__card:hover .btn.yellow {
  color: var(--color-yellow-2);
}
.video__card:hover .btn.red {
  color: var(--color-red);
}
.video__card:hover .btn.blue {
  color: var(--color-blue);
}
.video__img {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 46%;
  overflow: hidden;
  margin-bottom: min(max(
            10px + 2 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 12px);
}
.video__img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 400ms;
}
.video__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  will-change: transform;
  transition: all 0.7s;
}
.video__cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.video__cont div {
  width: 100%;
  font-size: min(max(
            16px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 20px);
  line-height: 1.2em;
  color: var(--color-blue-dark);
  margin-bottom: 6px;
}
.video__cont div.yellow {
  color: var(--color-yellow-2);
}
.video__cont div.red {
  color: var(--color-red);
}
.video__cont div.blue {
  color: var(--color-blue);
}
.video__cont h4 {
  margin-bottom: min(max(
            14px + -2 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 14px);
}
.video__cont .btn.size-2 {
  margin-top: auto;
}
.video__cont .btn.size-2 span {
  margin-left: min(max(
            11px + -4 *
              (
                (100vw - 768px) / 672
              )
          , 7px), 11px);
}

.head-team .select2-container--default .select2-selection--single {
  color: var(--color-white);
}
.head-team__cont h1 {
  padding-top: min(max(
            170px + 80 *
              (
                (100vw - 768px) / 672
              )
          , 170px), 250px) !important;
}
.head-team__form {
  display: flex;
  align-items: flex-end;
  max-width: 686px;
  margin-top: 27px;
}
.head-team__form .select2-container--default .select2-selection--single {
  border-bottom: none;
}
.head-team__form input:-webkit-autofill, .head-team__form input:-webkit-autofill:hover, .head-team__form input:-webkit-autofill:focus, .head-team__form input:-webkit-autofill:active,
.head-team__form textarea:-webkit-autofill,
.head-team__form textarea:-webkit-autofill:hover,
.head-team__form textarea:-webkit-autofill:focus,
.head-team__form textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
.head-team__form input {
  max-width: 278px;
  width: 100%;
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white);
  margin-right: 16px;
  padding: 9px 2px 10px;
}
.head-team__form input::placeholder {
  color: var(--color-white);
}
.head-team__form input:focus {
  border-color: var(--color-yellow-2);
}
.head-team__form input:focus::placeholder {
  color: var(--color-yellow-2);
}
.head-team__form button,
.head-team__form a {
  color: var(--color-white);
  cursor: pointer;
  margin-left: 16px;
}
.head-team__form .select2-container {
  border-bottom: 1px solid var(--color-white);
}
.head-team__form .select2-container--default .select2-selection--single {
  padding: 11px 0 15px 0px;
}
.head-team__form .select2-container--default .select2-selection--single .select2-selection__rendered {
  max-width: 280px;
  width: 100%;
}
.head-team__form .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 0px;
}
.head-team__form .select2-container--default .select2-selection--single .select2-selection__arrow::before {
  display: inline-block;
  transition: transform 250ms ease;
}
.head-team__form .select2-container--open .select2-selection__arrow::before {
  transform: rotate(90deg);
}

.team-grid {
  margin-top: min(max(
            50px + 16 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 66px);
  margin-bottom: min(max(
            50px + 109 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 159px);
}
.team-grid__inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-flow: dense;
  grid-column-gap: 16px;
  grid-row-gap: 63px;
  justify-items: center;
}
.team-grid__card {
  max-width: 278px;
  width: 100%;
}
.team-grid__card a.team-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.team-grid__card a.team-card .team-card__links {
  flex: 1;
  align-items: flex-end;
}
.team-grid__card:hover .team-card__img {
  transform: scale(1.05);
}

.no-results {
  display: block;
  width: 100%;
}

.show-team {
  padding: 0px 0 min(max(
            70px + 46 *
              (
                (100vw - 768px) / 672
              )
          , 70px), 116px);
  position: relative;
}
.show-team .show__title {
  max-width: 200px;
}
.show-team .decor {
  pointer-events: none;
}
.show-team .decor img {
  width: min(max(
            100px + 95.5 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 195.5px);
  height: auto;
  bottom: 0;
  left: 0px;
  transform-origin: bottom left;
}
.show-team__text p {
  margin-bottom: 41px;
}
.show-team__video {
  margin-top: min(max(
            30px + 40 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 70px) !important;
}

.cards-hiring {
  position: relative;
  padding: min(max(
            30px + 68 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 98px) 0px min(max(
            50px + 114 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 164px);
}
.cards-hiring .decor {
  pointer-events: none;
}
.cards-hiring .decor img {
  top: -43px;
  right: -7px;
  transform-origin: bottom right;
  width: 198.72px;
  height: auto;
}
.cards-hiring .swiper-pagination {
  bottom: 10px;
}
.cards-hiring__swiper .swiper-pagination-bullet {
  border-color: var(--color-blue-dark);
}
.cards-hiring__swiper .swiper-pagination-bullet:hover,
.cards-hiring__swiper .swiper-pagination-bullet-active {
  background-color: var(--color-blue-dark);
}

.say-hiring {
  padding: min(max(
            67px + 45 *
              (
                (100vw - 768px) / 672
              )
          , 67px), 112px) 0 min(max(
            84px + -54 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 84px) 0;
}
.say-hiring__swiper {
  margin: 0 auto min(max(
            20px + 57 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 77px) auto;
}

.clients-hiring {
  margin: min(max(
            50px + 74 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 124px) 0px min(max(
            50px + 42 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 92px);
}

.video-hiring {
  padding: min(max(
            20px + 115 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 135px) 0 min(max(
            138px + 82 *
              (
                (100vw - 768px) / 672
              )
          , 138px), 220px) !important;
}

.head-member {
  clip-path: polygon(0 0, 100% 0, 100% 0, 100% 70%, 90% 100%, 0 100%, 0 100%, 0 0);
}
.head-member__cont p {
  margin-top: 15px;
  color: var(--color-green-2);
  font-size: min(max(
            22px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 30px);
  line-height: 1;
}

.member {
  margin-top: 42px;
  margin-bottom: min(max(
            50px + 65 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 115px);
}
.member__inner {
  display: flex;
  justify-content: space-between;
}
.member__text-block {
  max-width: 572px;
}
.member__text-block a {
  margin-top: min(max(
            20px + 30 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 50px);
}
.member__text p {
  margin-bottom: min(max(
            15px + 15 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 30px);
}
.member__text p:last-child {
  margin-bottom: 0;
}
.member__info {
  position: relative;
  max-width: min(max(
            50px + 327 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 377px);
  width: 100%;
  right: min(max(
            0px + 94 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 94px);
  top: min(max(
            -100px + -205 *
              (
                (100vw - 768px) / 672
              )
          , -305px), -100px);
}
.member__photo {
  max-width: 377px;
  width: 100%;
}
.member__photo img {
  width: 100%;
  height: auto;
  clip-path: polygon(16% 0, 100% 0, 100% 0, 100% 83%, 84% 100%, 0 100%, 0 100%, 0 16%);
}
.member__contact {
  margin-top: 27px;
}
.member__contact.team-card__contact {
  margin-top: 10px;
}
.member__contact.team-card__contact a {
  margin-bottom: 10px;
}
.member__contact.team-card__contact a:last-child {
  margin-bottom: 0;
}
.member__contact a {
  color: var(--color-red);
  font-size: min(max(
            18px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 21px);
  line-height: min(max(
            18px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 24px);
  font-weight: 400;
  transition: all 0.3s;
  margin-bottom: min(max(
            10px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 17px);
}
.member__contact a:last-child {
  margin-bottom: 0;
}
.member__contact a:hover {
  color: var(--color-yellow-2);
}
.member__soc {
  display: flex;
  align-items: baseline;
  margin-top: min(max(
            10px + 15 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 25px);
}
.member__soc a {
  margin-right: 14px;
}
.member__soc a .icon-email {
  font-size: 22px;
}
.member__soc a i {
  font-size: 20px;
  transition: all 0.3s;
}
.member__soc a i:before {
  color: var(--color-yellow-2);
}
.member__soc a:hover i::before {
  color: var(--color-red);
}
.member__soc a:last-child {
  margin-right: 0;
}
.member__btns {
  display: flex;
  flex-wrap: wrap;
  margin-top: min(max(
            5px + 9 *
              (
                (100vw - 768px) / 672
              )
          , 5px), 14px);
}
.member__btns a,
.member__btns button {
  margin-top: 10px;
}
.member__btns .btn {
  letter-spacing: -1px;
}
.member__sectors {
  margin-top: min(max(
            15px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 25px);
}
.member__sectors p {
  font-size: min(max(
            14px + 2 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 16px);
}

.jobs-member {
  margin: min(max(
            50px + 65 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 115px) 0px min(max(
            50px + 95 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 145px);
}
.jobs-member__head {
  align-items: flex-end;
  margin-bottom: 34px;
}
.jobs-member__head h2 {
  letter-spacing: -1px;
}
.jobs-member__cont .btn-block {
  justify-content: flex-end;
  margin-bottom: 10px;
}
.jobs-member .swiper-slide {
  opacity: 0.5;
}
.jobs-member .swiper-slide-active {
  opacity: 1;
}
.jobs-member .swiper-slide-next,
.jobs-member .swiper-slide-prev {
  opacity: 1;
}
.jobs-member__control {
  margin-top: min(max(
            40px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 60px) !important;
}

.resent {
  margin-bottom: min(max(
            40px + 129 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 169px);
}
.resent__slider.swiper {
  overflow: visible;
}
.resent__slider {
  max-width: 376px;
  margin-top: min(max(
            0px + -4 *
              (
                (100vw - 768px) / 672
              )
          , -4px), 0px);
}

.show-work {
  margin: min(max(
            50px + 34 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 84px) 0px min(max(
            50px + 44 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 94px);
}
.show-work .show__text-block {
  flex-direction: column;
}
.show-work .show-work-dec {
  pointer-events: none;
}
.show-work .show-work-dec img {
  position: absolute;
  top: 34px;
  left: 0;
  max-width: min(max(
            88px + 130 *
              (
                (100vw - 768px) / 672
              )
          , 88px), 218px);
  height: auto;
}
.show-work__title {
  max-width: 100% !important;
  margin-bottom: 15px;
  text-align: center;
}
.show-work__text {
  max-width: 100% !important;
  margin-top: 0 !important;
}
.show-work__text a {
  display: inline-block;
}
.show-work__text a:hover {
  text-decoration: underline;
}
.show-work__text strong {
  position: relative;
  padding: 2px;
  background: var(--color-yellow);
}
.show-work__slider {
  max-width: 769px;
  margin-top: min(max(
            30px + 36 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 66px);
}

.resources-slider__nav {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.resources-slider__nav .swiper-pagination-bullet {
  border-color: var(--color-blue-dark);
}
.resources-slider__nav .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-blue-dark);
}

.team-s-work {
  padding-bottom: min(max(
            20px + 137 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 157px) !important;
}
.team-s-work__box {
  margin-left: min(max(
            0px + 98 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 98px);
  margin-bottom: 64px;
}
.team-s-work__box h2 {
  max-width: 280px;
}
.team-s-work__box h2 span {
  background-color: var(--color-green);
  padding: 1px 2px;
}
@media only screen and (max-width: 900px) {
  .team-s-work__box h2 {
    max-width: 100%;
  }
  .team-s-work__box h2 span {
    background-color: var(--color-green);
    padding: 1px 2px;
  }
}
.team-s-work__cont {
  width: 61% !important;
  padding-left: 75px !important;
}
.team-s-work__cont p {
  margin-bottom: min(max(
            17px + 23 *
              (
                (100vw - 768px) / 672
              )
          , 17px), 40px) !important;
}
@media only screen and (max-width: 900px) {
  .team-s-work__cont {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .team-s-work__cont h2 {
    max-width: 100%;
  }
  .team-s-work__cont h2 span {
    background-color: var(--color-green);
    padding: 1px 2px;
  }
}
.team-s-work__control {
  margin-top: min(max(
            35px + 25 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 60px) !important;
}

.jobs-work {
  margin-bottom: min(max(
            40px + 86 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 126px);
}
.jobs-work .title-box {
  margin-bottom: 76px;
}
.jobs-work .swiper-slide {
  opacity: 0.5;
  transition: opacity 300ms ease;
}
.jobs-work .swiper-slide-active {
  opacity: 1;
}
.jobs-work .swiper-slide-next,
.jobs-work .swiper-slide-prev {
  opacity: 1;
}
.jobs-work .slider-control {
  margin-top: min(max(
            35px + 9 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 44px);
}

.career-work {
  margin-top: min(max(
            50px + 115 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 165px);
}

.say-work {
  margin: min(max(
            50px + 101 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 151px) 0 min(max(
            30px + 23 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 53px);
  position: relative;
}
.say-work .decor-2 {
  pointer-events: none;
}
.say-work .decor-2 img {
  position: absolute;
}
.say-work .decor-2 img:first-child {
  width: min(max(
            35px + 105 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 140px);
  height: auto;
  bottom: -1px;
  left: -1px;
  transform-origin: top left;
}
.say-work .decor-2 img:last-child {
  width: min(max(
            35px + 105 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 140px);
  height: auto;
  top: -1px;
  right: -5px;
  transform-origin: bottom right;
}

.video-sec-work {
  padding: min(max(
            50px + 85 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 135px) 0 min(max(
            100px + 100 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 200px) !important;
}

.show-solution {
  padding: min(max(
            50px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 58px) 0px min(max(
            30px + 17 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 47px);
}

.sol-career-revers {
  margin-top: min(max(
            50px + 50 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 100px);
  margin-bottom: min(max(
            20px + 30 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 50px);
}
.sol-career-revers .career__inner {
  flex-direction: row-reverse;
  margin-left: 0px;
}
.sol-career-revers .career__text {
  margin-right: 0px;
  margin-left: 40px;
  margin-top: 50px;
}
.sol-career-revers .career__text h2,
.sol-career-revers .career__text h3,
.sol-career-revers .career__text h4 {
  margin-bottom: min(max(
            9px + 27 *
              (
                (100vw - 768px) / 672
              )
          , 9px), 36px);
}

.sol-career {
  margin-top: min(max(
            30px + 80 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 110px);
  padding-bottom: min(max(
            110px + -10 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 110px);
  position: relative;
}
.sol-career .decor {
  pointer-events: none;
}
.sol-career .decor img {
  width: min(max(
            100px + 95.5 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 195.5px);
  height: auto;
  bottom: 0;
  left: 0px;
  transform-origin: bottom left;
}

.modal__backdrop {
  /*position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 13;

   display: flex;
   align-items: center;
   justify-content: center;

   padding: 40px;

   background-color: rgba(1, 1, 61, 0.5);

   opacity: 0;
   visibility: hidden;

   transition: opacity ease 250ms, visibility ease 250ms;

   @include media(501) {
      padding: 20px;
   }

   &.is-open {
      opacity: 1;
      visibility: visible;
   }*/
}

.modal {
  position: relative;
  overflow: hidden;
  width: 90vw !important;
  max-width: 1200px;
  height: auto !important;
  max-height: 100%;
  -webkit-clip-path: polygon(0 0, calc(100% - 140px) 0, 100% 140px, 100% 100%, 140px 100%, 0 calc(100% - 140px));
  clip-path: polygon(0 0, calc(100% - 140px) 0, 100% 140px, 100% 100%, 140px 100%, 0 calc(100% - 140px));
  padding: min(max(
            55px + 19 *
              (
                (100vw - 768px) / 672
              )
          , 55px), 74px) min(max(
            75px + 121 *
              (
                (100vw - 768px) / 672
              )
          , 75px), 196px) 55px !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  background-color: var(--color-white);
  font-family: var(--font-main);
}
@media only screen and (max-width: 1440px) {
  .modal {
    -webkit-clip-path: polygon(0 0, calc(100% - 80px) 0, 100% 80px, 100% 100%, 80px 100%, 0 calc(100% - 80px));
    clip-path: polygon(0 0, calc(100% - 80px) 0, 100% 80px, 100% 100%, 80px 100%, 0 calc(100% - 80px));
  }
}
@media only screen and (max-width: 769px) {
  .modal {
    padding: 55px 30px 20px !important;
    clip-path: polygon(0 0, 94% 0, 100% 6%, 100% 100%, 6% 100%, 0 94%);
  }
}
@media only screen and (max-width: 550px) {
  .modal {
    max-width: 89%;
  }
}
.modal--applied {
  height: 80vh;
}
.modal .apply-form p {
  font-size: min(max(
            16px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 20px);
}
.modal .apply-form__input, .modal .apply-form__textarea {
  color: var(--color-blue-dark);
}
.modal .apply-form__input::placeholder, .modal .apply-form__textarea::placeholder {
  color: var(--color-blue-dark);
}
.modal .apply-form__input:focus, .modal .apply-form__textarea:focus {
  border-color: var(--color-blue-dark);
}
.modal .apply-form__textarea[data-autosize] {
  transition: none;
  height: 36px;
  overflow: hidden;
}
@media only screen and (max-width: 551px) {
  .modal .apply-form__textarea[data-autosize] {
    height: 64px;
  }
}
.modal .apply-form__checkboxes-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
@media only screen and (max-width: 551px) {
  .modal .apply-form__checkboxes-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 415px) {
  .modal .apply-form__checkboxes-container p {
    font-size: 14px;
  }
}
.modal .checkBox {
  grid-column-start: 1;
  grid-column-end: -1;
}
.modal .checkBox .check-title {
  color: var(--color-blue-dark);
  font-family: var(--font-main);
  font-size: 18px;
  padding-left: 25px;
}
.modal .checkBox .check-title:before {
  border: 1px solid var(--color-blue-dark);
  width: 18px;
  height: 18px;
}
.modal .checkBox .check-title a {
  display: inline-block;
  position: relative;
  transition: all 0.3s ease;
  color: var(--color-blue);
}
.modal .checkBox .check-title a:before {
  content: "";
  position: absolute;
  right: 0;
  left: auto;
  bottom: 2px;
  height: 1px;
  width: 0;
  background: var(--color-blue);
  transition: all 0.3s ease;
}
.modal .checkBox .check-title a:hover {
  color: var(--color-blue-dark);
  transition: all 0.3s ease;
}
.modal .checkBox .check-title a:hover:before {
  background: var(--color-blue-dark);
  left: 0;
  right: auto;
  width: 100%;
}
.modal .checkBox input:checked + .check-title::before {
  background: var(--color-red);
}

.modal__close {
  position: absolute;
  top: 74px;
  right: 98px;
  display: flex;
  align-items: center;
  transition: color ease 250ms;
}
.modal__close:hover {
  color: var(--color-yellow);
}
@media only screen and (max-width: 1281px) {
  .modal__close {
    top: 30px;
  }
}
@media only screen and (max-width: 769px) {
  .modal__close {
    right: 50px;
  }
}
@media only screen and (max-width: 651px) {
  .modal__close {
    top: 20px;
  }
}

.modal__close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  margin-left: 20px;
  font-size: 11px;
  color: var(--color-white);
  background-color: var(--color-yellow);
}

.modal__title {
  margin-bottom: 15px;
  font-size: min(max(
            22px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 32px);
  font-weight: 600;
  line-height: 1.2;
}
@media only screen and (max-width: 415px) {
  .modal__title {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.modal__text {
  padding-bottom: min(max(
            10px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 20px);
}
.modal__text p {
  font-size: 14px;
}

.modal__descr {
  color: var(--color-blue);
  font-size: 20px;
}

.modal__btn-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 55px;
}

.apply-form__alert {
  margin-bottom: 15px;
  color: var(--color-blue-2);
  font-size: 18px;
  font-weight: 400;
}

.apply-form__fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 33px;
}
@media only screen and (max-width: 551px) {
  .apply-form__fields {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }
  .apply-form__fields--cv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
  }
  .apply-form__fields--cv > * {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 551px) and (max-width: 415px) {
  .apply-form__fields--cv > * {
    margin-bottom: 10px;
  }
}

.buttons-upload-container {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  width: 100%;
}
@media only screen and (max-width: 415px) {
  .buttons-upload-container {
    gap: 10px;
  }
}
.buttons-upload-container .btn {
  margin: 0 !important;
  padding-bottom: 0;
  border: none;
  flex-wrap: nowrap;
  cursor: pointer;
  justify-content: center !important;
}
.buttons-upload-container .btn > * {
  text-decoration: none;
  margin-right: 0;
}
.buttons-upload-container .btn .apply-form__file-title {
  display: none;
}
.buttons-upload-container .btn .apply-form__file-name {
  display: none;
}
.buttons-upload-container .btn input {
  display: none;
}

.apply-form__input,
.apply-form__textarea {
  padding: 0 0 8px;
  border-bottom: 1px solid var(--color-blue-dark);
}

.apply-form__file,
.apply-form__textarea {
  grid-column-start: 1;
  grid-column-end: -1;
}

.apply-form__file {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-blue-dark);
  font-size: 18px;
  cursor: pointer;
}
.apply-form__file:hover .apply-form__file-choose {
  color: var(--color-blue);
}
.apply-form__file input {
  display: none;
}
@media only screen and (max-width: 551px) {
  .apply-form__file {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
  }
}

.apply-form__file-title {
  margin-right: min(max(
            15px + 21 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 36px);
  white-space: nowrap;
}

.apply-form__file-choose {
  margin-right: min(max(
            15px + 25 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 40px);
  white-space: nowrap;
  text-decoration: underline;
  transition: color 300ms ease;
}

.apply-form__file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 451px) {
  .apply-form__file-name .cv_file_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.apply-form__btn {
  margin-left: auto;
}

body.safari .modal__slider .swiper-slide {
  padding-right: 0 !important;
}

.modal__slider {
  overflow: hidden;
  width: 100%;
}
.modal__slider .swiper-wrapper {
  padding-bottom: 20px;
  align-items: center;
}
@media only screen and (max-width: 415px) {
  .modal__slider .swiper-wrapper {
    padding-bottom: 10px;
  }
}
.modal__slider .swiper-slide {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.modal__content {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  max-height: 85vh;
  max-height: calc(var(--vh, 1vh) * 85);
  margin-top: 30px;
}
.modal__content .modal__slider {
  margin-top: -10px;
}
@media only screen and (max-width: 1024px) {
  .modal__content {
    margin-top: 0;
  }
  .modal__content .modal__slider {
    margin-top: 0;
  }
}
@media only screen and (max-width: 401px) {
  .modal__content {
    padding-right: 5px;
  }
}

.modal__slider-nav-wrap {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: fit-content;
  /*margin: -24px auto 0;*/
  margin: 0 auto 0;
}
@media only screen and (max-width: 769px) {
  .modal__slider-nav-wrap {
    margin: 15px auto 0;
  }
}

.modal__slider-nav-btn {
  font-size: 10.5px;
  color: var(--color-blue-dark);
  transition: opacity ease 250ms;
}
.modal__slider-nav-btn:hover {
  opacity: 0.75;
}

.modal__slider-prev-btn {
  margin-right: 14px;
}

.modal__slider-nav {
  margin-right: 14px;
}
.modal__slider-nav .swiper-pagination-fraction span {
  transition: all 0.25s ease;
  cursor: pointer;
}
.modal__slider-nav .swiper-pagination-fraction span:hover {
  opacity: 1;
}
.modal__slider-nav .swiper-pagination-current {
  cursor: pointer;
}
.modal__slider-nav .swiper-pagination-total {
  cursor: pointer;
  opacity: 0.7;
}
.modal__slider-nav .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  border: 1px solid var(--color-blue-dark);
  border-radius: 50%;
  transition: transform ease 250ms;
}
.modal__slider-nav .swiper-pagination-bullet:hover {
  transform: scale(1.1);
}
.modal__slider-nav .swiper-pagination-bullet-active {
  background-color: var(--color-blue-dark);
}

.modal__decor {
  position: absolute;
  left: 0;
  z-index: 1;
}

.page404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  font-family: var(--font-main);
}

.page404__title {
  margin-bottom: 10px;
  font-size: min(max(
            30px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 42px);
}

.page404__btn .btn {
  color: var(--color-white);
}

.page404__subtitle {
  margin-bottom: min(max(
            30px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 40px);
  width: 90%;
  max-width: 550px;
  font-size: min(max(
            20px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 25px);
  text-align: center;
}

.statistics-section {
  margin-bottom: min(max(
            30px + 90 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 120px);
}

.why-matters {
  position: relative;
  padding: min(max(
            40px + 80 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 120px) 0;
  background-color: var(--color-blue-dark);
}
.why-matters .decor {
  pointer-events: none;
}
.why-matters .decor .decor-img {
  position: absolute;
  width: min(max(
            35px + 105 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 140px);
}
.why-matters .decor .decor-img.top {
  top: -1px;
  right: -1px;
}
.why-matters .decor .decor-img.bottom {
  bottom: -1px;
  left: -1px;
}

.why-metters__title {
  margin-bottom: 45px;
  color: var(--white);
  text-align: center;
}
.why-metters__title span {
  background-color: var(--color-red);
}

.why-matters__swiper {
  position: relative;
  overflow: hidden;
  padding-bottom: 45px;
}
.why-matters__swiper .swiper-pagination {
  bottom: 0;
}

.why-matters__slide {
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  max-width: 768px;
  margin: 0 auto;
  color: var(--color-white);
  font-family: var(--font-main);
  text-align: center;
}

.five-actions {
  padding: min(max(
            80px + 40 *
              (
                (100vw - 768px) / 672
              )
          , 80px), 120px) 0 min(max(
            100px + 50 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 150px);
  font-family: var(--font-main);
  text-align: center;
}
@media only screen and (max-width: 769px) {
  .five-actions {
    padding-bottom: 55px;
  }
}

.five-actions__title {
  margin-bottom: 25px;
  font-size: min(max(
            22px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 34px);
}

.five-actions__descr {
  max-width: 572px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.78;
}

.five-actions__list {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 86%;
  margin: 0 auto;
  padding: min(max(
            50px + 39 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 89px) 0;
}
.five-actions__list::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
  display: block;
  width: 100%;
  height: 4px;
  background: var(--color-blue-dark);
}
@media only screen and (max-width: 769px) {
  .five-actions__list {
    flex-direction: column;
    align-items: center;
  }
  .five-actions__list::before {
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: calc(100% - 125px);
  }
}

.five-actions__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 60px;
  cursor: pointer;
  transition: transform ease 300ms;
}
.five-actions__item.green {
  background-color: var(--color-green);
}
.five-actions__item.red {
  background-color: var(--color-red);
}
.five-actions__item.yellow {
  background-color: var(--color-yellow);
}
.five-actions__item.dark-blue {
  background-color: var(--color-blue-dark);
}
.five-actions__item.blue {
  background-color: var(--color-blue);
}

.five-actions__item-wrap {
  position: relative;
  cursor: pointer;
}
@media only screen and (max-width: 1281px) {
  .five-actions__item-wrap:last-child .five-actions__item-text {
    transform: translateX(-65%);
  }
  .five-actions__item-wrap:first-child .five-actions__item-text {
    transform: translateX(-35%);
  }
}
@media only screen and (max-width: 769px) {
  .five-actions__item-wrap {
    margin-bottom: 25px;
  }
  .five-actions__item-wrap:last-child .five-actions__item-text, .five-actions__item-wrap:first-child .five-actions__item-text {
    transform: translate(-50%, -50%);
  }
}
.five-actions__item-wrap:hover .five-actions__item-text {
  opacity: 1;
  visibility: visible;
}

.five-actions__item-text {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 335px;
  padding: 25px 10px 5px;
  background: var(--color-white);
  opacity: 0;
  visibility: hidden;
  transition: all 400ms ease;
  font-size: min(max(
            16px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 22px);
  font-weight: 500;
}
.five-actions__item-text * {
  font-size: min(max(
            16px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 22px);
  font-weight: 500;
}
@media only screen and (max-width: 769px) {
  .five-actions__item-text {
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    min-height: 100%;
    max-height: 100%;
    padding: 10px;
  }
}

.five-actions__item-info-list {
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  width: calc(100% + 40px);
  padding: 0 20px;
  background-color: var(--color-white);
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 300ms ease;
}
.five-actions__item-info-list.active {
  opacity: 1;
  visibility: visible;
}
@media only screen and (max-width: 769px) {
  .five-actions__item-info-list {
    top: -1px;
    left: 0;
    transform: none;
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

.five-actions__item-info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: min(max(
            230px + 48 *
              (
                (100vw - 768px) / 672
              )
          , 230px), 278px);
  background-color: var(--color-white);
  padding: 0 min(max(
            0px + 100 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 100px);
}
@media only screen and (max-width: 769px) {
  .five-actions__item-info-item {
    flex-direction: column;
    top: 0;
    justify-content: center;
    width: auto;
    height: 100%;
    max-height: unset;
  }
}
.five-actions__item-info-item.green .five-actions__item-num {
  background-color: var(--color-green);
}
.five-actions__item-info-item.red .five-actions__item-num {
  background-color: var(--color-red);
}
.five-actions__item-info-item.yellow .five-actions__item-num {
  background-color: var(--color-yellow);
}
.five-actions__item-info-item.dark-blue .five-actions__item-num {
  background-color: var(--color-blue-dark);
}
.five-actions__item-info-item.blue .five-actions__item-num {
  background-color: var(--color-blue);
}

.five-actions__item-info-item-close {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background: var(--color-blue-dark);
  color: var(--color-white);
  font-size: 10px;
  cursor: pointer;
  transition: background ease 250ms;
}
.five-actions__item-info-item-close:hover {
  background: var(--color-red);
}
@media only screen and (max-width: 769px) {
  .five-actions__item-info-item-close {
    width: 40px;
    height: 40px;
    font-size: 15px;
  }
}

.five-actions__item-num {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  flex-shrink: 0;
  margin-right: 30px;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 111px;
}
@media only screen and (max-width: 769px) {
  .five-actions__item-num {
    width: 250px;
    height: 250px;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.five-actions__item-text-wrap {
  width: 100%;
  max-width: 768px;
  text-align: left;
}

.five-actions__item-title {
  margin-bottom: 17px;
  font-size: 26px;
}

.five-actions__item-descr {
  width: 100%;
  max-height: 132px;
  font-size: min(max(
            18px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 26px);
}
@media only screen and (max-width: 769px) {
  .five-actions__item-descr {
    max-height: 370px;
  }
}
.five-actions__item-descr .simplebar-mask {
  margin-right: 25px;
}

.five-actions__item-info-list-nav {
  position: absolute;
  bottom: min(max(
            -40px + 40 *
              (
                (100vw - 768px) / 672
              )
          , -40px), 0px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 300ms ease;
}
@media only screen and (max-width: 769px) {
  .five-actions__item-info-list-nav {
    bottom: -20px;
  }
}
.five-actions__item-info-list-nav.active {
  opacity: 1;
  visibility: visible;
}

.five-actions__nav {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
@media only screen and (max-width: 769px) {
  .five-actions__nav {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.five-actions__nav-btn {
  width: 23px;
  height: 23px;
  font-size: 9px;
  transition: transform ease 250ms;
  color: var(--color-white);
  background: var(--color-blue-dark);
}
@media only screen and (max-width: 769px) {
  .five-actions__nav-btn {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}
.five-actions__nav-btn:hover {
  transform: scale(1.1);
}
.five-actions__nav-btn.prev {
  margin-right: 10px;
}
.five-actions__nav-btn.swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.five-actions__nav-btn.swiper-button-disabled:hover {
  transform: none;
}

.campaigns-section .decor {
  pointer-events: none;
}
.campaigns-section .decor img.campaigns-section__decor-img:last-child {
  top: 0;
  right: 0;
  left: unset;
}
.campaigns-section .video-sec__cont p {
  max-width: 572px;
}

.campaigns__slider {
  max-width: 376px;
  padding-bottom: 52px;
  margin: 0 auto;
  overflow: visible;
}
.campaigns__slider .campaigns__slide {
  opacity: 0.5;
  transition: opacity ease 750ms;
}
.campaigns__slider .swiper-slide-active {
  opacity: 1;
}
.campaigns__slider .swiper-slide-next,
.campaigns__slider .swiper-slide-prev {
  opacity: 1;
}

.events-section {
  padding-bottom: min(max(
            40px + 87 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 127px);
}

.events-section__title {
  margin-bottom: 28px;
}

.events__swiper {
  overflow: hidden;
}
.events__swiper .swiper-slide {
  height: initial;
}

.events__card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.events__card-img {
  width: 100%;
  object-fit: cover;
  margin-bottom: -72px;
}

.events__card-text-wrap {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 28px 18px 20px;
  clip-path: polygon(20% -1px, 85% -1px, 100% 32%, 100% 80%, 100% 100%, 15% 100%, 0 68%, 0 -1px);
  background-color: var(--color-blue-dark);
  color: var(--color-white);
}
.events__card-text-wrap .btn {
  margin-left: auto;
  margin-top: auto;
}

.events__card-title {
  margin-bottom: 15px;
  font-size: min(max(
            22px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 32px);
  font-weight: 500;
}

.events__card-date {
  margin-bottom: 18px;
  font-size: 18px;
}

.modal-radio-button-title {
  font-size: 18px;
  font-weight: 400;
  line-height: unset;
}

.modal-dropdown-list:not(:last-child) {
  margin-bottom: 4px;
}

.modal-radio-button-list:not(:last-child) {
  margin-bottom: 20px;
}
.modal-radio-button-list .flex-box {
  display: flex;
}
.modal-radio-button-list .flex-box .modal-radio-button:not(:last-child) {
  margin-right: 20px;
}
.modal-radio-button-list .apply-form__input {
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal-radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.modal-radio-button input {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 0 !important;
}
.modal-radio-button div {
  font-size: min(max(
            16px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 20px);
}
.modal-radio-button.with-ethnicity div {
  font-size: 18px;
}

[data-prefer-input] {
  display: none;
}
[data-prefer-input].active {
  display: block;
}

.swiper-pagination-bullet-active-prev-prev,
.swiper-pagination-bullet-active-prev,
.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-next-next {
  transform: none !important;
}

.swiper-backface-hidden .swiper-slide {
  transform: unset !important;
  backface-visibility: unset !important;
}

.engaging-search-section {
  height: 1339px;
  padding: 120px 0 140px;
  color: var(--color-white);
  background: var(--color-blue-dark);
}
@media only screen and (max-width: 1025px) {
  .engaging-search-section {
    height: auto;
  }
}
@media only screen and (max-width: 769px) {
  .engaging-search-section {
    padding: 50px 0;
  }
}
.engaging-search-section .cont.engaging-search-cont {
  padding-left: min(max(
            0px + 250 *
              (
                (100vw - 1024px) / 416
              )
          , 0px), 250px);
}
@media only screen and (max-width: 1025px) {
  .engaging-search-section .cont.engaging-search-cont {
    width: 80.8%;
  }
}

.engaging-search-section__title {
  padding-left: min(max(
            0px + 150 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 150px);
  margin-bottom: min(max(
            24px + 16 *
              (
                (100vw - 768px) / 672
              )
          , 24px), 40px);
  color: var(--white);
}

.engaging-search__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 159px;
  transition: margin ease 250ms;
}
@media only screen and (max-width: 1025px) {
  .engaging-search__wrap {
    align-items: flex-start;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.engaging-search__wrap.is-exec {
  margin-top: 50px;
}
.engaging-search__wrap.is-exec .engaging-search__text.interim {
  display: none;
}
.engaging-search__wrap.is-exec .engaging-search__text.exec {
  display: block;
}
.engaging-search__wrap.is-exec .engaging-search__title-interim {
  display: none;
}
.engaging-search__wrap.is-exec .engaging-search__title-exec {
  display: block;
}
.engaging-search__wrap.is-exec .engaging-search-mob__text.interim {
  display: none;
}
.engaging-search__wrap.is-exec .engaging-search-mob__text.exec {
  display: block;
}
.engaging-search__wrap.is-exec .engaging-search__select-comment {
  opacity: 1;
  visibility: visible;
}

.engaging-search {
  position: relative;
  width: 100%;
  height: 428.7px;
  transition: transform ease 400ms;
}
@media only screen and (max-width: 1025px) {
  .engaging-search {
    display: none;
  }
}
.engaging-search.active .engaging-search__item {
  opacity: 1;
}
.engaging-search.active .engaging-search__item:nth-child(2) {
  --rotate-deg: 20deg;
  z-index: 9;
}
.engaging-search.active .engaging-search__item:nth-child(3) {
  --rotate-deg: 40deg;
  z-index: 8;
}
.engaging-search.active .engaging-search__item:nth-child(4) {
  --rotate-deg: 60deg;
  z-index: 7;
}
.engaging-search.active .engaging-search__item:nth-child(5) {
  --rotate-deg: 80deg;
  z-index: 6;
}
.engaging-search.active .engaging-search__item:nth-child(6) {
  --rotate-deg: 100deg;
  z-index: 5;
}
.engaging-search.active .engaging-search__item:nth-child(7) {
  --rotate-deg: 120deg;
  z-index: 4;
}
.engaging-search.active .engaging-search__item:nth-child(8) {
  --rotate-deg: 140deg;
  z-index: 3;
}
.engaging-search.active .engaging-search__item:nth-child(9) {
  --rotate-deg: 160deg;
  z-index: 2;
}
.engaging-search.active .engaging-search__item:nth-child(10) {
  --rotate-deg: 180deg;
  z-index: 1;
}
.engaging-search.active .engaging-search__item .engaging-search__text {
  opacity: 1;
}

.engaging-search__item {
  --rotate-deg: 0deg;
  position: absolute;
  bottom: 0;
  width: 159px;
  height: 428.7px;
  opacity: 0;
  color: var(--color-gray);
  transform-origin: right bottom;
  transform: rotate(var(--rotate-deg)) scale(0.75);
  z-index: 10;
  transition: transform ease 400ms, box-shadow ease 400ms, opacity ease 700ms;
}
.engaging-search__item.is-exec .engaging-search__text.interim {
  display: none;
}
.engaging-search__item.is-exec .engaging-search__text.exec {
  display: block;
}
.engaging-search__item:first-child, .engaging-search__item:last-child {
  height: 479px;
}
.engaging-search__item:first-child .engaging-search__text, .engaging-search__item:last-child .engaging-search__text {
  max-width: min(max(
            375px + 170 *
              (
                (100vw - 1024px) / 416
              )
          , 375px), 545px);
}
.engaging-search__item:nth-child(2), .engaging-search__item:nth-child(7) {
  color: var(--color-blue);
}
.engaging-search__item:nth-child(3), .engaging-search__item:nth-child(8) {
  color: var(--color-green);
}
.engaging-search__item:nth-child(4), .engaging-search__item:nth-child(9) {
  color: var(--color-yellow);
}
.engaging-search__item:nth-child(5), .engaging-search__item:nth-child(10) {
  color: var(--color-red);
}
.engaging-search__item svg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.engaging-search__text {
  position: absolute;
  bottom: 100%;
  left: 100%;
  transform-origin: left center;
  transform: rotate(calc(var(--rotate-deg) * -1));
  width: max-content;
  max-width: min(max(
            375px + 68 *
              (
                (100vw - 1025px) / 415
              )
          , 375px), 443px);
  color: var(--color-white);
  font-family: var(--font-main);
  font-size: min(max(
            18px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 22px);
  font-weight: 300;
  opacity: 0;
  transition: opacity 400ms ease, transform ease 400ms;
}
.engaging-search__text.exec {
  display: none;
}
.engaging-search__text strong {
  font-weight: 700;
}
.engaging-search__text * {
  color: var(--color-white);
  font-family: var(--font-main);
  font-size: min(max(
            18px + 4 *
              (
                (100vw - 1024px) / 416
              )
          , 18px), 22px);
  font-weight: 300;
}

.engaging-search__item-svg {
  overflow: visible;
  filter: drop-shadow(0 3px 13px rgba(0, 0, 0, 0.3));
}

.engaging-search__select-wrap {
  position: relative;
  padding-top: 10px;
  padding-right: min(max(
            12px + 15 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 27px);
}
@media only screen and (max-width: 1025px) {
  .engaging-search__select-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 23px;
    padding-right: 0;
  }
}

.engaging-search__select-comment {
  width: fit-content;
  margin-left: auto;
  margin-top: 33px;
  opacity: 0;
  visibility: hidden;
  transition: opacity ease 250ms, visibility ease 250ms;
}
@media only screen and (max-width: 1025px) {
  .engaging-search__select-comment {
    position: absolute;
    bottom: calc(100% + 9px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.engaging-search__select-comment-item {
  display: flex;
  align-items: center;
}
.engaging-search__select-comment-item:not(:last-child) {
  margin-bottom: 13px;
}
@media only screen and (max-width: 1025px) {
  .engaging-search__select-comment-item:not(:last-child) {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
.engaging-search__select-comment-item img {
  width: 27px;
  height: 27px;
}

.engaging-search__select-comment-item-text {
  margin-left: 11px;
  font-family: var(--font-main);
  font-size: 12px;
  font-weight: 200;
}

.engaging-search__title {
  margin-bottom: min(max(
            15px + 10 *
              (
                (100vw - 1025px) / 415
              )
          , 15px), 25px);
  font-family: var(--font-main);
  font-size: min(max(
            20px + 30 *
              (
                (100vw - 1024px) / 416
              )
          , 20px), 50px);
  font-weight: 600;
  line-height: 1.15;
  text-align: right;
}
@media only screen and (max-width: 1025px) {
  .engaging-search__title {
    margin-bottom: 0;
    font-size: 32px;
    text-align: left;
  }
}
@media only screen and (max-width: 501px) {
  .engaging-search__title {
    font-size: 20px;
  }
}

.engaging-search__title-exec {
  display: none;
  white-space: nowrap;
}

.engaging-search__select {
  display: flex;
  justify-content: flex-end;
}

.engaging-search__select-checkbox-wrap {
  cursor: pointer;
}
.engaging-search__select-checkbox-wrap:hover .engaging-search__select-custom-checkbox::before {
  background: #807d8a;
}
.engaging-search__select-checkbox-wrap .engaging-search__select-default-checkbox:checked ~ .engaging-search__select-custom-checkbox::before {
  transform: translateY(100%);
}

.engaging-search__select-default-checkbox {
  display: none;
}

.engaging-search__select-custom-checkbox {
  display: flex;
  justify-content: center;
  width: min(max(
            22px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 30px);
  height: min(max(
            44px + 13 *
              (
                (100vw - 768px) / 672
              )
          , 44px), 57px);
  padding: min(max(
            1px + 1 *
              (
                (100vw - 768px) / 672
              )
          , 1px), 2px);
  border-radius: 50px;
  background: var(--color-white);
}
.engaging-search__select-custom-checkbox::before {
  content: "";
  display: block;
  width: 100%;
  height: 50%;
  border-radius: 50%;
  background: #c9c4d9;
  transition: transform ease 250ms, background ease 250ms;
}

.engaging-search__select-variants-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 15px;
}

.engaging-search__select-variant {
  font-family: var(--font-main);
  font-size: min(max(
            12px + 9 *
              (
                (100vw - 1024px) / 416
              )
          , 12px), 21px);
  font-weight: 700;
  text-align: right;
  opacity: 0.4;
  transition: opacity ease 250ms;
}
.engaging-search__select-variant.active {
  opacity: 1;
}

.engaging-search-mob {
  display: none;
}
.engaging-search-mob.active .engaging-search-mob__item {
  transform: translateX(0);
  opacity: 1;
}
@media only screen and (max-width: 1025px) {
  .engaging-search-mob {
    display: block;
  }
}

.engaging-search-mob__item {
  display: flex;
  color: var(--color-gray);
  transform: translateX(100%);
  opacity: 0;
  transition: opacity ease 400ms, transform ease 400ms;
}
.engaging-search-mob__item:not(:last-child) {
  margin-bottom: 9px;
}
.engaging-search-mob__item:nth-child(2), .engaging-search-mob__item:nth-child(7) {
  color: var(--color-blue);
}
.engaging-search-mob__item:nth-child(3), .engaging-search-mob__item:nth-child(8) {
  color: var(--color-green);
}
.engaging-search-mob__item:nth-child(4), .engaging-search-mob__item:nth-child(9) {
  color: var(--color-yellow);
}
.engaging-search-mob__item:nth-child(5), .engaging-search-mob__item:nth-child(10) {
  color: var(--color-red);
}

.engaging-search-mob__item-triangle {
  overflow: visible;
  flex-shrink: 0;
  width: min(max(
            10px + 5 *
              (
                (100vw - 601px) / 423
              )
          , 10px), 15px);
  margin-top: 0.5em;
  margin-right: 14px;
  font-size: min(max(
            14px + 6 *
              (
                (100vw - 601px) / 423
              )
          , 14px), 20px);
}

.engaging-search-mob__text {
  color: var(--color-white);
  font-family: var(--font-main);
  font-size: min(max(
            14px + 6 *
              (
                (100vw - 601px) / 423
              )
          , 14px), 20px);
}
.engaging-search-mob__text strong {
  font-weight: 700;
}
.engaging-search-mob__text * {
  color: var(--color-white);
  font-family: var(--font-main);
  font-size: min(max(
            14px + 6 *
              (
                (100vw - 601px) / 423
              )
          , 14px), 20px);
  font-weight: 300;
}
.engaging-search-mob__text.exec {
  display: none;
}

.cards__swiper {
  overflow: visible !important;
}
.cards__swiper.cards__swiper-hiring .card-about {
  cursor: pointer;
}
.cards__swiper .swiper-slide {
  position: relative;
}

.card-about {
  display: flex;
  flex-direction: column;
  clip-path: polygon(0 0, calc(100% - 58px) 0, 100% 58px, 100% 100%, 58px 100%, 0 calc(100% - 58px));
  height: 100%;
  padding: 29px 18px 35px;
  color: var(--color-white);
  background: var(--color-blue-dark);
  font-family: var(--font-main);
  transition: background ease 250ms;
}
.card-about::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: -1;
  background: var(--color-white);
  opacity: 0;
  transition: opacity ease 250ms;
  clip-path: polygon(0 0, calc(100% - 57px) 0, 100% 57px, 100% 100%, 57px 100%, 0 calc(100% - 57px));
}
.card-about:hover {
  color: var(--color-blue-dark);
}
.card-about:hover::before {
  opacity: 1;
}
.card-about:hover * {
  color: var(--color-blue-dark);
}
.card-about:hover .card-about__link,
.card-about:hover .card-about__btn {
  color: var(--color-blue-dark);
}
.card-about.active {
  color: var(--color-blue-dark);
}
.card-about.active::before {
  opacity: 1;
}
.card-about.active .card-about__hidden-text {
  opacity: 1;
  color: var(--color-blue-dark);
}
.card-about.active .card-about__main-text {
  color: var(--color-blue-dark);
}
.card-about.active .card-about__btn span {
  transform: rotate(-90deg);
}
.card-about.active .card-about__link {
  display: flex !important;
  color: var(--color-blue-dark);
}
.card-about.active .card-about__btn {
  display: none;
}

.card-about__title {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 500;
}

.card-about__text {
  max-height: min(max(
            300px + 110 *
              (
                (100vw - 768px) / 672
              )
          , 300px), 410px);
  overflow: auto;
  margin-bottom: 44px;
  font-size: 18px;
}
.card-about__text * {
  color: var(--color-white);
}
@media only screen and (max-width: 551px) {
  .card-about__text {
    max-height: 244px;
  }
}

.card-about__hidden-text {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 250ms ease, max-height 250ms ease;
}

.card-about__main-text {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

.card-about__btn,
.card-about__link {
  margin-left: auto;
  margin-right: 0 !important;
  color: var(--color-white);
}
.card-about__btn span,
.card-about__link span {
  transition: transform 250ms ease;
}

.card-about__btn:not(:last-of-type) {
  margin-bottom: 15px;
}
.card-about__btn:first-of-type {
  margin-top: auto;
}

.card-about__link {
  display: none !important;
}

.eapps-instagram-feed {
  z-index: 1 !important;
}

.btn.cards__popup-btn {
  margin-top: 10px;
  margin-left: auto;
  margin-right: 0;
}

.apply-form-info {
  position: relative;
}

.apply-form-info__main {
  width: fit-content;
}

.apply-form-info__main-text {
  display: inline-block;
  margin-right: min(max(
            5px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 5px), 10px);
  color: var(--color-blue-2);
  font-size: min(max(
            14px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 20px);
  line-height: 1.5;
  cursor: default;
}

.apply-form-info__main-icon {
  display: inline-block;
  color: var(--color-blue-dark);
  transition: color 250ms ease;
  cursor: default;
}

.apply-form-info__hidden-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 999999;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
}
.apply-form-info__hidden-wrap.active {
  opacity: 1;
  visibility: visible;
}

.apply-form-info__hidden-close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  font-size: 11px;
  color: var(--color-white);
  background: var(--color-yellow);
}

.apply-form-info__hidden-text {
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  max-height: 90%;
  max-width: 90%;
  width: 70%;
  background: var(--color-white);
  font-size: 14px;
  transition: opacity 250ms ease, visibility 250ms ease;
}
.apply-form-info__hidden-text p {
  text-align: justify;
}
.apply-form-info__hidden-text a {
  display: inline-block;
  color: var(--color-blue-2);
}
.apply-form-info__hidden-text a:hover {
  text-decoration: underline;
}
.apply-form-info__hidden-text #apply-form-info__close {
  position: absolute;
}

.cv-upload-additional-text {
  grid-column: 1/-1;
  font-size: 14px;
}
@media only screen and (max-width: 551px) {
  .cv-upload-additional-text {
    margin-bottom: 0px;
  }
}

.transition-none {
  transition: none !important;
}

.font-bold {
  font-weight: 700 !important;
}

.checkBox.cv-checkbox {
  margin: 0;
}
.checkBox.cv-checkbox .check-title {
  font-size: 14px;
}
@media only screen and (max-width: 551px) {
  .checkBox.cv-checkbox {
    margin: 0;
  }
}
@media only screen and (max-width: 415px) {
  .checkBox.cv-checkbox {
    margin: 0;
  }
  .checkBox.cv-checkbox:not(:last-of-type) {
    margin: 0;
  }
}

.clients-slider__pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.clients-slider__pagination {
  transform: none !important;
  margin-left: 10px;
}
.clients-slider__pagination .swiper-pagination-bullet {
  border-color: var(--color-blue-dark);
}
.clients-slider__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--color-blue-dark);
}

.say__total-swiper-pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.say__total-swiper-pagination {
  transform: none !important;
  margin: 0 10px;
  width: fit-content !important;
}

.say__total-swiper-btn {
  color: var(--color-white);
}

.apply-form .select2-container--default .select2-selection--single {
  display: flex;
  align-items: center;
}
.apply-form .select2-container--default .select2-selection--single .select2-selection__arrow {
  position: relative;
  top: 0;
  right: 0;
}

.popup-loader__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 111;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity ease 250ms, visibility ease 250ms;
}
.popup-loader__wrap.is-show {
  opacity: 1;
  visibility: visible;
}

.popup-loader {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border: 5px solid var(--color-white);
  border-bottom-color: var(--color-blue-2);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lets-talk * {
  color: var(--color-white);
}
.lets-talk a {
  display: inline-block;
  color: var(--color-yellow-2);
}
.lets-talk a:hover {
  text-decoration: underline;
}

.acsb-trigger {
  bottom: 90px !important;
}

.popup.loading::before, .popup.loading::after {
  opacity: 1;
  visibility: visible;
}
.popup::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all ease 250ms;
}
.popup::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 12;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border: 5px solid #fff;
  border-bottom-color: #127fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  opacity: 0;
  visibility: hidden;
  transition: all ease 250ms;
}
@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.grecaptcha-badge {
  bottom: 85px !important;
}

.head {
  background-color: var(--color-blue-dark);
  min-height: min(max(
            270px + 130 *
              (
                (100vw - 768px) / 672
              )
          , 270px), 400px);
  position: relative;
  padding-bottom: 30px;
}
.head .dec {
  pointer-events: none;
}
.head .dec img {
  bottom: 0px;
  right: -4px;
  transform-origin: right bottom;
}
.head__cont {
  margin-right: 75px;
}
.head__cont h1 {
  font-size: min(max(
            30px + 40 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 70px);
  color: var(--color-white);
  font-weight: 600;
  padding-top: min(max(
            170px + 86 *
              (
                (100vw - 768px) / 672
              )
          , 170px), 256px);
  letter-spacing: -1px;
}
@media only screen and (max-width: 400px) {
  .head__cont.head-team__cont {
    margin-right: 0;
  }
}

.show {
  position: relative;
}
.show .show-dec {
  pointer-events: none;
}
.show .show-dec img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: min(max(
            88px + 130 *
              (
                (100vw - 768px) / 672
              )
          , 88px), 218px);
  height: auto;
}
.show__text-block {
  max-width: 969px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.show__title {
  max-width: 280px;
  margin-left: min(max(
            0px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 5px);
}
.show__title h1,
.show__title h2,
.show__title h3,
.show__title h4 {
  font-weight: 600;
  font-size: min(max(
            22px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 34px);
  letter-spacing: 0.4px;
}
.show__title h1 span,
.show__title h2 span,
.show__title h3 span,
.show__title h4 span {
  background-color: var(--color-yellow-dark);
  padding: 2px 3px;
}
.show__text {
  max-width: 572px;
  margin-top: min(max(
            25px + -15 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 25px);
}
.show__text.w-full {
  max-width: 100%;
}
.show__text p {
  font-weight: 400;
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
}
.show__video {
  margin: min(max(
            30px + 29 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 59px) auto 0;
  max-width: 1160px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*clip-path: polygon(
    20% -1px,
    92% -1px,
    100% 21%,
    100% 80%,
    100% 100%,
    8% 100%,
    0px 78%,
    0 -1px
  );*/
  /*.plyr--video .plyr__controls {
    display: none;
    width: 80%;
    margin: 0 auto;
  }*/
}
.show__video .plyr {
  width: 100%;
  max-width: min(max(
            444px + 310 *
              (
                (100vw - 768px) / 672
              )
          , 444px), 754px);
  height: min(max(
            250px + 174 *
              (
                (100vw - 768px) / 672
              )
          , 250px), 424px);
}
.show__video .plyr video,
.show__video .plyr iframe {
  width: 100%;
  max-width: min(max(
            444px + 310 *
              (
                (100vw - 768px) / 672
              )
          , 444px), 754px);
  height: min(max(
            250px + 174 *
              (
                (100vw - 768px) / 672
              )
          , 250px), 424px);
}
.show__video .plyr.plyr-hide-controls .plyr__controls {
  opacity: 0;
}
@media only screen and (max-width: 1024px) {
  .show__video .plyr.plyr-hide-controls .plyr__controls {
    opacity: 1;
  }
}
.show__video .plyr__poster {
  background-size: cover;
  /*clip-path: polygon(
    20% -1px,
    92% -1px,
    100% 21%,
    100% 80%,
    100% 100%,
    8% 100%,
    0px 78%,
    0 -1px
  );*/
}
.show__video .plyr:fullscreen video {
  height: 100% !important;
  width: 100% !important;
}
.show__video .plyr__control--overlaid {
  background: var(--color-green-2);
  border: 0;
  border-radius: 0;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  display: none;
  opacity: 1;
  padding: 15px;
}
.show__video .plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.show__video .plyr__control--overlaid {
  font: inherit;
  line-height: inherit;
  width: 70px;
  height: 70px;
}
@media only screen and (max-width: 901px) {
  .show__video .plyr__control--overlaid {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 769px) {
  .show__video .plyr__control--overlaid {
    width: 40px;
    height: 40px;
  }
}
.show__video .plyr__control--overlaid::before {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 65%;
  border: min(max(
            12px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 20px) solid transparent;
  border-left: min(max(
            16px + 9 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 25px) solid var(--color-white);
}
.show__video .plyr__control--overlaid svg {
  display: none;
}
.show__video .plyr--video .plyr__control:hover {
  border-radius: 100%;
  background: var(--color-blue);
}
@media only screen and (max-width: 551px) {
  .show__video {
    max-width: 100%;
  }
}

.career .career-dec {
  pointer-events: none;
}
.career .career-dec img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: min(max(
            88px + 130 *
              (
                (100vw - 768px) / 672
              )
          , 88px), 218px);
  height: auto;
}
.career__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1062px;
  margin-left: 97px;
}
.career__inner.wide {
  justify-content: center;
  margin: 0 auto;
}
.career__inner.wide .career__video {
  max-width: 770px;
  height: min(max(
            300px + 150 *
              (
                (100vw - 768px) / 672
              )
          , 300px), 450px);
}
.career__inner.wide .career__video iframe {
  width: 100%;
  height: 100%;
}
.career__text {
  max-width: 474px;
  width: 100%;
  margin-right: 40px;
}
.career__text h2,
.career__text h3,
.career__text h4 {
  margin-bottom: min(max(
            9px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 9px), 15px);
  font-size: min(max(
            30px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 50px);
  font-size: min(max(
            26px + 18 *
              (
                (100vw - 768px) / 672
              )
          , 26px), 44px);
  font-weight: 600;
}
.career__text h2 span,
.career__text h3 span,
.career__text h4 span {
  background-color: var(--color-green);
  padding: 2px 2px;
}
.career__text p {
  margin-bottom: min(max(
            18px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 29px);
}
.career__video {
  max-width: 471px;
  width: 100%;
  margin-top: 23px;
  /*clip-path: polygon(
    20% 0,
    85% 0,
    100% 16%,
    100% 80%,
    100% 100%,
    16% 100%,
    0 83%,
    0 0
  );*/
}
.career .plyr--video {
  --plyr-color-main: var(--color-yellow);
  /*clip-path: polygon(
    20% 0,
    85% 0,
    100% 16%,
    100% 80%,
    100% 100%,
    16% 100%,
    0 83%,
    0 0
  );*/
}
.career .plyr .plyr__video-wrapper {
  aspect-ratio: auto;
}
.career .plyr:fullscreen video {
  height: 100% !important;
}
.career .plyr__poster {
  background-size: cover;
}
.career .plyr--video.plyr--stopped .plyr__controls {
  display: none;
}
.career .plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.career .plyr__control--overlaid {
  background: var(--color-yellow-2);
  border: 0;
  border-radius: 0;
  color: #fff;
  display: none;
  opacity: 1;
}
.career .plyr__control--overlaid::before {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 66%;
  border: min(max(
            12px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 20px) solid transparent;
  border-left: min(max(
            16px + 9 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 25px) solid var(--color-white);
}
.career .plyr__control--overlaid svg {
  display: none;
}
.career .plyr .plyr__control--overlaid {
  font: inherit;
  line-height: inherit;
  width: 70px;
  height: 70px;
}
@media only screen and (max-width: 901px) {
  .career .plyr .plyr__control--overlaid {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 769px) {
  .career .plyr .plyr__control--overlaid {
    width: 40px;
    height: 40px;
  }
}
.career .plyr--video .plyr__control:hover {
  border-radius: 100%;
  background: var(--color-blue);
}

.title-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.title-box h2 {
  width: 37%;
  font-weight: 600;
}
.title-box__cont {
  width: 58%;
  padding-left: 5px;
}
.title-box__cont p {
  margin-bottom: min(max(
            17px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 17px), 23px);
}
.title-box__cont .btn-block {
  margin-top: 10px;
}

.team-card__img {
  max-width: 278px;
  clip-path: polygon(16% 0%, 100% 0%, 100% 0, 100% 83%, 84% 100%, 0 100%, 0 100%, 0% 16%);
  overflow: hidden;
  transition: transform 250ms ease;
}
.team-card__img img {
  object-fit: cover;
  transition: transform 300ms ease;
}
.team-card__name h3 {
  margin-top: min(max(
            12px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 18px);
  font-family: Lexend;
  font-weight: 500;
  font-size: min(max(
            18px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 25px);
  line-height: 1.3;
}
.team-card__position h3 {
  font-size: min(max(
            14px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 21px);
  line-height: min(max(
            18px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 24px);
  color: var(--color-red);
  font-weight: 400;
}
.team-card__links {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.team-card__link-box a {
  display: inline-block;
  margin-right: min(max(
            5px + 1 *
              (
                (100vw - 768px) / 672
              )
          , 5px), 6px);
}
.team-card__link-box a:last-child {
  margin-right: 3px;
}
.team-card__link-none a:nth-child(1) {
  display: none;
}
.team-card__link-none a:nth-child(2) {
  display: none;
}
.team-card__link i::before {
  color: var(--color-blue-dark);
}
.team-card__link .icon-email {
  font-size: min(max(
            18px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 22px);
}
.team-card__link .icon-twitter {
  font-size: min(max(
            18px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 22px);
}
.team-card__link .icon-linkedin {
  font-size: min(max(
            17px + 2 *
              (
                (100vw - 768px) / 672
              )
          , 17px), 19px);
}
.team-card__link i:hover::before {
  transition: all 0.3s ease-in-out;
  color: var(--color-blue);
}

.slider-control {
  position: relative;
  max-width: 190px;
  margin: 0 auto;
  height: 13px;
}
.slider-control .swiper-button-prev::after,
.slider-control .swiper-button-next::after {
  display: none;
}
.slider-control .slider-control__prev i,
.slider-control .slider-control__next i {
  transition: all 0.3s ease-in-out;
  color: var(--color-blue-dark);
}
.slider-control .slider-control__prev:hover i,
.slider-control .slider-control__next:hover i {
  transform: scale(1.2);
}
.slider-control .slider-control__pagination {
  bottom: 0;
}
.slider-control .slider-control__next {
  right: 0;
}
.slider-control .slider-control__prev {
  left: 0;
}
.slider-control .swiper-pagination-bullet {
  border: 1px solid var(--color-blue-dark);
  margin: 0 7px 0 0 !important;
}
.slider-control .swiper-pagination-bullet-active {
  background: var(--color-blue-dark);
}
.slider-control .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 51%;
}

.soc p {
  font-size: min(max(
            16px + 1 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 17px);
  font-weight: 700;
}
.soc__box {
  margin-top: 15px;
  display: flex;
}
.soc__box a {
  margin-right: 18px;
}
.soc__box a:last-child {
  margin-right: 0;
}
.soc__box a i {
  font-size: 20px;
  transition: all 0.3s;
}
.soc__box a i::before {
  color: var(--color-blue);
}
.soc__box a:hover i::before {
  color: var(--color-green-2);
}

.candidate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: min(max(
            15px + 18 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 33px) 13px min(max(
            15px + 2 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 17px) 13px;
  background-color: var(--color-blue-dark);
  max-width: 278px;
  min-height: min(max(
            150px + 50.76 *
              (
                (100vw - 768px) / 672
              )
          , 150px), 200.76px);
  clip-path: polygon(0 0, 83% 0%, 100% 22%, 100% 100%, 100% 100%, 15% 100%, 0% 80%, 0 0);
}
.candidate h4 {
  text-align: center;
  color: var(--color-white);
  font-size: min(max(
            21px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 21px), 32px);
  line-height: 1.4;
  font-weight: 500;
}
.candidate a,
.candidate button {
  margin-top: min(max(
            18px + 9 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 27px);
  color: var(--color-white);
}
.candidate a span::before,
.candidate button span::before {
  border-left: 13px solid var(--color-blue-dark);
}

.show-about {
  padding: min(max(
            50px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 58px) 0px min(max(
            50px + 37 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 87px);
}

.values {
  background-color: var(--color-blue-dark);
  position: relative;
}
.values.js-values-dynamic-title .values__title h2 {
  position: absolute;
  left: 0;
  padding-left: min(max(
            55px + 21 *
              (
                (100vw - 768px) / 672
              )
          , 55px), 76px);
}
@media only screen and (max-width: 769px) {
  .values.js-values-dynamic-title .values__title h2 {
    position: static !important;
    padding-left: 0;
  }
}
.values .decor {
  pointer-events: none;
}
.values .decor img:first-child {
  width: min(max(
            35px + 105 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 140px);
  height: auto;
  bottom: -1px;
  left: -1px;
  transform-origin: top left;
}
.values .decor img:last-child {
  width: min(max(
            35px + 105 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 140px);
  height: auto;
  top: -1px;
  right: -5px;
  transform-origin: bottom right;
}
.values__inner {
  padding: min(max(
            50px + 36 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 86px) 0px min(max(
            50px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 70px);
}
.values__title h2 {
  text-align: center;
  color: var(--color-white);
  font-weight: 600;
  font-size: min(max(
            22px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 34px);
  letter-spadaptive-calcing: -1px;
}
.values__block {
  margin-top: 48px;
}
.values__nav h2 {
  color: rgba(255, 255, 255, 0.3607843137);
}
.values__item h2,
.values__item .tab__item-text {
  color: var(--color-white);
}
.values__item h2 *,
.values__item h2 p,
.values__item .tab__item-text *,
.values__item .tab__item-text p {
  color: var(--color-white);
}

.cards-about {
  position: relative;
  padding: min(max(
            100px + 50 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 150px) 0px min(max(
            100px + 89 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 189px);
}
@media only screen and (max-width: 1100px) {
  .cards-about {
    padding-top: 160px;
  }
}
.cards-about .decor {
  pointer-events: none;
}
.cards-about .decor img {
  top: 3px;
  right: -7px;
  transform-origin: bottom right;
  width: 126.72px;
  height: auto;
}
@media only screen and (max-width: 1350px) {
  .cards-about .decor img {
    top: -20px;
    right: -20px;
  }
}

.team-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.team-card__links {
  flex: 1;
  align-items: flex-end;
}

.team-s {
  padding-bottom: min(max(
            80px + 77 *
              (
                (100vw - 768px) / 672
              )
          , 80px), 157px);
}
.team-s .team-s-box {
  margin-bottom: min(max(
            30px + 34 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 64px);
}
.team-s .team-s-box h2 {
  margin-top: 2px;
}
.team-s .team-s-box__cont p {
  margin-bottom: min(max(
            17px + 18 *
              (
                (100vw - 768px) / 672
              )
          , 17px), 35px);
}
.team-s__slider {
  max-width: 100%;
  padding-bottom: 52px;
  margin: 0 auto;
  clip-path: inset(0 0 0 0);
}
@media only screen and (max-width: 1024px) {
  .team-s__slider {
    clip-path: none;
    max-width: 278px;
  }
}
.team-s__slide {
  /*    opacity: 0.5;*/
  height: auto;
}
@media only screen and (max-width: 1024px) {
  .team-s__slide {
    opacity: 0.5;
  }
}
.team-s .swiper-slide-active {
  opacity: 1;
}
.team-s .swiper-slide-next,
.team-s .swiper-slide-prev {
  opacity: 1;
}
.team-s__slider.swiper {
  overflow: visible;
}
.team-s__control {
  margin-top: 2px;
}
.team-s .slider-control__prev {
  left: 0;
}
.team-s .slider-control__next {
  right: 0;
}
.team-s + .clients.clients-about {
  margin-top: 0;
}

.progress-section {
  padding: min(max(
            50px + 54 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 104px) 0 min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px);
  background-color: var(--color-blue-dark);
}
.progress-section.firefox .progress-item:nth-child(odd) .progress-item_box::before, .progress-section.safari .progress-item:nth-child(odd) .progress-item_box::before {
  transform: translateX(calc(-50% - 4px));
}
@media only screen and (max-width: 769px) {
  .progress-section.firefox .progress-item:nth-child(odd) .progress-item_box::before, .progress-section.safari .progress-item:nth-child(odd) .progress-item_box::before {
    left: -33px !important;
    top: 20px !important;
    transform: translateX(-50%) !important;
  }
}
.progress-section.firefox .progress-item:nth-child(even) .progress-item_box::before, .progress-section.safari .progress-item:nth-child(even) .progress-item_box::before {
  transform: translateX(calc(50% - 4px));
}
@media only screen and (max-width: 769px) {
  .progress-section.firefox .progress-item:nth-child(even) .progress-item_box::before, .progress-section.safari .progress-item:nth-child(even) .progress-item_box::before {
    left: -33px !important;
    top: 20px !important;
    transform: translateX(-50%) !important;
  }
}
.progress-section .decor {
  pointer-events: none;
}
.progress-section .decor img {
  width: min(max(
            40px + 100 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 140px);
  height: auto;
}
.progress-section .decor img:first-child {
  bottom: -1px;
  left: -1px;
  transform-origin: top left;
}
.progress-section .decor img:last-child {
  top: -1px;
  right: -5px;
  transform-origin: bottom right;
}
.progress-section h2 {
  color: var(--color-white);
  text-align: center;
  margin-bottom: min(max(
            40px + 42 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 82px);
}
.progress-chain {
  position: relative;
  max-width: 1062px;
  margin: 0 auto;
}
.progress-items {
  max-height: 456px;
  overflow: auto;
}
@media only screen and (max-width: 769px) {
  .progress-items {
    max-height: 230px;
  }
}
.progress-item {
  display: flex;
}
.progress-item .color-1 h4 {
  color: var(--color-yellow);
}
.progress-item .color-1::before {
  background-color: var(--color-yellow);
}
.progress-item .color-2 h4 {
  color: var(--color-green);
}
.progress-item .color-2::before {
  background-color: var(--color-green);
}
.progress-item .color-3 h4 {
  color: var(--color-blue);
}
.progress-item .color-3::before {
  background-color: var(--color-blue);
}
.progress-item .color-4 h4 {
  color: var(--color-red);
}
.progress-item .color-4::before {
  background-color: var(--color-red);
}
.progress-item_box {
  width: 50%;
}
.progress-item span {
  position: relative;
}
.progress-item span::before {
  content: "";
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}
.progress-item h4 {
  width: 50%;
  flex-shrink: 0;
  color: var(--color-white);
  font-size: min(max(
            22px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 34px);
  line-height: 1.7;
  position: relative;
}
.progress-item p {
  flex-shrink: 0;
  width: 50%;
  color: var(--color-white);
}
.progress-item:not(:first-child) {
  margin-top: min(max(
            40px + 100 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 140px);
}
.progress-item:not(:last-of-type) {
  margin-bottom: min(max(
            60px + -48 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 60px);
}
.progress-item:nth-child(odd) span {
  position: relative;
}
.progress-item:nth-child(odd) span::before {
  left: 100%;
  top: 38px;
  transform: translateX(-50%);
}
.progress-item:nth-child(odd) h4 {
  width: 63%;
  margin-left: auto;
  padding-right: 106px;
  line-height: 1.5;
  margin-top: 11px;
}
.progress-item:nth-child(odd) p {
  padding-left: 63px;
  padding-right: 10px;
}
.progress-item:nth-child(even) {
  margin-left: auto;
  flex-direction: row-reverse;
}
.progress-item:nth-child(even) span {
  position: relative;
}
.progress-item:nth-child(even) span::before {
  right: 100%;
  top: 35px;
  transform: translateX(50%);
}
.progress-item:nth-child(even) h4 {
  text-align: left;
  position: relative;
  padding-left: 63px;
}
.progress-item:nth-child(even) p {
  padding-right: 57px;
}
@media only screen and (max-width: 769px) {
  .progress-item {
    max-width: 100%;
    flex-direction: column;
    padding-right: 10px;
  }
  .progress-item p {
    padding: 0;
    width: 100%;
  }
  .progress-item_box {
    width: 100%;
  }
  .progress-item span {
    position: relative;
  }
  .progress-item span::before {
    width: 18px;
    height: 18px;
  }
  .progress-item:nth-child(odd), .progress-item:nth-child(even) {
    margin-left: 45px;
    text-align: left;
    flex-direction: column;
  }
  .progress-item:nth-child(odd) span::before, .progress-item:nth-child(even) span::before {
    left: -33px;
    top: 20px;
    transform: translateX(-50%);
  }
  .progress-item:nth-child(odd) h4, .progress-item:nth-child(even) h4 {
    width: 100%;
    text-align: left;
  }
  .progress-item:nth-child(even) span::before {
    top: 10px;
  }
  .progress-item:nth-child(even) h4 {
    padding-left: 0;
  }
  .progress-item:nth-child(odd) h4 {
    margin-left: inherit;
  }
  .progress-item:nth-child(odd) p {
    padding-left: 0;
  }
}
.progress-line {
  width: 4px;
  height: 100%;
  position: absolute;
  background-color: var(--color-white);
  top: 0;
  left: calc(50% - 5px);
  transform: translateX(-25%);
  overflow: hidden;
}
@media only screen and (max-width: 769px) {
  .progress-line {
    left: 10px;
    transform: none;
  }
}

.clients-about {
  margin: min(max(
            60px + 106 *
              (
                (100vw - 768px) / 672
              )
          , 60px), 166px) 0 min(max(
            25px + 51 *
              (
                (100vw - 768px) / 672
              )
          , 25px), 76px);
}

.clients__box {
  margin-bottom: min(max(
            40px + 45 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 85px);
  justify-content: space-between;
}
.clients__box-cont p {
  margin-bottom: min(max(
            10px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 20px);
}
.clients__box-cont .btn-block a {
  margin-top: 16px;
}
.clients-slider {
  /*max-width: 244px;*/
  max-width: 100%;
  clip-path: inset(0 0 0 0);
}
@media only screen and (max-width: 1024px) {
  .clients-slider {
    /*clip-path: none;*/
    /*max-width: 244px;*/
  }
}
.clients-slider.swiper {
  overflow: visible;
}
.clients-slider.swiper .swiper-wrapper {
  align-items: center;
}
.clients-slide img {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
}

.video-sec {
  padding: min(max(
            50px + 85 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 135px) 0 min(max(
            120px + 100 *
              (
                (100vw - 768px) / 672
              )
          , 120px), 220px);
  position: relative;
}
.video-sec .decor {
  pointer-events: none;
}
.video-sec .decor img:first-child {
  width: min(max(
            100px + 137.24 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 237.24px);
  height: auto;
  top: 0;
  right: 0px;
  transform-origin: top right;
}
@media only screen and (max-width: 1280px) {
  .video-sec .decor img:first-child {
    top: 20px;
  }
}
.video-sec .decor img:last-child {
  width: min(max(
            100px + 95.5 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 195.5px);
  height: auto;
  bottom: 0;
  left: 0px;
  transform-origin: bottom left;
}
.video-sec__box {
  margin-bottom: min(max(
            40px + 51 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 91px);
}
.video-sec__cont p {
  margin-bottom: min(max(
            20px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 30px);
  max-width: 545px;
}

.career-show {
  padding: min(max(
            50px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 58px) 0px min(max(
            50px + 37 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 87px);
}

.career-main {
  padding: min(max(
            50px + 35 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 85px) 0px 0px;
}
.career-main.d-and-i {
  padding-bottom: min(max(
            50px + 35 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 85px);
}
.career-main.d-and-i .career__text {
  max-width: 100%;
  margin-right: 0;
}

.testimonials {
  padding-top: min(max(
            50px + 178 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 228px);
  margin-bottom: min(max(
            40px + 74 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 114px);
}
.testimonials .testimonials__slide {
  height: auto;
}
.testimonials__slider-box {
  margin: 0 auto;
  max-width: 1180px;
  width: 81.9444444444%;
}
.testimonials .testimonials-decor {
  pointer-events: none;
}
.testimonials .testimonials-decor img {
  position: absolute;
  top: -12px;
  right: -6px;
  width: min(max(
            100px + 105.64 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 205.64px);
  height: auto;
}
.testimonials__title {
  text-align: center;
}
.testimonials__slider {
  margin-top: min(max(
            20px + 30 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 50px);
}
.testimonials__slider.swiper {
  padding: 30px 8px 37px;
  margin-left: -8px;
  margin-right: -8px;
}

.staff-card.orange .decor {
  pointer-events: none;
}
.staff-card.orange .decor p,
.staff-card.orange .decor span {
  color: var(--color-yellow-2);
}
.staff-card.orange .staff-card__name p {
  color: var(--color-yellow-2);
}
.staff-card.orange .staff-card__text .simplebar-scrollbar::before {
  background: var(--color-yellow-2);
}
.staff-card.blue .decor {
  pointer-events: none;
}
.staff-card.blue .decor p,
.staff-card.blue .decor span {
  color: var(--color-blue);
}
.staff-card.blue .staff-card__name p {
  color: var(--color-blue);
}
.staff-card.blue .staff-card__text .simplebar-scrollbar::before {
  background: var(--color-blue);
}
.staff-card.green .decor {
  pointer-events: none;
}
.staff-card.green .decor p,
.staff-card.green .decor span {
  color: var(--color-green-2);
}
.staff-card.green .staff-card__name p {
  color: var(--color-green-2);
}
.staff-card.green .staff-card__text .simplebar-scrollbar::before {
  background: var(--color-green-2);
}

.staff-card {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 376px;
  height: 100%;
  padding: min(max(
            40px + 45 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 85px) 21px min(max(
            20px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 32px);
  margin: 0 auto;
}
.staff-card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0;
  background-color: var(--color-blue-dark);
  clip-path: polygon(0 0, 85% 0, 100% 11%, 100% 100%, 16% 100%, 0 88%);
  z-index: -1;
}
.staff-card .decor {
  pointer-events: none;
}
.staff-card .decor p,
.staff-card .decor span {
  position: absolute;
  font-size: min(max(
            180px + 70 *
              (
                (100vw - 768px) / 672
              )
          , 180px), 250px);
  line-height: 1;
  top: min(max(
            -44px + -9 *
              (
                (100vw - 768px) / 672
              )
          , -53px), -44px);
  left: min(max(
            -11px + -6 *
              (
                (100vw - 768px) / 672
              )
          , -17px), -11px);
  pointer-events: none;
}
.staff-card__text {
  max-height: 262px;
  margin-bottom: 35px;
}
.staff-card__text .simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}
.staff-card__text .simplebar-content-wrapper {
  padding-right: 17px;
}
.staff-card__text p {
  color: var(--color-white);
  text-align: center;
}
.staff-card__name {
  max-width: 107px;
}
.staff-card__name p {
  color: var(--color-white);
  font-size: min(max(
            14px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 17px);
  line-height: 1;
  margin-right: 12px;
  margin-bottom: min(max(
            12px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 24px);
}
.staff-card__position p {
  color: var(--color-white);
  font-size: min(max(
            14px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 17px);
  line-height: 1;
}
.staff-card__box {
  display: flex;
  margin-top: auto;
  margin-left: min(max(
            25px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 25px), 32px);
}
.staff-card__box img {
  margin-right: min(max(
            10px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 16px);
  width: min(max(
            56px + 30 *
              (
                (100vw - 768px) / 672
              )
          , 56px), 86px);
  height: min(max(
            56px + 30 *
              (
                (100vw - 768px) / 672
              )
          , 56px), 86px);
  border-radius: 50%;
}

.lookout {
  position: relative;
  background-color: var(--color-blue-dark);
}
.lookout .decor {
  pointer-events: none;
}
.lookout .decor img:first-child {
  width: min(max(
            50px + 90.03 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 140.03px);
  height: auto;
  top: -1px;
  left: -1px;
  transform-origin: top left;
}
.lookout .decor img:last-child {
  width: min(max(
            50px + 90.03 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 140.03px);
  height: auto;
  bottom: -1px;
  right: -1px;
  transform-origin: bottom right;
}
.lookout__inner {
  padding: min(max(
            40px + 139 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 179px) 0px min(max(
            40px + 129 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 169px);
  max-width: 769px;
  margin: 0 auto;
  text-align: center;
}
.lookout__inner p {
  color: var(--color-white);
}
.lookout__inner a {
  color: var(--color-white);
  display: inline-flex;
  /*margin: 40px auto 0px;*/
  margin: 6px 0 0;
}
.lookout__title h1,
.lookout__title h2,
.lookout__title h3,
.lookout__title h4,
.lookout__title h5,
.lookout__title p {
  color: var(--color-white);
  text-align: center;
  margin-bottom: 20px;
}

.impact {
  margin: min(max(
            50px + 92 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 142px) 0 min(max(
            50px + 108 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 158px);
}
.impact .impact-box {
  margin-bottom: min(max(
            20px + 39 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 59px);
}
.impact .impact-box__cont p {
  margin-bottom: min(max(
            20px + 19 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 39px);
}
.impact__slider {
  max-width: 100%;
  position: relative;
  height: min(max(
            250px + 174 *
              (
                (100vw - 768px) / 672
              )
          , 250px), 424px);
}
.impact__slide-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 92% 0, 100% 21%, 100% 100%, 100% 100%, 8% 100%, 0 79%, 0 0);
}
.impact__slide-box {
  position: absolute;
  bottom: 0px;
  right: 0;
  background-color: var(--color-blue-dark);
  padding: min(max(
            7px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 7px), 17px) min(max(
            15px + 67 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 82px);
}
.impact__slide-box img {
  width: min(max(
            100px + 112 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 212px);
  height: auto;
}

.insta {
  padding-bottom: min(max(
            110px + 34 *
              (
                (100vw - 768px) / 672
              )
          , 110px), 144px);
  padding-top: 80px;
}
.insta .decor {
  pointer-events: none;
}
.insta .decor img {
  width: min(max(
            100px + 95.5 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 195.5px);
  height: auto;
  bottom: 0;
  right: 0px;
  transform-origin: bottom right;
}
.insta__soc {
  display: flex;
  align-items: center;
}
.insta__soc h2 {
  margin-right: 52px;
  line-height: 1;
}
.insta__soc-box {
  position: relative;
  top: 3px;
  display: flex;
  line-height: 1;
}
.insta__soc-box a {
  margin-right: 25px;
  transition: all 0.3s;
}
.insta__soc-box a:hover i::before {
  color: var(--color-yellow-2);
  transition: all 0.3s;
}
.insta__soc-box a i {
  font-size: 25px;
  transition: all 0.3s;
}
.insta__soc-box a i::before {
  color: var(--color-red);
}
.insta__soc-box a:last-child {
  margin-right: 0;
}
.insta__grid {
  margin-top: min(max(
            30px + 30 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 60px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(223px, 1fr));
  grid-auto-flow: dense;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-items: center;
  align-items: center;
}
.insta__grid-block {
  max-width: 278px;
  width: 100%;
  max-height: 278px;
  transition: all 0.3s;
}
.insta__grid-block:hover {
  transform: scale(1.03);
  box-shadow: 0px 0px 15px 6px rgba(0, 0, 0, 0.2);
}
.insta__grid-block img {
  width: 100%;
  max-height: 278px;
}

.insight-card {
  display: flex;
  flex-direction: column;
}
.insight-card .photo {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: max(min(56.25%, 250px), 0px);
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 20px;
}
.insight-card .photo img,
.insight-card .photo iframe,
.insight-card .photo video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.insight-card .top-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.insight-card .date {
  font-family: var(--font-main);
  font-size: min(max(
            11px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 11px), 14px);
  color: var(--primary);
  margin-left: auto;
  margin-bottom: 10px;
}
.insight-card h5 {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.insight-card p {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  margin-bottom: 20px;
}
.insight-card:hover .photo img {
  transform: scale(1.12);
}

.insight-first-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(168deg, rgba(var(--black-rgb), 1) 34%, rgba(var(--primary-rgb), 1) 71%);
}
.insight-first-section .date {
  font-family: var(--font-main);
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  color: var(--primary);
  margin-bottom: 10px;
}
@media only screen and (max-width: 769px) {
  .insight-first-section[data-with-photo]::before {
    mix-blend-mode: multiply;
  }
}

.insights-content {
  margin: min(max(
            30px + 45 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 75px) 0;
}

.insights-result {
  margin-top: min(max(
            70px + 50 *
              (
                (100vw - 768px) / 672
              )
          , 70px), 120px);
}

.insights-recommendation h3 {
  margin-bottom: min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px);
}

.job-card {
  width: 100%;
  padding: min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px);
  border: solid 2px var(--black);
  border-radius: min(max(
            10px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 20px);
  background: var(--bg-second);
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.job-card h4 {
  margin-bottom: 5px;
}
.job-card .description {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
  margin-bottom: 20px;
}
.job-card_small {
  padding: 20px;
}
.job-card_small .btn {
  width: 100%;
}
.job-card_small .btn-wrap .btn:not(:last-of-type) {
  margin-right: 0;
}

.jobs-swiper-section {
  margin: min(max(
            100px + 100 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 200px) 0;
}

.job-recommendation {
  margin: min(max(
            100px + 100 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 200px) 0;
}
.job-recommendation h3 {
  margin-bottom: min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px);
}

.job-page.first-section .info {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
}
.job-page.first-section .info h6 {
  color: var(--primary);
  width: min(max(
            90px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 90px), 110px);
  margin-right: min(max(
            10px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 20px);
  flex-shrink: 0;
}
.job-page.first-section .info h5 {
  margin-bottom: 0;
}

.job-content .sticky-wrap {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 651px) {
  .job-content .sticky-wrap {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 1024px) {
  .job-content .sticky-wrap_related-jobs {
    flex-direction: column;
  }
  .job-content .sticky-wrap_related-jobs .main-part {
    width: 100%;
  }
  .job-content .sticky-wrap_related-jobs .sidebar {
    width: 100%;
  }
}
.job-content .main-part {
  width: 70%;
}
.job-content .sidebar {
  width: 20%;
}
@media only screen and (max-width: 901px) {
  .job-content .sidebar {
    width: 25%;
  }
}
@media only screen and (max-width: 651px) {
  .job-content .main-part, .job-content .sidebar {
    width: 100%;
  }
  .job-content .team-member-card {
    display: flex;
    margin-bottom: 50px;
  }
  .job-content .team-member-card.square-photo .photo {
    width: min(max(
            120px + 80 *
              (
                (100vw - 375px) / 275
              )
          , 120px), 200px);
    padding-bottom: min(max(
            120px + 80 *
              (
                (100vw - 375px) / 275
              )
          , 120px), 200px);
    margin-right: 30px;
  }
}
@media only screen and (max-width: 420px) {
  .job-content .sidebar {
    width: 80%;
    margin: 0 auto;
  }
  .job-content .team-member-card {
    flex-direction: column;
  }
  .job-content .team-member-card.square-photo .photo {
    width: 100%;
    padding-bottom: 100%;
    margin-right: 0;
  }
}

.job-result {
  margin-top: min(max(
            60px + 60 *
              (
                (100vw - 768px) / 672
              )
          , 60px), 120px);
}
.job-result .cont {
  display: flex;
  justify-content: space-between;
}
.job-result .filters {
  width: 22%;
  max-width: initial;
  min-width: 200px;
  margin: 0;
  margin-right: 20px;
}
.job-result .grid-two-wrapper {
  width: 75%;
}
@media only screen and (max-width: 650px) {
  .job-result .cont {
    flex-direction: column;
  }
  .job-result .filters, .job-result .grid-two-wrapper {
    width: 100%;
  }
  .job-result .filters.sidebar {
    margin-bottom: 35px;
  }
  .job-result .filters.sidebar .main-filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
@media only screen and (max-width: 420px) {
  .job-result .filters.sidebar .main-filters {
    grid-template-columns: 1fr;
  }
}

.job-page-related .slick-list {
  margin: 0 -15px;
}
.job-page-related .slick-slide {
  margin: 0 15px;
}

.team-member-card .photo {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: max(68.75%, 0px);
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 20px;
}
.team-member-card .photo img,
.team-member-card .photo iframe,
.team-member-card .photo video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.team-member-card h5 {
  margin-bottom: 5px;
}
.team-member-card h6 {
  margin-bottom: 10px;
  line-height: 1.5;
}
.team-member-card:hover .photo img {
  transform: scale(1.12);
}
.team-member-card.square-photo .photo {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: max(100%, 0px);
  overflow: hidden;
}
.team-member-card.square-photo .photo img,
.team-member-card.square-photo .photo iframe,
.team-member-card.square-photo .photo video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.team-member.first-section .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
}
.team-member.first-section .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.team-member.main-section {
  margin-top: min(max(
            120px + 90 *
              (
                (100vw - 768px) / 672
              )
          , 120px), 210px);
  margin-bottom: min(max(
            70px + 40 *
              (
                (100vw - 768px) / 672
              )
          , 70px), 110px);
}
.team-member.main-section .cont {
  columns: 2;
  column-gap: min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px);
}
@media only screen and (max-width: 901px) {
  .team-member.main-section .cont {
    columns: 1;
  }
}
.team-member.main-section .team-member-block {
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  padding: min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px) min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px);
  border-radius: min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px);
  border: solid 2px var(--primary);
  margin-bottom: min(max(
            20px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 40px);
}
.team-member.main-section .team-member-block h3 {
  margin-bottom: min(max(
            10px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 20px);
}
.team-member.main-section .team-member-block:nth-of-type(1) {
  margin-top: min(max(
            -40px + -30 *
              (
                (100vw - 768px) / 672
              )
          , -70px), -40px);
}

.our-process {
  position: relative;
  padding: 140px 0;
  color: var(--color-white);
  background-color: var(--color-blue-dark);
}
.our-process.slider-is-visible .our-process__line {
  opacity: 1;
  visibility: visible;
}
.our-process.slider-is-visible .our-process__main-content {
  opacity: 0;
  visibility: hidden;
}
.our-process .cont {
  position: relative;
  width: 67%;
}

.our-process__line {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 5px;
  background-color: var(--color-white);
  transition: opacity 250ms ease, visibility 250ms ease;
  opacity: 0;
  visibility: hidden;
}

.our-process__main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 250ms ease, visibility 250ms ease;
}

.our-process__title {
  margin-bottom: 34px;
  color: var(--color-white);
}

.our-process__descr {
  max-width: 572px;
  margin-bottom: 67px;
  font-family: var(--font-main);
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  line-height: 1.77;
  text-align: center;
}
.our-process__descr * {
  color: var(--color-white);
  font-family: var(--font-main);
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  line-height: 1.77;
  text-align: center;
}

.our-process__btns-wrap {
  display: flex;
}

.btn.our-process__btn:not(:last-child) {
  margin-right: 50px;
}
.btn.our-process__btn span::before {
  border-left: 13px solid var(--color-blue-dark);
}

.our-process__decor {
  position: absolute;
  width: 9.6527777778vw;
  max-width: 139px;
}
.our-process__decor--top-left {
  top: -1px;
  left: 0;
}
.our-process__decor--right-bottom {
  bottom: -1px;
  right: 0;
}

.our-process__slider {
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 1;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 250ms ease, visibility 250ms ease;
}
.our-process__slider.visible {
  opacity: 1;
  visibility: visible;
}
@media only screen and (max-width: 769px) {
  .our-process__slider {
    top: 50%;
    transform: translateY(-50%);
  }
}
.our-process__slider .swiper-slide {
  width: 278px;
}

.our-process__slide.orange .our-process__slide-num {
  color: var(--color-yellow-2);
}
.our-process__slide.orange .our-process__slide-num::after {
  background-color: var(--color-yellow-2);
}
.our-process__slide.green .our-process__slide-num {
  color: var(--color-green-2);
}
.our-process__slide.green .our-process__slide-num::after {
  background-color: var(--color-green-2);
}
.our-process__slide.blue .our-process__slide-num {
  color: var(--color-blue-2);
}
.our-process__slide.blue .our-process__slide-num::after {
  background-color: var(--color-blue-2);
}

.our-process__slide-num {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-bottom: 44px;
  font-size: 70px;
}
.our-process__slide-num::after {
  content: "";
  display: block;
  width: 23px;
  height: 23px;
  margin-top: 29px;
  border-radius: 50%;
  background-color: var(--color-white);
}

.our-process__slide-text {
  font-family: var(--font-main);
  font-size: 18px;
}

.btn.our-process__back-btn {
  margin: 20px auto 0;
}

.head-details .head__cont {
  margin-right: 105px;
}
.head-details .head__cont h1 {
  font-size: min(max(
            30px + 22 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 52px);
  line-height: min(max(
            35px + 41 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 76px);
  color: var(--color-white);
  font-weight: 600;
  padding-top: min(max(
            170px + 26 *
              (
                (100vw - 768px) / 672
              )
          , 170px), 196px);
  letter-spacing: -1px;
  max-width: 600px;
}

.details {
  margin: min(max(
            50px + 29 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 79px) 0 min(max(
            50px + 50 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 100px);
}
.details__inner {
  display: flex;
  justify-content: space-between;
}
.details__descr {
  max-width: 768px;
  margin-right: 50px;
}
.details__descr-text p {
  margin-bottom: min(max(
            15px + 16 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 31px);
}
.details__descr-text p:last-child {
  margin-bottom: 0;
}
.details__descr-btns {
  margin-top: min(max(
            20px + 19 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 39px);
  display: flex;
  flex-wrap: wrap;
}
.details__descr-btns a,
.details__descr-btns button {
  margin-top: 15px;
}
.details__descr-btns .btn {
  margin-right: min(max(
            15px + 34 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 49px);
}
.details__descr-soc {
  margin-top: 48px;
}
.details__info {
  position: relative;
  max-width: 450px;
  margin-top: 5px;
  right: min(max(
            0px + -7 *
              (
                (100vw - 768px) / 672
              )
          , -7px), 0px);
  flex-shrink: 0;
}
.details__info-card {
  max-width: 450px;
  margin-bottom: 44px;
}
.details__info-descr {
  background: var(--color-blue-dark);
  clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 40px, 100% 100%, 40px 100%, 0 calc(100% - 40px));
  padding: 38px 16px 38px;
}
@media only screen and (max-width: 651px) {
  .details__info-descr {
    height: max-content;
  }
}
.details__info-descr > * {
  color: white;
}
.details__info-descr > * > * {
  color: white;
}
.details__info-descr h4 {
  font-size: min(max(
            18px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 21px);
  font-weight: 400;
  margin-bottom: 8px;
}
.details__info-nums {
  margin-top: 19px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.details__info-nums p {
  margin-right: 13px;
  font-size: min(max(
            13px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 13px), 16px);
}
.details__info-nums p:last-child {
  margin-right: 0;
}
.details__boxes {
  margin-top: 51px;
}
.details__box-local {
  margin-top: 50px;
  max-width: 278px;
}
.details__local-img img {
  max-width: 278px;
  max-height: 148px;
  object-fit: cover;
}
.details__local-text {
  padding: 10px 15px;
}
.details__local-text h4 {
  text-align: center;
  font-size: min(max(
            21px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 21px), 32px);
  line-height: 1.4;
}
.details__local-text a {
  margin: 13px auto 0px;
}
.details__local-text .btn:last-child {
  margin-right: auto;
}

.jobs-details {
  margin: min(max(
            20px + 145 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 165px) 0 min(max(
            50px + 91 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 141px);
}
.jobs-details__box {
  margin-bottom: 37px;
  align-items: baseline;
}
.jobs-details__box h2 {
  width: 33.4%;
  letter-spacing: -1px;
}
.jobs-details .swiper-slide {
  opacity: 0.5;
}
.jobs-details .swiper-slide-active {
  opacity: 1;
}
.jobs-details .swiper-slide-next,
.jobs-details .swiper-slide-prev {
  opacity: 1;
}
.jobs-details .slider-control {
  margin-top: 51px;
}

.community {
  margin-left: min(max(
            0px + 64 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 64px);
  margin-bottom: 50px;
}
.community .title-box__cont {
  margin-top: 5px;
  padding-left: min(max(
            0px + 17 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 17px);
}

.community-signup {
  margin-top: min(max(
            50px + 54 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 104px);
  padding-bottom: min(max(
            35px + 19 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 54px) !important;
  margin-left: min(max(
            0px + 64 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 64px);
}
.community-signup .cont {
  max-width: 1160px !important;
  width: 80.5555555556% !important;
}
.community-signup__inner {
  display: flex;
  justify-content: space-between;
}
.community-signup__inner .signup-section__title {
  width: 44%;
}
.community-signup__form-inner {
  margin: 33px 45px 0 30px;
  width: 56%;
}

.head-resource {
  padding-bottom: min(max(
            30px + 113 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 143px);
}
.head-resource .head__cont {
  padding-top: min(max(
            170px + 92 *
              (
                (100vw - 768px) / 672
              )
          , 170px), 262px);
  display: flex;
}
.head-resource .head__cont h1 {
  width: 60%;
  padding-top: 0;
  font-size: min(max(
            22px + 12 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 34px);
  max-width: min(max(
            400px + 368 *
              (
                (100vw - 768px) / 672
              )
          , 400px), 768px);
  line-height: min(max(
            35px + 40 *
              (
                (100vw - 768px) / 672
              )
          , 35px), 75px);
  letter-spacing: 0px;
}
.head-resource__box {
  margin-top: 15px;
  margin-left: 20px;
}
.head-resource__box h5 {
  color: var(--color-green-2);
  font-size: min(max(
            18px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 21px);
  font-weight: 400;
}
.head-resource__box h5 + h5 {
  margin-top: 4px;
}
.head-resource__box p {
  font-size: min(max(
            14px + 2 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 16px);
  margin-top: min(max(
            10px + 16 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 26px);
  color: var(--color-white);
}
.head-resource__box a,
.head-resource__box button {
  margin-top: min(max(
            10px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 10px), 30px);
  color: var(--color-white);
}
.head-resource__box a span::before,
.head-resource__box button span::before {
  border-left: 13px solid var(--color-blue-dark);
}

.resource {
  margin-bottom: min(max(
            50px + 85 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 135px);
}
.resource__inner {
  display: flex;
  justify-content: space-between;
}
.resource__descr {
  max-width: 768px;
  margin-right: 50px;
  margin-top: min(max(
            -20px + -82 *
              (
                (100vw - 768px) / 672
              )
          , -102px), -20px);
}
.resource__descr-img {
  margin-bottom: min(max(
            30px + 14 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 44px);
}
.resource__descr-img img {
  max-width: 100%;
  height: auto;
}
.resource__descr-img p {
  margin-top: 8px;
  font-size: min(max(
            14px + 2 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 16px);
  color: var(--color-green-2);
}
.resource__descr-text.resource__descr-text-bottom {
  font-family: var(--font-main);
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
}
.resource__descr-text p {
  margin-bottom: min(max(
            15px + 17 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 32px);
}
.resource__descr-text p:last-child {
  margin-bottom: 0;
}
.resource__descr-quote {
  margin-top: min(max(
            30px + 54 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 84px);
  position: relative;
  padding-left: min(max(
            20px + 38 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 58px);
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  font-family: "Lexend";
  line-height: 1.7777777778em;
}
.resource__descr-quote::before {
  content: "“";
  position: absolute;
  font-size: min(max(
            50px + 50 *
              (
                (100vw - 768px) / 672
              )
          , 50px), 100px);
  line-height: 1;
  font-family: "Lexend";
  color: var(--color-red);
  top: min(max(
            0px + -20 *
              (
                (100vw - 768px) / 672
              )
          , -20px), 0px);
  left: min(max(
            -8px + 8 *
              (
                (100vw - 768px) / 672
              )
          , -8px), 0px);
}
.resource__descr-btns a {
  margin-top: min(max(
            40px + 63 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 103px);
}
.resource__info {
  margin-top: 343px;
  position: relative;
  max-width: 278px;
  right: -5px;
  grid-row-gap: min(max(
            24px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 24px), 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.resource__info-card {
  width: 278px;
}
.resource__box {
  margin-top: 96px;
}

.video-resource {
  margin-bottom: 140px;
}
.video-resource .title-box {
  margin-bottom: 33px;
}
.video-resource .title-box h2 {
  width: 100%;
}

.content-element.details__descr-text {
  font-size: min(max(
            14px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 14px), 18px);
  line-height: 1.7777777778em;
  font-family: var(--font-main);
  color: var(--color-blue-dark);
}
.content-element.details__descr-text p {
  word-break: break-word;
}

.resources-section {
  padding: 79px 0 min(max(
            100px + 95 *
              (
                (100vw - 768px) / 672
              )
          , 100px), 195px);
  min-height: 600px;
}
.resources-section .cont {
  width: 94.2%;
  max-width: 1356px;
  overflow: hidden;
}
.resources-section__top {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.resources-section__top.cont {
  overflow: visible;
}
.resources-section__top .beefup.job-board__filter-accordion {
  position: relative;
}
.resources-section__top .beefup__body {
  position: absolute;
  margin-top: 12px !important;
  width: 100%;
  border: 1px solid var(--color-blue-dark);
  padding: 8px !important;
  background: #fff;
  z-index: 10;
}
.resources-section__top .beefup__body a {
  margin-bottom: 4px;
}
.resources-section__top .beefup__body a:hover {
  text-decoration: underline;
}

.resources-cont {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: min(max(20px + 25 * (100vw - 768px) / 672, 20px), 45px);
}
@media only screen and (max-width: 769px) {
  .resources-cont {
    grid-template-columns: 1fr;
  }
}

.recources-section__title {
  margin-bottom: min(max(
            20px + 19 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 39px);
  text-align: center;
}

.resources-slider {
  width: 572px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .resources-slider {
    width: 100%;
  }
}

.resources-slider__card {
  margin: 0 8px;
}
.resources-slider__card:hover .btn.yellow {
  color: var(--color-yellow-2);
}
.resources-slider__card:hover .btn.blue {
  color: var(--color-blue);
}
.resources-slider__card:hover .btn.red {
  color: var(--color-red);
}

.resources-slider__card-img {
  width: 100%;
  height: 260px;
  object-fit: cover;
  margin-bottom: min(max(
            15px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 23px);
}
@media only screen and (max-width: 768px) {
  .resources-slider__card-img {
    height: auto;
  }
}

.resources-slider__card-type {
  margin-bottom: 1px;
  font-size: min(max(
            17px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 17px), 20px);
}
.resources-slider__card-type.yellow {
  color: var(--color-yellow);
}
.resources-slider__card-type.blue {
  color: var(--color-blue);
}
.resources-slider__card-type.green {
  color: var(--color-green);
}
.resources-slider__card-type.red {
  color: var(--color-red);
}

.resources-slider__card-title {
  max-width: 376px;
  margin-bottom: 8px;
  font-size: min(max(
            20px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 26px);
  line-height: 1.73;
}

.resources-pagination {
  display: flex;
  align-items: center;
  grid-column: 1/-1;
  width: fit-content;
  margin: 0 auto;
}

.resources-pagination-btn {
  margin-left: 14px;
  font-size: 10.5px;
  color: var(--color-blue-dark);
  transition: opacity ease 250ms;
  cursor: pointer;
}
.resources-pagination-btn.prev {
  margin-right: 14px;
  margin-left: 0;
}
.resources-pagination-btn:hover {
  opacity: 0.75;
}

.resources-pagination-pages {
  display: flex;
  align-items: center;
}
.resources-pagination-pages .active {
  display: flex;
  align-items: center;
  height: 13px;
  margin-right: 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  transform: translateY(0.5px);
}
.resources-pagination-pages .resources-pagination-bullet {
  width: 13px;
  height: 13px;
  border: 1px solid var(--color-blue-dark);
  border-radius: 50%;
  transition: transform ease 250ms;
  cursor: pointer;
}
.resources-pagination-pages .resources-pagination-bullet:not(:last-child) {
  margin-right: 7px;
}
.resources-pagination-pages .resources-pagination-bullet:hover {
  transform: scale(1.1);
}
.resources-pagination-pages .resources-pagination-bullet.active {
  background-color: var(--color-blue-dark);
}

.recources-section__decor {
  pointer-events: none;
  display: none;
  position: absolute;
  bottom: 150px;
  width: 15.4166666667vw;
  max-width: 222px;
}
.recources-section__decor--interim .recources-section__decor-img {
  transform: translateY(45%) scale(-1, 1);
}
.recources-section__decor--interview {
  right: 0;
}
.recources-section__decor--interview .recources-section__decor-img {
  transform: translateY(30%);
}

.signup-section {
  padding-bottom: 100px;
}
.signup-section .cont {
  width: 67%;
  max-width: 964px;
}

.signup-section__title {
  margin-bottom: min(max(
            20px + 13 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 33px);
  font-size: min(max(
            25px + 25 *
              (
                (100vw - 768px) / 672
              )
          , 25px), 50px);
}

.signup-section__form {
  display: flex;
  margin-bottom: 21px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-blue-dark);
}

.signup-section__input {
  margin-right: 10px;
  padding: 0;
  color: var(--color-blue-dark);
  caret-color: var(--color-blue-dark);
}
.signup-section__input::placeholder {
  color: var(--color-blue-dark);
}
.signup-section__input:focus {
  border-color: transparent;
}

.btn.signup-section__btn {
  white-space: nowrap;
}
@media only screen and (max-width: 551px) {
  .btn.signup-section__btn span {
    margin-left: 0;
  }
}

@media only screen and (max-width: 551px) {
  .signup-section__btn-text {
    display: none;
  }
}

.signup-section__form-descr {
  font-size: 12px;
  margin-left: auto;
  width: fit-content;
  transition: all 0.4s ease-in-out;
}
.signup-section__form-descr:hover {
  color: var(--color-red);
}

.signup-section__decor {
  position: absolute;
  bottom: -1px;
  width: 14.1666666667vw;
  max-width: 204px;
}

.interim-resources-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
@media only screen and (max-width: 1281px) {
  .interim-resources-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 769px) {
  .interim-resources-grid {
    grid-template-columns: 1fr;
  }
}

.resources__filters {
  padding-bottom: 0 !important;
}

.interim-resources-list {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  height: fit-content;
}
@media only screen and (max-width: 769px) {
  .interim-resources-list {
    grid-template-columns: 1fr;
  }
}

.resources-list-item img {
  margin-bottom: 16px;
}
@media only screen and (max-width: 551px) {
  .resources-list-item {
    margin-bottom: 50px;
  }
}

.resources-list-item__title {
  line-height: 1.4em;
}
.resources-list-item__title:not(:last-child) {
  margin-bottom: 6px;
}

.resources-list-item__descr {
  margin-bottom: 10px;
}

.resources-list-item {
  display: flex;
  flex-direction: column;
}

.resources-list-item__text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.resources-list-item__author {
  margin-top: auto;
  margin-bottom: 4px;
}

.job-board {
  padding: min(max(
            64px + 39 *
              (
                (100vw - 768px) / 672
              )
          , 64px), 103px) 0 64px;
}

.job-board__cont {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: min(max(
            20px + 25 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 45px);
}
@media only screen and (max-width: 601px) {
  .job-board__cont {
    grid-template-columns: 1fr;
  }
}

.job-board__filters {
  font-family: Lexend;
  font-size: 18px;
  padding-bottom: 500px;
}
.job-board__filters > *:not(:last-child) {
  margin-bottom: 16px;
}
.job-board__filters input {
  color: var(--color-blue-dark);
}
.job-board__filters input::placeholder {
  color: var(--color-blue-dark);
}
@media only screen and (max-width: 1600px) {
  .job-board__filters {
    padding-bottom: 400px;
  }
}
@media only screen and (max-width: 1281px) {
  .job-board__filters {
    padding-bottom: 350px;
  }
}

.beefup.job-board__filter-accordion {
  margin: 0;
  border: none;
  border-radius: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-blue-dark);
}
.beefup.job-board__filter-accordion:not(:last-child) {
  margin-bottom: 16px;
}
.beefup.job-board__filter-accordion .beefup__head {
  padding: 0;
}
.beefup.job-board__filter-accordion .beefup__head::after {
  display: none;
}
.beefup.job-board__filter-accordion .beefup__head i {
  display: inline-block;
  margin-left: 14px;
  font-size: 10px;
  color: var(--color-green);
  transition: transform ease 250ms;
}
.beefup.job-board__filter-accordion .beefup__head button {
  width: 100%;
  margin: 0;
}
.beefup.job-board__filter-accordion.is-open .beefup__head i {
  transform: rotate(90deg);
}
.beefup.job-board__filter-accordion .beefup__body {
  padding: 10px 0 0;
  width: 155px;
  right: 0;
}

.job-board__filter-label {
  position: relative;
  display: flex;
  cursor: pointer;
}
.job-board__filter-label:not(:last-child) {
  margin-bottom: 10px;
}
.job-board__filter-label.radio .job-board__filter-checkbox:checked ~ .job-board__filter-style::before {
  content: "";
  background-color: var(--color-blue);
}

.job-board__filter-checkbox {
  width: 20px;
  margin-right: 10px;
}

.job-board__filter-input {
  padding: 0 0 12px;
  border-bottom: 1px solid var(--color-blue-dark);
}

.job-board__filter-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.job-board__filter-checkbox ~ .job-board__filter-style::before .job-board__filter-checkbox {
  opacity: 0;
}

.job-board__filter-checkbox ~ .job-board__filter-style::before {
  content: "✓";
  position: absolute;
  text-align: center;
  color: transparent;
  top: 4px;
  line-height: 1em;
  width: 1em;
  height: 1em;
  border: 2px inset var(--color-blue-dark);
  border-radius: 0.25em;
  margin-right: 10px;
  display: inline-block;
}

.job-board__filter-checkbox:checked ~ .job-board__filter-style::before {
  color: var(--color-blue);
  border: 2px solid var(--color-blue);
}

.job-board__filter-label-text {
  padding-left: 25px;
}

.btn.job-board__filter-cv-btn {
  margin-top: 29px;
  background: var(--color-yellow);
  padding: 10px;
  font-size: min(max(
            16px + 2 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 18px);
  width: 100%;
  justify-content: space-between;
}
.btn.job-board__filter-cv-btn:hover {
  background: var(--color-blue-dark);
}
.btn.job-board__filter-cv-btn.clear-btn {
  background: var(--color-blue-dark);
  color: var(--white);
}
.btn.job-board__filter-cv-btn.clear-btn:hover {
  background: var(--color-green);
  color: var(--color-blue-dark);
}
.btn.job-board__filter-cv-btn.clear-btn:hover span {
  background: var(--color-blue-dark);
}

.job-board__results {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-board__results-grid {
  display: grid;
  grid-template-columns: repeat(3, 313px);
  gap: 41px 18px;
  margin-bottom: 75px;
}
@media only screen and (max-width: 1281px) {
  .job-board__results-grid {
    grid-template-columns: repeat(2, 313px);
  }
}
@media only screen and (max-width: 901px) {
  .job-board__results-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 769px) {
  .job-board__results-grid {
    grid-template-columns: 313px;
  }
}

.job-board__card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 380px;
  padding: 38px 21px 102px;
  color: var(--color-white);
  background-color: var(--color-blue-dark);
  font-family: var(--font-main);
}

.job-board__card-title {
  color: var(--color-white);
  margin-bottom: 21px;
  font-size: min(max(
            20px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 20px), 25px);
  font-weight: 500;
}

.job-board__card-place {
  margin-bottom: 13px;
  font-size: 17px;
}

.job-board__card-info {
  margin-bottom: 38px;
  font-size: 16px;
  font-weight: 300;
}

.job-board__card-btns-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
}

.btn.job-board__card-btn {
  margin-right: 10px;
  color: var(--color-white);
  font-size: 17px;
  letter-spacing: -0.34px;
  white-space: nowrap;
}

.job-board__card-decor {
  position: absolute;
  left: -0.5px;
  bottom: -1px;
  overflow: hidden;
  display: flex;
  width: calc(100% + 0.5px);
}
.job-board__card-decor--top {
  width: auto;
  left: unset;
  bottom: unset;
  top: -1px;
  right: 0;
  background-image: none;
}
.job-board__card-decor--top .job-board__card-decor-img:last-child {
  transform: none;
  margin-left: 0;
}

.job-board__card-decor-img:last-child {
  transform: scale(-1, 1);
  margin-left: -1px;
}

.job-board__results-pagination {
  display: flex;
  align-items: center;
  margin-bottom: min(max(
            40px + 109 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 149px);
}

.job-board__results-pagination-btn {
  font-size: 10.5px;
  transition: opacity ease 250ms;
}
.job-board__results-pagination-btn:hover {
  opacity: 0.75;
}

.job-board__results-pagination-bullets {
  display: flex;
  align-items: center;
  margin-left: 14px;
  margin-right: 14px;
}

.job-board__results-pagination-bullet {
  width: 13px;
  height: 13px;
  border: 1px solid var(--color-blue-dark);
  border-radius: 50%;
  transition: transform ease 250ms;
}
.job-board__results-pagination-bullet:hover {
  transform: scale(1.1);
}
.job-board__results-pagination-bullet:not(:last-child) {
  margin-right: 11px;
}
.job-board__results-pagination-bullet.active {
  background-color: var(--color-blue-dark);
}

.job-board__bottom-wrap {
  position: relative;
  width: 100%;
  max-width: 768px;
  align-self: flex-end;
}

.job-board__info-block {
  margin-bottom: min(max(
            40px + 73 *
              (
                (100vw - 768px) / 672
              )
          , 40px), 113px);
}

.job-board__info-title {
  margin-bottom: 11px;
}

.job-board__info-descr {
  margin-bottom: 28px;
  font-family: var(--font-main);
  font-size: 18px;
}

.job-board__bottom-decor {
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 64px;
  transform: scale(-1, 1);
}
@media only screen and (max-width: 601px) {
  .job-board__bottom-decor {
    display: none;
  }
}

.job-board__bottom-decor-img {
  width: 19.5138888889vw;
  max-width: 281px;
  min-width: 150px;
}

.header {
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-height);
}
.header.scrolled {
  height: var(--header-height-scrolled);
  background-color: var(--color-blue-dark);
  box-shadow: 0 5px 10px rgba(1, 1, 61, 0.2);
}
.header.scrolled .header__list {
  padding: 0;
}
.header.scrolled .header__links {
  background-color: var(--color-blue-dark);
  box-shadow: 0 5px 10px rgba(1, 1, 61, 0.2);
}
.header.scrolled + .header__layer {
  top: calc(var(--header-height-scrolled));
  height: calc(100% - var(--header-height-scrolled));
}
.header .cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  position: relative;
  display: inline-block;
  width: min(max(
            146px + 85 *
              (
                (100vw - 768px) / 672
              )
          , 146px), 231px);
}
@media only screen and (max-width: 360px) {
  .header__logo {
    width: 125px;
  }
}
.header__logo:hover img {
  transform: scale(1.02);
}
.header__logo img {
  width: 100%;
  height: auto;
  backface-visibility: hidden;
  will-change: transform;
  transition: all 0.7s;
}
.header__menu {
  display: flex;
  align-items: center;
  height: 100%;
}
.header__list {
  position: relative;
  display: flex;
  height: 100%;
  padding-top: min(max(
            0px + 22 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 22px);
}
.header:not(.header.scrolled):not(.header:not(.header.scrolled).open-menu) .header__list {
  margin-right: 24px;
}
.header:not(.header.scrolled) .header__list-item:not(.header__list-item.active):hover .header__links {
  display: block !important;
  opacity: 1;
  visibility: visible;
}
.header__list-item {
  height: 100%;
  display: inline-block;
  margin-right: min(max(
            30px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 30px), 40px);
}
.header__list-item:last-child {
  margin-right: 0;
}
.header__list-item.active span {
  color: var(--color-yellow-2);
}
.header__list-item.active span::before {
  transform: rotate(90deg);
}
.header__list-item.active .header__links {
  opacity: 1;
  visibility: visible;
}
.header__list-item .upload-icon {
  margin-left: 7px;
  color: var(--color-yellow);
  font-size: 14px;
}
@media only screen and (max-width: 1281px) {
  .header__list-item .upload-icon {
    font-size: 22px;
  }
}
.header__list-item .header__list-link {
  font-size: 17px;
  font-weight: 700;
  color: var(--color-white);
}
.header__list-item .header__list-link:hover {
  color: var(--color-yellow-2);
}
@media only screen and (max-width: 1281px) {
  .header__list-item .header__list-link {
    font-size: min(max(
            22px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 33px);
    line-height: 1em;
    margin-bottom: 17px;
  }
}
.header__list-item div {
  position: relative;
  display: flex;
}
.header__list-item span {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 15px;
  cursor: pointer;
}
.header__list-item span a {
  font-size: 17px;
  font-weight: 700;
  color: var(--color-white);
}
.header__list-item span a:hover {
  color: var(--color-yellow-2);
}
.header__list-item span:before {
  position: absolute;
  right: 0;
  content: "";
  border-left: 8px solid var(--color-yellow-2);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}
.header__links {
  position: absolute;
  top: 100%;
  left: -8px;
  width: max-content;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  padding: 8px;
}
.header__links li {
  width: 100%;
  margin-bottom: 8px;
}
.header__links li:last-child {
  margin-bottom: 0;
}
.header__links li a {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-white);
}
.header__links li a:hover {
  color: var(--color-yellow-2);
}
.header__burger {
  position: relative;
  display: none;
  align-items: center;
  width: 41px;
  height: 19px;
}
.header__burger::before, .header__burger::after,
.header__burger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-white);
}
.header__burger::before {
  content: "";
  top: 0;
  will-change: transform;
}
.header__burger::after {
  content: "";
  bottom: 0;
  will-change: transform;
}

.header__layer {
  position: fixed;
  z-index: 11;
  top: var(--header-height);
  left: 0;
  display: none;
  width: 100%;
  height: calc(100% - var(--header-height));
  background-color: rgba(1, 1, 61, 0.5);
  opacity: 0;
  visibility: hidden;
}

.footer {
  position: relative;
  width: 100%;
  background-color: var(--color-blue-dark);
  padding: min(max(
            54px + 34 *
              (
                (100vw - 768px) / 672
              )
          , 54px), 88px) 0 min(max(
            65px + -1 *
              (
                (100vw - 768px) / 672
              )
          , 64px), 65px) 0;
}
.footer .cont {
  display: flex;
  flex-wrap: wrap;
}
.footer__block {
  position: relative;
  width: 50%;
}
.footer__logo {
  width: fit-content;
  margin-bottom: min(max(
            21px + 19 *
              (
                (100vw - 768px) / 672
              )
          , 21px), 40px);
}
.footer__logo:hover img {
  transform: scale(1.05);
}
.footer__logo img {
  width: min(max(
            146px + 148 *
              (
                (100vw - 768px) / 672
              )
          , 146px), 294px);
  height: auto;
  will-change: transform;
}
.footer__wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.footer__left {
  width: 50%;
}
.footer__links {
  width: fit-content;
  margin-bottom: 29px;
}
.footer__links a {
  width: fit-content;
  font-size: 18px;
  line-height: 1.3333333333em;
  color: var(--color-white);
  margin-bottom: 24px;
}
.footer__links a:last-child {
  margin-bottom: 0;
}
.footer__links a:hover {
  color: var(--color-yellow-2);
}
.footer__text {
  width: 100%;
}
.footer__text > a {
  width: fit-content;
}
.footer__text > a:hover p {
  color: var(--color-yellow-2);
}
.footer__text p {
  width: fit-content;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6em;
  color: var(--color-white);
}
.footer__right {
  position: relative;
  top: -3px;
  width: 50%;
  padding-left: 4px;
}
.footer__menu {
  width: 100%;
  margin-bottom: 30px;
}
.footer__menu li {
  width: fit-content;
  margin-bottom: 9px;
}
.footer__menu li:last-child {
  margin-bottom: 0;
}
.footer__menu a {
  font-size: min(max(
            18px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 23px);
  font-weight: 300;
  color: var(--color-white);
}
.footer__menu a:hover {
  color: var(--color-yellow-2);
}
.footer__socials {
  display: flex;
  align-items: center;
  width: 100%;
}
.footer__socials li {
  display: inline-block;
  margin-right: min(max(
            22px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 26px);
}
.footer__socials li:last-child {
  margin-right: 0;
}
.footer__socials a {
  display: inline-block;
  will-change: transform;
  transition: all 0.3s;
}
.footer__socials a i {
  display: flex;
  font-size: 20px;
  color: var(--color-yellow-2);
  transition: all 0.3s;
}
.footer__socials a:hover i::before {
  transition: all 0.3s;
  color: var(--color-red);
}
.footer__socials a:active {
  transform: translateY(5px);
}
.footer__policy {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: min(max(
            42px + 49 *
              (
                (100vw - 768px) / 672
              )
          , 42px), 91px);
  margin-right: -10px;
  margin-bottom: -10px;
}
.footer__policy div,
.footer__policy a {
  position: relative;
  display: inline-block;
  width: fit-content;
  font-size: 14px;
  line-height: 2.1666666667em;
  color: var(--color-white);
  margin-right: 10px;
  margin-bottom: 10px;
}
.footer__policy div {
  left: -4px;
  margin-right: 62px;
}
.footer__policy .footer__policy-link-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
  margin-right: -10px;
}
.footer__policy a {
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 13px;
  padding-left: 3px;
}
.footer__policy a:hover {
  color: var(--color-yellow-2);
}
.footer__form {
  width: 50%;
  padding: min(max(
            0px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 3px) 0 0 8px;
}
.footer__form div,
.footer__form h2 {
  color: var(--color-white);
}
.footer__form div {
  width: 100%;
  font-size: min(max(
            24px + 20 *
              (
                (100vw - 768px) / 672
              )
          , 24px), 44px);
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: -0.45px;
}
.footer__form h2 {
  font-weight: 600;
}
.footer__form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: min(max(
            53px + -25 *
              (
                (100vw - 768px) / 672
              )
          , 28px), 53px);
}
.footer__form form input,
.footer__form form textarea {
  width: 49%;
  height: 36px;
  font-size: 18px;
  line-height: 1.3333333333em;
  color: var(--color-white);
  border: none;
  border-bottom: 2px solid var(--color-white);
  background-color: transparent;
  padding: 0 0 12px 0;
  margin-top: 30px;
}
.footer__form form input:-webkit-autofill, .footer__form form input:-webkit-autofill:hover, .footer__form form input:-webkit-autofill:focus, .footer__form form input:-webkit-autofill:active,
.footer__form form textarea:-webkit-autofill,
.footer__form form textarea:-webkit-autofill:hover,
.footer__form form textarea:-webkit-autofill:focus,
.footer__form form textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
.footer__form form input:nth-child(1), .footer__form form input:nth-child(2),
.footer__form form textarea:nth-child(1),
.footer__form form textarea:nth-child(2) {
  margin-top: 0;
}
.footer__form form input:focus,
.footer__form form textarea:focus {
  border-color: var(--color-yellow-2);
}
.footer__form form input:focus::placeholder,
.footer__form form textarea:focus::placeholder {
  color: var(--color-yellow-2);
}
.footer__form form textarea {
  width: 100%;
  height: min(max(
            141px + -57 *
              (
                (100vw - 768px) / 672
              )
          , 84px), 141px);
}
.footer__form form button.size-2 {
  font-size: min(max(
            12px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 18px);
  font-weight: 600;
  color: var(--color-white);
  margin: min(max(
            26px + 8 *
              (
                (100vw - 768px) / 672
              )
          , 26px), 34px) 0 0 auto;
}
.footer__form form button.size-2 span {
  margin-left: min(max(
            12px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 15px);
}
.footer__credits {
  max-width: 150px;
  width: 100%;
  margin: 50px auto 0;
  height: auto;
  transition: transform 300ms ease;
}
.footer__credits:hover {
  transform: scale(1.1);
}

.header.scrolled :root {
  --header-height: 140px;
  --header-height-scrolled: 112px;
}
.header.scrolled .header__socials {
  padding-top: 0;
}
.header.scrolled + .header__layer {
  display: block;
}
.header.scrolled .header__menu {
  margin-left: auto;
  margin-right: 20px;
}
.header.scrolled .header__list {
  top: var(--header-height-scrolled);
  height: calc(100% - var(--header-height-scrolled));
  padding: 59px 20px 59px 50px;
  margin-right: 0;
  width: 580px;
}
@media only screen and (max-width: 769px) {
  .header.scrolled .header__list {
    width: 100%;
  }
}
.header.scrolled.open-menu .header__list {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  display: flex;
  flex-direction: column;
  margin-right: 0;
  align-items: flex-start;
}
.header.scrolled.open-menu .header__burger::before {
  top: 9px;
  transform: rotate(45deg);
}
.header.scrolled.open-menu .header__burger::after {
  bottom: 9px;
  transform: rotate(-45deg);
}
.header.scrolled.open-menu .header__burger span {
  opacity: 0;
  visibility: hidden;
  left: 20px;
}
.header.scrolled.open-menu + .header__layer {
  opacity: 1;
  visibility: visible;
}
.header.scrolled .header__list {
  position: fixed;
  right: 0;
  display: block;
  padding: 59px 20px 59px 50px;
  background-color: var(--color-blue-dark);
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  will-change: transform;
  transform: translateX(580px);
}
.header.scrolled .header__list-item {
  width: fit-content;
  height: auto;
  margin-right: 0;
  margin-bottom: 24px;
}
.header.scrolled .header__list-item:last-child {
  margin-bottom: 0;
}
.header.scrolled .header__list-item div {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.header.scrolled .header__list-item:not(.header.scrolled .header__list-item.active) span:hover::before {
  transform: scale(1.5);
}
.header.scrolled .header__list-item span {
  padding-right: 28px;
  margin-bottom: 17px;
}
.header.scrolled .header__list-item span a {
  font-size: min(max(
            22px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 33px);
  line-height: 1em;
}
.header.scrolled .header__list-item span::before {
  border-left-width: 12px;
  border-top-width: 7px;
  border-bottom-width: 7px;
  margin-top: 5px;
  transition: transform ease 250ms;
}
.header.scrolled .header__list-item span::before:hover {
  transform: scale(1.1);
}
.header.scrolled .header__links {
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  display: none;
  padding: 0;
  opacity: 1;
  visibility: visible;
  transition: none;
}
.header.scrolled .header__links li {
  margin-bottom: 9px;
}
.header.scrolled .header__links li:last-child {
  margin-bottom: 0;
}
.header.scrolled .header__links li a {
  font-size: min(max(
            22px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 33px);
  font-weight: 400;
}
.header.scrolled .header__list-link {
  font-size: min(max(
            22px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 33px);
  line-height: 1em;
  margin-bottom: 17px;
}
.header.scrolled .upload-icon {
  font-size: 22px;
}
.header.scrolled .header__burger {
  display: flex;
}

.header__socials {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: min(max(
            0px + 22 *
              (
                (100vw - 768px) / 672
              )
          , 0px), 22px);
}
@media only screen and (max-width: 1281px) {
  .header__socials {
    padding-top: 0;
  }
}
.header__socials li {
  font-size: 20px;
}
.header__socials li:not(:last-child) {
  margin-right: 14px;
}
@media only screen and (max-width: 551px) {
  .header__socials li {
    font-size: 16px;
  }
}
.header__socials a:hover i::before {
  color: var(--color-green);
}
.header__socials i::before {
  color: var(--color-white);
  transition: color 250ms ease;
}

.btn-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.btn {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: min(max(
            16px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 22px);
  font-weight: 600;
  line-height: 1.4090909091em;
  margin-right: min(max(
            22px + 13 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 35px);
  cursor: pointer;
}
.btn:last-child {
  margin-right: 0;
}
.btn.blue:hover {
  color: var(--color-blue);
}
.btn.blue span {
  background-color: var(--color-blue);
}
.btn.yellow:hover {
  color: var(--color-yellow-2);
}
.btn.yellow span {
  background-color: var(--color-yellow-2);
}
.btn.red:hover {
  color: var(--color-red);
}
.btn.red span {
  background-color: var(--color-red);
}
.btn.green:hover {
  color: var(--color-green-2);
}
.btn.green span {
  background-color: var(--color-green-2);
}
.btn.size-2 {
  font-size: min(max(
            12px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 17px);
  font-weight: 700;
  line-height: 1.2941176471em;
}
.btn.size-2 span {
  width: min(max(
            18px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 24px);
  height: min(max(
            18px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 18px), 24px);
  margin-left: min(max(
            8px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 8px), 12px);
}
.btn.size-2 span::before {
  border-left-width: min(max(
            7px + 1 *
              (
                (100vw - 768px) / 672
              )
          , 7px), 8px);
  border-top-width: min(max(
            4px + 1 *
              (
                (100vw - 768px) / 672
              )
          , 4px), 5px);
  border-bottom-width: min(max(
            4px + 1 *
              (
                (100vw - 768px) / 672
              )
          , 4px), 5px);
  margin-left: 1px;
}
.btn span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: min(max(
            25px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 25px), 32px);
  height: min(max(
            25px + 7 *
              (
                (100vw - 768px) / 672
              )
          , 25px), 32px);
  background-color: var(--color-blue-dark);
  margin-left: min(max(
            24px + -12 *
              (
                (100vw - 768px) / 672
              )
          , 12px), 24px);
}
.btn span::before {
  position: absolute;
  content: "";
  display: inline-block;
  border-left: 13px solid var(--color-white);
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  margin-left: 2px;
}
.btn span.icon-dark::before {
  border-left-color: var(--color-blue-dark);
}
.btn.invert {
  color: var(--color-white);
}
.btn.invert span ::before {
  border-left: 13px solid var(--color-blue-dark);
}

.swiper-button-lock {
  display: none;
}

.input-wrap p {
  padding-bottom: 5px;
}
.input-wrap input,
.input-wrap textarea {
  width: 100%;
}

input,
textarea {
  position: relative;
  border-radius: 0;
  padding: 5px 10px 15px;
  width: 100%;
  resize: none;
  background-color: transparent;
  border-bottom: 2px solid var(--white);
  color: var(--white);
  transition: 0.3s ease-in-out all;
  font-family: var(--font-main);
  font-size: 18px;
}
input::placeholder,
textarea::placeholder {
  color: var(--white);
}
input:focus,
textarea:focus {
  border-color: var(--primary);
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #49495f !important;
}

textarea {
  height: 100px;
}

.select2-results__option {
  font-family: var(--font-main);
  background-color: var(--color-white);
  color: var(--color-blue-dark) !important;
}
.select2-results__option:hover {
  background-color: var(--color-yellow-2) !important;
  color: var(--color-blue-dark) !important;
}

.select2-container--default .select2-results__option--selected {
  background: var(--blue);
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background: var(--blue);
  color: var(--white);
}

.select2-container--default .select2-results > .select2-results__options {
  background: var(--black);
  color: var(--white);
  border: none;
}

.select2-container--open .select2-dropdown--below {
  border: none;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  border: 2px solid var(--color-blue-2);
  border-top: 0;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: var(--white);
}

.signup-section__form .select2-container {
  display: inline-flex;
  align-items: center;
  max-width: 200px;
  margin-right: 20px;
}
.signup-section__form .select2-container .selection {
  width: 100%;
}
.signup-section__form .select2-container--default .select2-selection--single .select2-selection__arrow::before {
  display: inline-block;
  transition: transform 250ms ease;
}
.signup-section__form .select2-container--open .select2-selection__arrow::before {
  transform: rotate(90deg);
}
.signup-section__form .select2-selection--single {
  padding: 0 !important;
}
.signup-section__form .select2-selection__rendered {
  color: var(--color-blue-dark) !important;
}
.signup-section__form .select2-selection__arrow {
  top: 0 !important;
}

form .select2-container {
  width: 100% !important;
}
form .select2-container--default .select2-selection--single {
  background: transparent;
  border: none;
  border-bottom: solid 2px var(--white);
  border-radius: 0;
  height: initial;
  color: var(--color-blue-dark);
  border-radius: 0;
  font-family: var(--font-main);
  font-size: 18px;
  padding: 0;
}
form .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: var(--color-blue-dark);
  line-height: initial;
  padding-left: 0;
}
form .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: var(--color-blue-dark);
}
form .select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 16px;
  height: 100%;
  top: 8px;
}
form .select2-container--default .select2-selection--single .select2-selection__arrow::before {
  font-family: "icomoon";
  content: "\e905";
  font-size: 12px;
  color: #00a217;
}
form .select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}
form.dark .select2-container:not(:last-child) {
  margin-right: 16px;
}
@media only screen and (max-width: 961px) {
  form.dark .select2-container:not(:last-child) {
    margin-right: 0;
  }
}
form.dark .select2-container--default .select2-selection--single {
  color: var(--white);
}
form.dark .select2-container--default .select2-selection--single .select2-selection__placeholder,
form.dark .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: var(--white);
}

.popup-dropdown {
  z-index: 9999999;
}

.checkbox:not(:last-child) {
  margin-bottom: 5px;
}
.checkbox span {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: 18px;
  position: relative;
}
.checkbox span::before {
  box-sizing: border-box;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--white);
  margin-right: 4px;
}
.checkbox span::after {
  content: "";
  position: absolute;
  left: 4px;
  width: 5px;
  height: 12px;
  opacity: 0;
  transform: rotate(45deg) scale(0);
  border-right: 2px solid var(--black);
  border-bottom: 2px solid var(--black);
  transition: all 0.3s ease;
  transition-delay: 0.15s;
}
.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox input:checked + span::before {
  background-color: var(--white);
  animation: jelly 0.6s ease;
}
.checkbox input:checked + span::after {
  opacity: 1;
  transform: rotate(45deg) scale(1);
}

@keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
.slider-custom .sc__slider {
  margin-bottom: min(max(
            9px + 6 *
              (
                (100vw - 768px) / 672
              )
          , 9px), 15px);
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  background: var(--white); /* Grey background */
  outline: none; /* Remove outline */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border: none;
  height: 1px; /* Specified height */
  padding: 0;
}
.slider-custom .sc__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: min(max(
            15px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 20px);
  height: min(max(
            15px + 5 *
              (
                (100vw - 768px) / 672
              )
          , 15px), 20px);
  background: var(--white);
  cursor: pointer;
  border-radius: 50%;
}
.slider-custom .sc__slider::-moz-range-thumb {
  width: 9px;
  height: 9px;
  background: var(--white);
  cursor: pointer;
}
@media only screen and (max-width: 1600px) {
  .career .career-dec img {
    max-width: 160px;
  }
  .job-board__bottom-decor-img {
    width: 12.513889vw;
  }
}
@media only screen and (max-width: 1480px) {
  .career .career-dec img {
    max-width: 120px;
  }
}
@media only screen and (max-width: 1325px) {
  .video-sec__cont {
    padding-right: 170px;
  }
}
@media only screen and (max-width: 1281px) {
  :root {
    --header-height: 140px;
  }
  .header.scrolled .header__list {
    top: var(--header-height-scrolled);
    height: calc(100% - var(--header-height-scrolled));
    padding: 59px 20px 59px 50px;
  }
  .header.open-menu .header__list {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .header.open-menu .header__burger::before {
    top: 9px;
    transform: rotate(45deg);
  }
  .header.open-menu .header__burger::after {
    bottom: 9px;
    transform: rotate(-45deg);
  }
  .header.open-menu .header__burger span {
    opacity: 0;
    visibility: hidden;
    left: 20px;
  }
  .header.open-menu + .header__layer {
    opacity: 1;
    visibility: visible;
  }
  .header .header__menu {
    margin-left: auto;
    margin-right: 20px;
  }
  .header__list {
    position: fixed;
    top: var(--header-height-scrolled);
    right: 0;
    display: block;
    width: 580px;
    height: calc(100% - var(--header-height-scrolled));
    padding: 59px 20px 59px 50px;
    background-color: var(--color-blue-dark);
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    will-change: transform;
    transform: translateX(580px);
  }
  .header__list-item {
    width: fit-content;
    height: auto;
    margin-right: 0;
    margin-bottom: 24px;
  }
  .header__list-item:last-child {
    margin-bottom: 0;
  }
  .header__list-item:not(.header__list-item.active) span:hover::before {
    transform: scale(1.5);
  }
  .header__list-item div {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .header__list-item span {
    padding-right: 28px;
    margin-bottom: 17px;
  }
  .header__list-item span a {
    font-size: min(max(
            22px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 33px);
    line-height: 1em;
  }
  .header__list-item span::before {
    border-left-width: 12px;
    border-top-width: 7px;
    border-bottom-width: 7px;
    margin-top: 5px;
  }
  .header__links {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    display: none;
    padding: 0;
    opacity: 1;
    visibility: visible;
    transition: none;
  }
  .header__links li {
    margin-bottom: 9px;
  }
  .header__links li:last-child {
    margin-bottom: 0;
  }
  .header__links li a {
    font-size: min(max(
            22px + 11 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 33px);
    font-weight: 400;
  }
  .header__burger {
    display: flex;
  }
  .header__layer {
    display: block;
  }
  .banner .decor img {
    transform: scale(0.8);
  }
  .banner__cont {
    max-width: 740px;
  }
  .banner__cont .btn-block {
    flex-direction: column;
  }
  .banner__cont .btn {
    width: fit-content;
    margin-right: 0;
    margin-bottom: min(max(
            16px + 4 *
              (
                (100vw - 768px) / 672
              )
          , 16px), 20px);
  }
  .banner__cont .btn:last-child {
    margin-bottom: 0;
  }
  .tab .decor img {
    transform: scale(0.8);
  }
  .cards .decor img {
    transform: scale(0.8);
  }
  .say .decor img {
    transform: scale(0.8);
  }
  .video .decor img {
    transform: scale(0.8);
  }
  .video__head {
    max-width: 650px;
  }
  .footer__block {
    width: 54%;
  }
  .footer__form {
    width: 46%;
  }
  .career .career-dec img {
    max-width: 55px;
  }
  .show-team .show__title {
    max-width: unset;
  }
}
@media only screen and (max-width: 1200px) {
  .tab__block {
    padding-left: 50px;
  }
  .values__block {
    padding-left: 50px;
  }
  .video-sec__cont {
    padding-right: 140px;
  }
  .tab__swiper {
    max-width: 640px;
  }
}
@media only screen and (max-width: 1150px) {
  .member__inner {
    flex-direction: column-reverse;
  }
  .member__info {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    max-width: 100%;
    width: 100%;
    right: 0px;
    top: 0px;
    margin-bottom: 30px;
  }
  .member__text-block {
    max-width: 100%;
  }
  .member__photo {
    max-width: 300px;
    margin-right: 25px;
  }
}
@media only screen and (max-width: 1100px) {
  .tab__block {
    padding-left: 65px;
  }
  .job-board__filters {
    padding-bottom: 240px;
  }
  .job-board__bottom-decor-img {
    width: 100%;
    max-width: 120px;
    min-width: 120px;
  }
  .tab__swiper {
    padding-right: 40px;
  }
}
@media only screen and (max-width: 1025px) {
  .banner .decor img {
    transform: scale(0.7);
  }
  .banner__cont {
    max-width: 550px;
  }
  .tab .decor img {
    transform: scale(0.7);
  }
  .tab__swiper {
    max-width: 620px;
  }
  .cards .decor img {
    transform: scale(0.7);
  }
  .say .decor img {
    transform: scale(0.7);
  }
  .say__swiper {
    max-width: 700px;
  }
  .jobs__card {
    clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 81%);
  }
  .jobs__card::before {
    clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 81%);
  }
  .video .decor img {
    transform: scale(0.7);
  }
  .video__head {
    max-width: 580px;
  }
  .footer__block {
    width: 45%;
  }
  .footer__form {
    width: 55%;
  }
  .footer__left, .footer__right {
    width: 100%;
  }
  .footer__left {
    margin-bottom: 31px;
  }
  .footer__policy a {
    padding: 0;
  }
}
@media only screen and (max-width: 960px) {
  .head-team__form {
    flex-direction: column;
    max-width: 350px;
  }
  .head-team__form input {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .head-team__form button,
.head-team__form a {
    margin-top: 30px;
  }
  .head-team__form .select2-container--default .select2-selection--single .select2-selection__rendered {
    max-width: 250px;
  }
  .head-team__form .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 0px;
  }
}
@media only screen and (max-width: 901px) {
  .banner .decor img {
    transform: scale(0.6);
  }
  .banner__cont {
    max-width: 475px;
  }
  .tab .decor img {
    transform: scale(0.6);
  }
  .tab__swiper {
    max-width: 450px;
  }
  .cards .decor img {
    transform: scale(0.6);
  }
  .say .decor img {
    transform: scale(0.6);
  }
  .say__swiper {
    max-width: 600px;
  }
  .jobs__card {
    clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 82%);
  }
  .jobs__card::before {
    clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 82%);
  }
  .video .decor img {
    transform: scale(0.6);
  }
  .video__head {
    max-width: 500px;
  }
  .jobs-member__cont .btn-block {
    justify-content: flex-start;
    margin-bottom: 0px;
  }
  .sol-career-revers .career__inner {
    flex-direction: column;
  }
  .sol-career-revers .career__text {
    margin-left: 0px;
    margin-top: 0px;
  }
  .video-sec__cont {
    padding-right: 170px;
  }
}
@media only screen and (max-width: 769px) {
  :root {
    --header-height: 78px;
    --header-height-scrolled: 78px;
  }
  .tab__swiper {
    padding-right: 0;
  }
  .header__list {
    width: 100%;
    transform: translateX(100%);
  }
  .banner .decor img {
    transform: scale(0.5);
  }
  .banner__cont {
    max-width: 390px;
  }
  .tab {
    padding-top: 36px;
    padding-bottom: 30px;
  }
  .tab .decor, .tab__nav {
    display: none;
  }
  .tab__swiper {
    max-width: 100%;
  }
  .tab__item {
    display: block;
  }
  .tab__item h2,
.tab__item .tab__head {
    display: block;
  }
  .tab__item .btn-block {
    padding-left: 0;
  }
  .say .decor {
    display: none;
  }
  .say__title {
    font-weight: 600;
  }
  .say__swiper {
    max-width: 100%;
  }
  .say__item p {
    line-height: 2.2857142857em;
  }
  .say__item p span {
    padding-right: 2px;
    padding-bottom: 1px;
  }
  .say__total-swiper .swiper-wrapper {
    display: flex;
    grid-template-columns: initial;
    grid-column-gap: initial;
    grid-row-gap: initial;
  }
  .jobs__card {
    clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 85%);
  }
  .jobs__card::before {
    clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 85%);
  }
  .video .decor img:first-child {
    display: none;
  }
  .video .decor img:last-child {
    width: 168px;
    transform: none;
  }
  .video__head p {
    width: 100%;
  }
  .video__head .btn {
    letter-spacing: -0.2px;
  }
  .video__swiper .swiper-wrapper {
    display: flex;
    grid-template-columns: initial;
    grid-row-gap: initial;
    grid-column-gap: initial;
  }
  .video__swiper .swiper-pagination {
    position: relative;
    bottom: auto;
    left: auto;
    display: block;
    margin-top: 28px;
  }
  .video__cont h4 {
    font-weight: 500;
    line-height: 1.5555555556em;
  }
  .video__cont .btn {
    letter-spacing: -0.2px;
  }
  .team-grid__inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
    grid-row-gap: 30px;
  }
  .cards-hiring .decor {
    display: none;
  }
  .show-work .show-work-dec {
    display: none;
  }
  .footer__block, .footer__form {
    width: 100%;
  }
  .footer__block {
    order: 1;
  }
  .footer__form {
    padding: 0;
    margin-bottom: 108px;
  }
  .footer__menu {
    display: none;
  }
  .tab__block {
    padding-left: 0px;
  }
  .values__inner {
    padding-bottom: 105px;
  }
}
@media only screen and (max-width: 651px) {
  .jobs__head h2 {
    font-weight: 600;
  }
  .jobs__swiper {
    width: 302px;
    overflow: hidden;
    margin: 0 auto;
  }
  .jobs__swiper .swiper-slide {
    height: initial;
  }
  .jobs__swiper .swiper-pagination {
    position: relative;
    display: block;
    margin-top: 39px;
  }
  .jobs__card {
    clip-path: polygon(0 0, 84% 0, 100% 12%, 100% 100%, 17% 100%, 0 84%);
  }
  .jobs__card::before {
    clip-path: polygon(0 0, 84% 0, 100% 12%, 100% 100%, 17% 100%, 0 84%);
  }
  .jobs__card .btn {
    margin-right: 22px;
  }
  .jobs__card .btn:last-child {
    margin-right: 0;
  }
  .jobs__card-cont h4 {
    line-height: 1.4444444444em;
  }
  .footer__form div {
    font-weight: 300;
    letter-spacing: 1.5px;
    margin-bottom: 8px;
  }
  .footer__form form input {
    width: 100%;
  }
  .footer__form form input:nth-child(2) {
    margin-top: 30px;
  }
  .footer__form form input:nth-child(3) {
    order: 1;
  }
  .footer__form form textarea {
    order: 2;
  }
  .footer__form form button {
    order: 3;
  }
  .footer__policy div {
    left: auto;
    line-height: normal;
    letter-spacing: -0.15px;
    margin-bottom: 2px;
  }
}
@media only screen and (max-width: 601px) {
  .job-board__filters {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 551px) {
  p {
    font-size: 12px;
    line-height: 1.8571428571em;
  }
  .banner .decor img {
    width: 200px;
    transform: none;
  }
  .banner__cont {
    max-width: 100%;
  }
  .banner__cont .title {
    line-height: 1.7em;
  }
  .banner__cont .title span:first-child {
    padding: 6px 3px 4px 3px;
  }
  .banner__cont .title span:last-child {
    padding: 1px 1px 5px 4px;
  }
  .banner__cont .btn {
    font-weight: 300;
  }
  .banner__cont .btn span {
    margin-left: 13px;
  }
  .cards .decor img {
    width: 340px;
    transform: none;
  }
  .cards__item p {
    line-height: 1.7142857143em;
  }
  .head-team .dec {
    display: none;
  }
  .head-team__form {
    max-width: 100%;
  }
}
@media only screen and (max-width: 421px) {
  .jobs__cont .btn {
    width: 100%;
    font-weight: 300;
    margin-bottom: 18px;
  }
  .jobs__cont .btn:last-child {
    margin-bottom: 0;
  }
  .dec-big img {
    right: -35px !important;
  }
  .footer__policy {
    flex-direction: column;
  }
  .footer__policy div,
.footer__policy a {
    margin-right: 0;
  }
  .footer__policy a {
    padding: 0;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 361px) {
  .header__list-item span {
    font-size: 28px;
  }
  .header__links li a {
    font-size: 22px;
  }
  .jobs__swiper {
    width: 100%;
  }
}
@media only screen and (max-width: 1400px) {
  .show__text-block {
    max-width: 860px;
  }
}
@media only screen and (max-width: 1281px) {
  .show__text-block {
    flex-direction: column;
    max-width: 700px;
    text-align: center;
    align-items: center;
  }
  .show__title {
    max-width: 100%;
  }
  .show__text {
    max-width: 100%;
  }
  .career__inner {
    margin-left: 60px;
  }
}
@media only screen and (max-width: 1025px) {
  .career__inner {
    margin-left: 40px;
  }
}
@media only screen and (max-width: 901px) {
  .title-box h2 {
    width: 100%;
    margin-bottom: min(max(
            9px + 3 *
              (
                (100vw - 768px) / 672
              )
          , 9px), 12px);
  }
  .title-box__cont {
    width: 100%;
    padding: 0;
  }
  .career__inner {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  .career__text {
    max-width: 100%;
    margin-right: 0px;
    margin-left: 40px;
  }
  .career__video {
    max-width: 460px;
    margin-top: 35px;
    clip-path: polygon(7% 0, 94% 0, 100% 12%, 100% 80%, 100% 100%, 6% 100%, 0 90%, 0 0);
  }
  .career .plyr--video {
    clip-path: polygon(7% 0, 94% 0, 100% 12%, 100% 80%, 100% 100%, 6% 100%, 0 90%, 0 0);
  }
}
@media only screen and (max-width: 769px) {
  .show .show-dec {
    display: none;
  }
  .career .career-dec {
    display: none;
  }
  .career__text {
    margin-left: 0;
  }
}
@media only screen and (max-width: 769px) {
  .video-sec .decor img:first-child {
    display: none;
  }
}
@media only screen and (max-width: 1281px) {
  .testimonials__slider.swiper {
    max-width: 768px;
  }
}
@media only screen and (max-width: 769px) {
  .testimonials .testimonials-decor {
    display: none;
  }
  .testimonials__slider.swiper {
    max-width: 376px;
  }
}
@media only screen and (max-width: 651px) {
  .insta__soc {
    flex-direction: column;
    align-items: baseline;
  }
  .insta__soc h2 {
    margin-right: 0px;
  }
  .insta__soc-box {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 551px) {
  .lookout .decor {
    display: none;
  }
  .insta__grid {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
}
@media only screen and (max-width: 421px) {
  .impact__slider {
    max-width: 100%;
    position: relative;
    height: 200px;
  }
  .impact__slide-img img {
    clip-path: polygon(0 0, 89% 0, 100% 21%, 100% 100%, 100% 100%, 11% 100%, 0 81%, 0 0);
  }
}
@media only screen and (max-width: 1025px) {
  .details__inner {
    flex-direction: column-reverse;
  }
  .details__descr {
    max-width: 100%;
    margin-right: 0px;
  }
  .details__info {
    display: flex;
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .details__boxes {
    margin-top: 0;
  }
  .details__person-box {
    margin-right: 110px;
  }
  .details__box-local {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 901px) {
  .details__person-box {
    margin-right: 70px;
  }
  .details__local-img img {
    max-width: 100%;
  }
  .community-signup__inner {
    flex-direction: column;
  }
  .community-signup__inner .signup-section__title {
    width: 100%;
  }
  .community-signup__form-inner {
    margin: 10px 0px 0 0px;
    width: 100%;
  }
  .head-resource .head__cont {
    flex-direction: column;
  }
  .head-resource .head__cont h1 {
    width: 100%;
  }
  .head-resource__box {
    margin-top: 20px;
    margin-left: 0px;
  }
  .resource__inner {
    margin-top: 50px;
    flex-direction: column;
  }
  .resource__descr {
    max-width: 100%;
    margin-right: 0px;
    margin-top: initial;
  }
  .resource__info {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    max-width: 100%;
    right: 0px;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: min(max(
            22px + 10 *
              (
                (100vw - 768px) / 672
              )
          , 22px), 32px);
    padding-bottom: 30px;
    flex-direction: row;
  }
  .resource__info-card {
    margin-right: 0;
    margin-top: 0;
    /*width: 100%;*/
  }
  .resource__box {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 769px) {
  .details__person-box {
    margin-right: 40px;
  }
  .resource__inner {
    margin-top: 50px;
    flex-direction: column;
  }
  .resource__descr {
    max-width: 100%;
    margin-right: 0px;
    margin-top: initial;
  }
  .resource__info {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    max-width: 100%;
    right: 0px;
  }
  .resource__info-card {
    /*margin-right: 70px;
    margin-top: 40px;*/
    max-width: 278px;
    width: 100%;
  }
  .resource__box {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 651px) {
  .details__info {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
  }
  .details__person-box {
    margin-right: 30px;
  }
  .details__info-card {
    margin-right: 40px;
  }
  .details__boxes {
    display: flex;
  }
  .details__box-candidate {
    margin-top: 20px;
    margin-right: 30px;
  }
  .resource {
    /*&__info-card {
      margin-right: 30px;
      margin-top: 30px;
    }*/
  }
  .resource__info {
    margin-top: 20px;
  }
  .resource__box {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 551px) {
  .details__boxes {
    flex-direction: column;
    max-width: 100%;
    width: 100%;
  }
  .details__box-local {
    max-width: 100%;
    width: 100%;
  }
  .details__local-img img {
    width: 100%;
  }
  .details__box-candidate {
    max-width: 100%;
  }
  .details__info-card {
    margin-bottom: 20px;
  }
  .details__box-candidate {
    margin-top: 20px;
    margin-right: 0px;
    width: 100%;
  }
  .resource {
    /* &__info-card {
       margin-right: 0px;
       margin-top: 30px;
     }*/
  }
  .resource__info {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }
  .resource__box {
    margin-top: 30px;
    max-width: 278px;
    width: 100%;
  }
}
@media only screen and (max-width: 421px) {
  .details__person-box {
    margin-right: 0;
    flex-direction: column;
  }
  .resource__info-card {
    width: 100%;
  }
}