.head-details {
  .head {
    &__cont {
      margin-right: 105px;
      h1 {
        font-size: adaptive-calc(52px, 30px);
        line-height: adaptive-calc(76px, 35px);
        color: var(--color-white);
        font-weight: 600;
        padding-top: adaptive-calc(196px, 170px);
        letter-spacing: -1px;
        max-width: 600px;
      }
    }
  }
}

.details {
  margin: adaptive-calc(79px, 50px) 0 adaptive-calc(100px, 50px);

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__descr {
    max-width: 768px;
    margin-right: 50px;

    &-text {
      p {
        margin-bottom: adaptive-calc(31px, 15px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-btns {
      margin-top: adaptive-calc(39px, 20px);
      display: flex;
      flex-wrap: wrap;

      a,
      button {
        margin-top: 15px;
      }

      .btn {
        margin-right: adaptive-calc(49px, 15px);
      }
    }
  }

  &__descr-soc {
    margin-top: 48px;
  }

  &__info {
    position: relative;
    max-width: 450px;
    margin-top: 5px;
    right: adaptive-calc(-7px, 0px);
    flex-shrink: 0;

    &-card {
      max-width: 450px;
      margin-bottom: 44px;
    }
  }

  &__info-descr {
    background: var(--color-blue-dark);
    clip-path: polygon(
      0 0,
      calc(100% - 40px) 0,
      100% 40px,
      100% 100%,
      40px 100%,
      0 calc(100% - 40px)
    );
    padding: 38px 16px 38px;

    @include media(651) {
      height: max-content;
    }

    & > * {
      color: white;
      & > * {
        color: white;
      }
    }

    h4 {
      font-size: adaptive-calc(21px, 18px);
      font-weight: 400;
      margin-bottom: 8px;
    }
  }

  &__info-nums {
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    p {
      margin-right: 13px;
      font-size: adaptive-calc(16px, 13px);
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__boxes {
    margin-top: 51px;
  }

  &__box-local {
    margin-top: 50px;
    max-width: 278px;
  }
  &__local-img {
    img {
      max-width: 278px;
      max-height: 148px;
      object-fit: cover;
    }
  }

  &__local-text {
    padding: 10px 15px;

    h4 {
      text-align: center;
      font-size: adaptive-calc(32px, 21px);
      line-height: 1.4;
    }
    a {
      margin: 13px auto 0px;
    }
    .btn:last-child {
      margin-right: auto;
    }
  }
}

.jobs-details {
  margin: adaptive-calc(165px, 20px) 0 adaptive-calc(141px, 50px);

  &__box {
    margin-bottom: 37px;
    align-items: baseline;

    h2 {
      width: 33.4%;
      letter-spacing: -1px;
    }
  }

  .swiper-slide {
    opacity: 0.5;
  }

  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide-next,
  .swiper-slide-prev {
    opacity: 1;
  }

  .slider-control {
    margin-top: 51px;
  }
}

.community {
  margin-left: adaptive-calc(64px, 0px);

  margin-bottom: 50px;

  .title-box__cont {
    margin-top: 5px;
    padding-left: adaptive-calc(17px, 0px);
  }
}

.community-signup {
  margin-top: adaptive-calc(104px, 50px);
  padding-bottom: adaptive-calc(54px, 35px) !important;
  margin-left: adaptive-calc(64px, 0px);

  .cont {
    max-width: 1160px !important;
    width: perc(1160) !important;
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    .signup-section__title {
      width: 44%;
    }
  }

  &__form-inner {
    margin: 33px 45px 0 30px;
    width: 56%;
  }
}

.head-resource {
  padding-bottom: adaptive-calc(143px, 30px);
  .head__cont {
    padding-top: adaptive-calc(262px, 170px);
    display: flex;
    // justify-content: space-between;
    h1 {
      width: 60%;
      padding-top: 0;
      font-size: adaptive-calc(34px, 22px);
      max-width: adaptive-calc(768px, 400px);
      line-height: adaptive-calc(75px, 35px);
      letter-spacing: 0px;
    }
  }

  &__box {
    margin-top: 15px;
    margin-left: 20px;

    h5 {
      color: var(--color-green-2);
      font-size: adaptive-calc(21px, 18px);
      font-weight: 400;

      + h5 {
        margin-top: 4px;
      }
    }

    p {
      font-size: adaptive-calc(16px, 14px);
      margin-top: adaptive-calc(26px, 10px);
      color: var(--color-white);
    }

    a,
    button {
      margin-top: adaptive-calc(30px, 10px);
      color: var(--color-white);

      span {
        &::before {
          border-left: 13px solid var(--color-blue-dark);
        }
      }
    }
  }
}

.resource {
  margin-bottom: adaptive-calc(135px, 50px);

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__descr {
    max-width: 768px;
    margin-right: 50px;
    margin-top: adaptive-calc(-102px, -20px);
  }

  &__descr-img {
    margin-bottom: adaptive-calc(44px, 30px);

    img {
      max-width: 100%;
      height: auto;
    }

    p {
      margin-top: 8px;
      font-size: adaptive-calc(16px, 14px);
      color: var(--color-green-2);
    }
  }

  &__descr-text {
    &.resource__descr-text-bottom {
      font-family: var(--font-main);
      font-size: adaptive-calc(18px, 14px);
    }

    p {
      margin-bottom: adaptive-calc(32px, 15px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__descr-quote {
    margin-top: adaptive-calc(84px, 30px);
    position: relative;
    padding-left: adaptive-calc(58px, 20px);
    font-size: adaptive-calc(18px, 14px);
    font-family: "Lexend";
    line-height: 1.7777777778em;

    &::before {
      content: "“";
      position: absolute;
      font-size: adaptive-calc(100px, 50px);
      line-height: 1;
      font-family: "Lexend";
      color: var(--color-red);
      top: adaptive-calc(-20px, 0px);
      left: adaptive-calc(0px, -8px);
    }
  }

  &__descr-btns {
    a {
      margin-top: adaptive-calc(103px, 40px);
    }
  }

  &__info {
    margin-top: 343px;
    position: relative;
    max-width: 278px;
    right: -5px;
    grid-row-gap: adaptive-calc(32px, 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  &__info-card {
    width: 278px;
  }
  &__box {
    margin-top: 96px;
  }
}

.video-resource {
  margin-bottom: 140px;
  .title-box {
    margin-bottom: 33px;

    h2 {
      width: 100%;
    }
  }
}

.content-element.details__descr-text {
  font-size: adaptive-calc(18px, 14px);
  line-height: 1.7777777778em;
  font-family: var(--font-main);
  color: var(--color-blue-dark);

  p {
    word-break: break-word;
  }
}
