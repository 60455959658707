@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?tbm1m8');
  src:  url('../fonts/icomoon.eot?tbm1m8#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?tbm1m8') format('truetype'),
  url('../fonts/icomoon.woff?tbm1m8') format('woff'),
  url('../fonts/icomoon.svg?tbm1m8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter-black:before {
  content: "\e900";
}
.icon-linkedin-black:before {
  content: "\e907";
}
.icon-twitter:before {
  content: "\e908";
  color: #e4b700;
}
.icon-linkedin:before {
  content: "\e901";
  color: #ffa300;
}
.icon-instagram:before {
  content: "\e902";
  color: #ffa300;
}
.icon-youtube:before {
  content: "\e903";
  color: #ffa300;
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-brand:before {
  content: "\e90b";
}
.icon-social:before {
  content: "\e90b";
}
.icon-right:before {
  content: "\e905";
}
.icon-left:before {
  content: "\e906";
}
.icon-info:before {
  content: "\e90e";
}
.icon-upload:before {
  content: "\e90d";
}
.icon-cross:before {
  content: "\e90c";
}
.icon-slider-left:before {
  content: "\e909";
}
.icon-slider-right:before {
  content: "\e90a";
}
.icon-email:before {
  content: "\e904";
  color: #01033d;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: var(--font-main);
  color: var(--color-blue-dark);
}

h1 {
  font-size: adaptive-calc(47px, 22px);
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: adaptive-calc(44px, 21px);
  font-weight: 700;
  line-height: 1.3em;
}

h3 {
  font-size: adaptive-calc(25px, 18px);
  font-weight: 700;
  line-height: 2.03125em;
}

h4 {
  font-size: adaptive-calc(18px, 14px);
  font-weight: 700;
  line-height: 1.8em;
}

h5 {
  font-size: adaptive-calc(16px, 12px);
  font-weight: 700;
  line-height: 1.1428571428571428em;
}

p {
  font-size: adaptive-calc(18px, 14px);
  line-height: 1.7777777777777777em;
}

.content-element {
  * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3 {
    margin-bottom: 10px;

    &:not(:first-child) {
      margin-top: adaptive-calc(20px, 15px);
    }
  }

  h1 {
    font-size: adaptive-calc(70px, 33px);
    font-weight: 700;
    line-height: 1.2em;
  }

  h2 {
    font-size: adaptive-calc(50px, 32px);
    font-weight: 700;
    line-height: 1.3em;
  }

  h3 {
    font-size: adaptive-calc(32px, 22px);
    font-weight: 700;
    line-height: 2.03125em;
  }

  p {
    padding: 0;
    font-size: adaptive-calc(18px, 14px);
    line-height: 1.7777777777777777em;
    margin-bottom: adaptive-calc(31px, 15px);
  }

  a {
    display: inline-block;
    font-weight: 500;
    position: relative;
    transition: all 0.3s ease;
    color: var(--color-blue);

    // &:before {
    //   content: "";
    //   position: absolute;
    //   right: 0;
    //   left: auto;
    //   bottom: 2px;
    //   height: 1px;
    //   width: 0;
    //   background: var(--color-blue);
    //   transition: all 0.3s ease;
    // }

    &:hover {
      text-decoration: underline;
      // color: var(--color-blue-dark);
      // transition: all 0.3s ease;
      // &:before {
      //   background: var(--color-blue-dark);
      //   left: 0;
      //   right: auto;
      //   width: 100%;
      // }
    }
  }

  li,
  blockquote {
    font-size: adaptive-calc(18px, 14px);
    line-height: 1.7777777777777777em;
    font-family: var(--font-main);
  }

  ul,
  ol {
    margin-bottom: adaptive-calc(31px, 15px);
  }

  ul {
    list-style: none;

    li {
      position: relative;
      // display: flex;
      padding-left: 15px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        /*background: url('../images/triangle-li-style.svg') no-repeat center center / contain;*/
        display: block;
        flex-shrink: 0;
        margin-right: 5px;
      }
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  /*blockquote {
    font-style: italic;
    position: relative;
    padding-left: adaptive-calc(25px, 14px);
    margin-bottom: 40px;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: adaptive-calc(4px, 2px);
      border-radius: 10px;
      background: var(--text);
    }
  }

  img {
    border-radius: adaptive-calc(40px, 20px);
    margin-top: adaptive-calc(20px, 10px);
    margin-bottom: adaptive-calc(76px, 35px);
  }*/
}
