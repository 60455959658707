.job-card {
    width: 100%;

    padding: adaptive-calc(40px, 20px);
    border: solid 2px var(--black);
    border-radius: adaptive-calc(20px, 10px);

    background: var(--bg-second);

    color: var(--white);

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
        margin-bottom: 5px;
    }

    .description {
        @include max-line-leng(4);
        margin-bottom: 20px;
    }
    &_small{
        padding: 20px;
        .btn{
            width: 100%;
        }
        .btn-wrap{
            .btn{
                &:not(:last-of-type){
                    margin-right: 0;
                }
            }
        }
    }
}

.jobs-swiper-section {
    margin: adaptive-calc(200px, 100px) 0;
}

.job-recommendation {
    margin: adaptive-calc(200px, 100px) 0;

    h3 {
        margin-bottom: adaptive-calc(40px, 20px);
    }
}

.job-page {
    &.first-section {
        .info {
            display: grid;
            grid-template-columns: max-content auto;
            align-items: center;

            h6 {
                color: var(--primary);
                width: adaptive-calc(110px, 90px);
                margin-right: adaptive-calc(20px, 10px);
                flex-shrink: 0;
            }
            h5 {
                margin-bottom: 0;
            }
        }
    }
}

.job-content {
    .sticky-wrap {
        display: flex;
        justify-content: space-between;

        @include media(651) {
            flex-direction: column-reverse;
        }
        &_related-jobs{
            @include media(1024) {
                flex-direction: column;
                .main-part{
                    width: 100%;
                }
                .sidebar{
                    width: 100%;
                }
            }
        }
    }
    .main-part {
        width: 70%;
    }
    .sidebar {
        width: 20%;

        @include media(901) {
            width: 25%;
        }
    }

    @include media(651) {
        .main-part, .sidebar {
            width: 100%;
        }

        .team-member-card {
            display: flex;
            margin-bottom: 50px;

            &.square-photo {
               .photo {
                   width: adaptive-calc(200px, 120px, 375, 650);
                   padding-bottom: adaptive-calc(200px, 120px, 375, 650);
                   margin-right: 30px;
               }
            }
        }
    }

    @include media(420) {
        .sidebar {
            width: 80%;
            margin: 0 auto;
        }

        .team-member-card {
            flex-direction: column;

            &.square-photo {
                .photo {
                    width: 100%;
                    padding-bottom: 100%;
                    margin-right: 0;
                }
            }
        }
    }
}

.job-result {
    margin-top: adaptive-calc(120px, 60px);
    .cont {
        display: flex;
        justify-content: space-between;
    }

    .filters {
        width: 22%;
        max-width: initial;
        min-width: 200px;

        margin: 0;
        margin-right: 20px;
    }

    .grid-two-wrapper {
        width: 75%;
    }

    @include media(650) {
        .cont {
            flex-direction: column;
        }

        .filters, .grid-two-wrapper {
            width: 100%;
        }

        .filters.sidebar {
           .main-filters {
               display: grid;
               grid-template-columns: repeat(2, 1fr);
               grid-gap: 20px;
           }
            margin-bottom: 35px;
        }
    }

    @include media(420) {
        .filters.sidebar {
            .main-filters {
                grid-template-columns: 1fr;
            }
        }
    }
}

.job-page-related{
    .slick-list{
        margin: 0 -15px;
    }
    .slick-slide{
        margin: 0 15px;
    }
}