@use "sass:math";

@media only screen and (max-width: 1281px) {}

@media only screen and (max-width: 1025px) {}

@media only screen and (max-width: 901px) {}

@media only screen and (max-width: 769px) {
  .video-sec{
    .decor {
      img {
        &:first-child {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 651px) {}

@media only screen and (max-width: 551px) {}

@media only screen and (max-width: 421px) {}

@media only screen and (max-width: 361px) {}