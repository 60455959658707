@use "sass:math";

@media only screen and (max-width: 1600px) {
  .career .career-dec img {
    max-width: 160px;
  }

  .job-board__bottom-decor-img {
    width: 12.513889vw;
  }
}

@media only screen and (max-width: 1480px) {
  .career .career-dec img {
    max-width: 120px;
  }
}

@media only screen and (max-width: 1325px) {
  .video-sec__cont {
    padding-right: 170px;
  }
}
@media only screen and (max-width: 1281px) {
  :root {
    --header-height: 140px;
  }

  .header {
    &.scrolled {
      .header__list {
        top: var(--header-height-scrolled);
        height: calc(100% - var(--header-height-scrolled));
        padding: 59px 20px 59px 50px;
      }
    }

    &.open-menu {
      .header__list {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .header__burger {
        &::before {
          top: 9px;
          transform: rotate(45deg);
        }

        &::after {
          bottom: 9px;
          transform: rotate(-45deg);
        }

        span {
          opacity: 0;
          visibility: hidden;
          left: 20px;
        }
      }
    }

    &.open-menu + .header__layer {
      opacity: 1;
      visibility: visible;
    }

    .header__menu {
      margin-left: auto;
      margin-right: 20px;
    }

    &__list {
      position: fixed;
      top: var(--header-height-scrolled);
      right: 0;
      display: block;
      width: 580px;
      height: calc(100% - var(--header-height-scrolled));
      padding: 59px 20px 59px 50px;
      background-color: var(--color-blue-dark);
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      will-change: transform;
      transform: translateX(580px);
      @extend %default-transition;
    }

    &__list-item {
      width: fit-content;
      height: auto;
      margin-right: 0;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &:not(&.active) {
        span:hover {
          &::before {
            transform: scale(1.5);
          }
        }
      }

      div {
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      span {
        padding-right: 28px;
        margin-bottom: 17px;

        a {
          // font-size: 33px;
          font-size: adaptive-calc(33px, 22px);
          line-height: 1em;
        }

        &::before {
          border-left-width: 12px;
          border-top-width: 7px;
          border-bottom-width: 7px;
          margin-top: 5px;
        }
      }
    }

    &__links {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      display: none;
      // text-align: center;
      padding: 0;
      opacity: 1;
      visibility: visible;
      transition: none;

      li {
        margin-bottom: 9px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          font-size: adaptive-calc(33px, 22px);

          font-weight: 400;
        }
      }
    }

    &__burger {
      display: flex;
    }
  }

  .header__layer {
    display: block;
  }

  .banner {
    .decor {
      img {
        transform: scale(0.8);
      }
    }

    &__cont {
      max-width: 740px;

      .btn-block {
        flex-direction: column;
      }

      .btn {
        width: fit-content;
        margin-right: 0;
        margin-bottom: adaptive-calc(20px, 16px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .tab {
    .decor {
      img {
        transform: scale(0.8);
      }
    }
  }

  .cards {
    .decor {
      img {
        transform: scale(0.8);
      }
    }
  }

  .say {
    .decor {
      img {
        transform: scale(0.8);
      }
    }
  }

  .jobs {
    &__card {
      // clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 78%);

      &::before {
        // clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 78%);
      }
    }
  }

  .video {
    .decor {
      img {
        transform: scale(0.8);
      }
    }

    &__head {
      max-width: 650px;
    }
  }

  .footer {
    &__block {
      width: 54%;
    }

    &__form {
      width: 46%;
    }
  }

  .career .career-dec img {
    max-width: 55px;
  }

  .show-team .show__title {
    max-width: unset;
  }
}

@media only screen and (max-width: 1200px) {
  .tab__block {
    padding-left: 50px;
  }

  .values__block {
    padding-left: 50px;
  }

  .video-sec__cont {
    padding-right: 140px;
  }

  .tab__swiper {
    max-width: 640px;
  }
}

@media only screen and (max-width: 1150px) {
  .member {
    &__inner {
      flex-direction: column-reverse;
    }

    &__info {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      max-width: 100%;
      width: 100%;
      right: 0px;
      top: 0px;
      margin-bottom: 30px;
    }

    &__text-block {
      max-width: 100%;
    }

    &__photo {
      max-width: 300px;
      margin-right: 25px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .tab__block {
    padding-left: 65px;
  }
  .job-board__filters {
    padding-bottom: 240px;
  }
  .job-board__bottom-decor-img {
    width: 100%;
    max-width: 120px;
    min-width: 120px;
  }

  .tab__swiper {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1025px) {
  .banner {
    .decor {
      img {
        transform: scale(0.7);
      }
    }

    &__cont {
      max-width: 550px;
    }
  }

  .tab {
    .decor {
      img {
        transform: scale(0.7);
      }
    }

    &__swiper {
      max-width: 620px;
    }
  }

  .cards {
    .decor {
      img {
        transform: scale(0.7);
      }
    }
  }

  .say {
    .decor {
      img {
        transform: scale(0.7);
      }
    }

    &__swiper {
      max-width: 700px;
    }
  }

  .jobs {
    &__card {
      clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 81%);

      &::before {
        clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 81%);
      }
    }
  }

  .video {
    .decor {
      img {
        transform: scale(0.7);
      }
    }

    &__head {
      max-width: 580px;
    }
  }

  .footer {
    &__block {
      width: 45%;
    }

    &__form {
      width: 55%;
    }

    &__left,
    &__right {
      width: 100%;
    }

    &__left {
      margin-bottom: 31px;
    }

    &__policy {
      div,
      a {
        //width: 100%;
      }

      a {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .head-team {
    &__form {
      flex-direction: column;
      max-width: 350px;
      input {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      button,
      a {
        margin-top: 30px;
      }

      .select2-container--default
        .select2-selection--single
        .select2-selection__rendered {
        max-width: 250px;
      }
      .select2-container--default
        .select2-selection--single
        .select2-selection__arrow {
        right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 901px) {
  .banner {
    .decor {
      img {
        transform: scale(0.6);
      }
    }

    &__cont {
      max-width: 475px;
    }
  }

  .tab {
    .decor {
      img {
        transform: scale(0.6);
      }
    }

    &__swiper {
      max-width: 450px;
    }
  }

  .cards {
    .decor {
      img {
        transform: scale(0.6);
      }
    }
  }

  .say {
    .decor {
      img {
        transform: scale(0.6);
      }
    }

    &__swiper {
      max-width: 600px;
    }
  }

  .jobs {
    &__card {
      clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 82%);

      &::before {
        clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 82%);
      }
    }
  }

  .video {
    .decor {
      img {
        transform: scale(0.6);
      }
    }

    &__head {
      max-width: 500px;
    }
  }

  .jobs-member {
    &__cont {
      .btn-block {
        justify-content: flex-start;
        margin-bottom: 0px;
      }
    }
  }

  .sol-career-revers {
    .career__inner {
      flex-direction: column;
    }
    .career__text {
      margin-left: 0px;
      margin-top: 0px;
    }
  }

  .video-sec__cont {
    padding-right: 170px;
  }
}

@media only screen and (max-width: 769px) {
  :root {
    --header-height: 78px;
    --header-height-scrolled: 78px;
  }

  .tab__swiper {
    padding-right: 0;
  }

  .header {
    &__list {
      width: 100%;
      transform: translateX(100%);
    }
  }

  .banner {
    .decor {
      img {
        transform: scale(0.5);
      }
    }

    &__cont {
      max-width: 390px;
    }
  }

  .tab {
    padding-top: 36px;
    padding-bottom: 30px;

    .decor,
    &__nav {
      display: none;
    }

    &__swiper {
      max-width: 100%;
    }

    &__item {
      display: block;

      h2,
      .tab__head {
        display: block;
      }

      .btn-block {
        padding-left: 0;
      }
    }
  }

  .say {
    .decor {
      display: none;
    }

    &__title {
      font-weight: 600;
    }

    &__swiper {
      max-width: 100%;
    }

    &__item {
      p {
        line-height: 2.2857142857142856em;

        span {
          padding-right: 2px;
          padding-bottom: 1px;
        }
      }
    }

    &__total-swiper {
      .swiper-wrapper {
        display: flex;
        grid-template-columns: initial;
        grid-column-gap: initial;
        grid-row-gap: initial;
      }
    }
  }

  .jobs {
    &__card {
      clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 85%);

      &::before {
        clip-path: polygon(0 0, 84% 0, 100% 13%, 100% 100%, 17% 100%, 0 85%);
      }
    }
  }

  .video {
    .decor {
      img {
        &:first-child {
          display: none;
        }

        &:last-child {
          width: 168px;
          transform: none;
        }
      }
    }

    &__head {
      p {
        width: 100%;
      }

      .btn {
        letter-spacing: -0.2px;
      }
    }

    &__swiper {
      .swiper-wrapper {
        display: flex;
        grid-template-columns: initial;
        grid-row-gap: initial;
        grid-column-gap: initial;
      }

      .swiper-pagination {
        position: relative;
        bottom: auto;
        left: auto;
        display: block;
        margin-top: 28px;
      }
    }

    &__cont {
      h4 {
        font-weight: 500;
        line-height: 1.5555555555555556em;
      }

      .btn {
        letter-spacing: -0.2px;
      }
    }
  }

  .team-grid {
    &__inner {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
      grid-row-gap: 30px;
    }
  }

  .cards-hiring {
    .decor {
      display: none;
    }
  }

  .show-work {
    .show-work-dec {
      display: none;
    }
  }

  .footer {
    &__block,
    &__form {
      width: 100%;
    }

    &__block {
      order: 1;
    }

    &__form {
      padding: 0;
      margin-bottom: 108px;
    }

    &__menu {
      display: none;
    }
  }

  .tab__block {
    padding-left: 0px;
  }
  .values__inner {
    padding-bottom: 105px;
  }
}

@media only screen and (max-width: 651px) {
  .jobs {
    &__head {
      h2 {
        font-weight: 600;
      }
    }

    &__swiper {
      width: 302px;
      overflow: hidden;
      margin: 0 auto;

      .swiper-slide {
        height: initial;
      }

      .swiper-pagination {
        position: relative;
        display: block;
        margin-top: 39px;
      }
    }

    &__card {
      clip-path: polygon(0 0, 84% 0, 100% 12%, 100% 100%, 17% 100%, 0 84%);

      &::before {
        clip-path: polygon(0 0, 84% 0, 100% 12%, 100% 100%, 17% 100%, 0 84%);
      }

      .btn {
        margin-right: 22px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__card-cont {
      h4 {
        line-height: 1.4444444444444444em;
      }
    }
  }

  .footer {
    &__form {
      div {
        font-weight: 300;
        letter-spacing: 1.5px;
        margin-bottom: 8px;
      }

      form {
        input {
          width: 100%;

          &:nth-child(2) {
            margin-top: 30px;
          }

          &:nth-child(3) {
            order: 1;
          }
        }

        textarea {
          order: 2;
        }

        button {
          order: 3;
        }
      }
    }

    &__policy {
      div {
        left: auto;
        line-height: normal;
        letter-spacing: -0.15px;
        margin-bottom: 2px;
      }
    }
  }
}

@media only screen and (max-width: 601px) {
  .job-board__filters {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 551px) {
  p {
    font-size: 12px;
    line-height: 1.8571428571428572em;
  }

  .banner {
    .decor {
      img {
        width: 200px;
        transform: none;
      }
    }

    &__cont {
      max-width: 100%;

      .title {
        line-height: 1.7em;

        span {
          &:first-child {
            padding: 6px 3px 4px 3px;
          }

          &:last-child {
            padding: 1px 1px 5px 4px;
          }
        }
      }

      .btn {
        font-weight: 300;

        span {
          margin-left: 13px;
        }
      }
    }
  }

  .cards {
    .decor {
      img {
        width: 340px;
        transform: none;
      }
    }

    &__item {
      p {
        line-height: 1.7142857142857142em;
      }
    }
  }

  .head-team {
    .dec {
      display: none;
    }

    &__form {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 421px) {
  .jobs {
    &__cont {
      .btn {
        width: 100%;
        font-weight: 300;
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .dec-big {
    img {
      right: -35px !important;
    }
  }

  .footer {
    &__policy {
      flex-direction: column;
      div,
      a {
        margin-right: 0;
      }

      a {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 361px) {
  .header {
    &__list-item {
      span {
        font-size: 28px;
      }
    }

    &__links {
      li {
        a {
          font-size: 22px;
        }
      }
    }
  }

  .jobs {
    &__swiper {
      width: 100%;
    }
  }
}
