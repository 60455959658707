.career-show {
  padding: adaptive-calc(58px, 50px) 0px adaptive-calc(87px, 50px);
}

.career-main {
  padding: adaptive-calc(85px, 50px) 0px 0px;

  &.d-and-i {
    padding-bottom: adaptive-calc(85px, 50px);
    .career__text {
      max-width: 100%;
      margin-right: 0;
    }
  }
}

.testimonials {
  padding-top: adaptive-calc(228px, 50px);
  margin-bottom: adaptive-calc(114px, 40px);

  .testimonials__slide {
    height: auto;
  }

  &__slider-box {
    margin: 0 auto;
    max-width: 1180px;
    width: perc(1180);
  }

  .testimonials-decor {
    pointer-events: none;

    img {
      position: absolute;
      top: -12px;
      right: -6px;
      width: adaptive-calc(205.64px, 100px);
      height: auto;
    }
  }

  &__title {
    text-align: center;
    h2 {
    }
  }

  &__slider {
    margin-top: adaptive-calc(50px, 20px);
  }
  &__slider.swiper {
    padding: 30px 8px 37px;
    margin-left: -8px;
    margin-right: -8px;
  }
}

.staff-card {
  &.orange {
    .decor {
      pointer-events: none;

      p,
      span {
        color: var(--color-yellow-2);
      }
    }

    .staff-card__name {
      p {
        color: var(--color-yellow-2);
      }
    }

    .staff-card__text {
      .simplebar-scrollbar::before {
        background: var(--color-yellow-2);
      }
    }
  }

  &.blue {
    .decor {
      pointer-events: none;

      p,
      span {
        color: var(--color-blue);
      }
    }

    .staff-card__name {
      p {
        color: var(--color-blue);
      }
    }

    .staff-card__text {
      .simplebar-scrollbar {
        &::before {
          background: var(--color-blue);
        }
      }
    }
  }

  &.green {
    .decor {
      pointer-events: none;

      p,
      span {
        color: var(--color-green-2);
      }
    }

    .staff-card__name {
      p {
        color: var(--color-green-2);
      }
    }

    .staff-card__text {
      .simplebar-scrollbar::before {
        background: var(--color-green-2);
      }
    }
  }
}

.staff-card {
  position: relative;

  display: flex;
  flex-direction: column;

  max-width: 376px;
  height: 100%;

  //background-color: var(--color-blue-dark);
  padding: adaptive-calc(85px, 40px) 21px adaptive-calc(32px, 20px);
  margin: 0 auto;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0;
    background-color: var(--color-blue-dark);
    clip-path: polygon(0 0, 85% 0, 100% 11%, 100% 100%, 16% 100%, 0 88%);
    z-index: -1;
  }

  .decor {
    pointer-events: none;

    p,
    span {
      position: absolute;
      font-size: adaptive-calc(250px, 180px);
      line-height: 1;
      top: adaptive-calc(-53px, -44px);
      left: adaptive-calc(-17px, -11px);
      pointer-events: none;
    }
  }

  &__text {
    max-height: 262px;
    margin-bottom: 35px;

    .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }

    .simplebar-content-wrapper {
      padding-right: 17px;
    }

    p {
      color: var(--color-white);
      text-align: center;
    }
  }

  &__name {
    max-width: 107px;
    p {
      color: var(--color-white);
      font-size: adaptive-calc(17px, 14px);
      line-height: 1;
      margin-right: 12px;
      margin-bottom: adaptive-calc(24px, 12px);
    }
  }

  &__position {
    p {
      color: var(--color-white);
      font-size: adaptive-calc(17px, 14px);
      line-height: 1;
    }
  }

  &__box {
    display: flex;
    margin-top: auto;
    margin-left: adaptive-calc(32px, 25px);

    img {
      margin-right: adaptive-calc(16px, 10px);
      width: adaptive-calc(86px, 56px);
      height: adaptive-calc(86px, 56px);
      border-radius: 50%;
    }
  }
}

.lookout {
  position: relative;
  background-color: var(--color-blue-dark);

  .decor {
    pointer-events: none;

    img {
      &:first-child {
        width: adaptive-calc(140.03px, 50px);
        height: auto;
        top: -1px;
        left: -1px;
        transform-origin: top left;
      }

      &:last-child {
        width: adaptive-calc(140.03px, 50px);
        height: auto;
        bottom: -1px;
        right: -1px;
        transform-origin: bottom right;
      }
    }
  }

  &__inner {
    padding: adaptive-calc(179px, 40px) 0px adaptive-calc(169px, 40px);
    max-width: 769px;
    margin: 0 auto;
    text-align: center;

    p {
      color: var(--color-white);
    }

    a {
      color: var(--color-white);
      display: inline-flex;
      /*margin: 40px auto 0px;*/
      margin: 6px 0 0;
    }
  }

  &__title {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: var(--color-white);
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

.impact {
  margin: adaptive-calc(142px, 50px) 0 adaptive-calc(158px, 50px);

  .impact-box {
    margin-bottom: adaptive-calc(59px, 20px);

    &__cont {
      p {
        margin-bottom: adaptive-calc(39px, 20px);
      }
    }
  }

  &__slider {
    max-width: 100%;
    position: relative;
    height: adaptive-calc(424px, 250px);
  }

  &__slide-img {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      clip-path: polygon(
        0 0,
        92% 0,
        100% 21%,
        100% 100%,
        100% 100%,
        8% 100%,
        0 79%,
        0 0
      );
    }
  }

  &__slide-box {
    position: absolute;
    bottom: 0px;
    right: 0;
    background-color: var(--color-blue-dark);
    padding: adaptive-calc(17px, 7px) adaptive-calc(82px, 15px);

    img {
      width: adaptive-calc(212px, 100px);
      height: auto;
    }
  }
}

.insta {
  padding-bottom: adaptive-calc(144px, 110px);
  padding-top: 80px;

  .decor {
    pointer-events: none;

    img {
      width: adaptive-calc(195.5px, 100px);
      height: auto;
      bottom: 0;
      right: 0px;
      transform-origin: bottom right;
    }
  }

  &__soc {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 52px;
      line-height: 1;
    }
  }

  &__soc-box {
    position: relative;
    top: 3px;
    display: flex;

    line-height: 1;

    a {
      margin-right: 25px;
      transition: all 0.3s;

      &:hover i::before {
        color: var(--color-yellow-2);
        transition: all 0.3s;
      }

      i {
        font-size: 25px;
        transition: all 0.3s;

        &::before {
          color: var(--color-red);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__grid {
    margin-top: adaptive-calc(60px, 30px);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(223px, 1fr));
    grid-auto-flow: dense;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    justify-items: center;
    align-items: center;
  }

  &__grid-block {
    max-width: 278px;
    width: 100%;
    max-height: 278px;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.03);
      box-shadow: 0px 0px 15px 6px rgba(0, 0, 0, 0.2);
    }

    img {
      width: 100%;
      max-height: 278px;
    }
  }
}
