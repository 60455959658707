.team-member {
  &-card {
    .photo {
      @include photo-aspect-ratio(16, 11);
      border-radius: 20px;
      margin-bottom: 20px;

      img {
        @extend %default-transition;
      }
    }
    h5 {
      margin-bottom: 5px;
    }
    h6 {
      margin-bottom: 10px;
      line-height: 1.5;
    }

    &:hover {
      .photo img {
        transform: scale(1.12);
      }
    }

    &.square-photo {
      .photo {
        @include photo-aspect-ratio(1, 1);
      }
    }
  }

  &.first-section {
    .photo {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.main-section {
    margin-top: adaptive-calc(210px, 120px);
    margin-bottom: adaptive-calc(110px, 70px);

    .cont {
      columns: 2;
      column-gap: adaptive-calc(40px, 20px);

      @include media(901) {
        columns: 1;
      }
    }

    .team-member-block {
      -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
      page-break-inside: avoid; /* Firefox */
      break-inside: avoid; /* IE 10+ */

      padding: adaptive-calc(40px, 20px) adaptive-calc(40px, 20px);
      border-radius: adaptive-calc(40px, 20px);
      border: solid 2px var(--primary);

      margin-bottom: adaptive-calc(40px, 20px);

      h3 {
        margin-bottom: adaptive-calc(20px, 10px);
      }

      &:nth-of-type(1) {
        margin-top: adaptive-calc(-70px, -40px);
      }
    }
  }
}
