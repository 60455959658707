.job-board {
  padding: adaptive-calc(103px, 64px) 0 64px;
}

.job-board__cont {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: adaptive-calc(45px, 20px);

  @include media(601) {
    grid-template-columns: 1fr;
  }
}

.job-board__filters {
  font-family: Lexend;
  font-size: 18px;
  padding-bottom: 500px;

  & > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  input {
    color: var(--color-blue-dark);

    &::placeholder {
      color: var(--color-blue-dark);
    }
  }

  @include media(1600) {
    padding-bottom: 400px;
  }

  @include media(1281) {
    padding-bottom: 350px;
  }
}

.beefup.job-board__filter-accordion {
  margin: 0;
  border: none;
  border-radius: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-blue-dark);

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .beefup__head {
    padding: 0;

    &::after {
      display: none;
    }

    i {
      display: inline-block;
      margin-left: 14px;

      font-size: 10px;

      color: var(--color-green);

      transition: transform ease 250ms;
    }

    button {
      width: 100%;

      margin: 0;
    }
  }

  &.is-open {
    .beefup__head {
      i {
        transform: rotate(90deg);
      }
    }
  }

  .beefup__body {
    padding: 10px 0 0;

    width: 155px;
    right: 0;
  }
}

.job-board__filter-label {
  position: relative;
  display: flex;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.radio {
    .job-board__filter-checkbox:checked ~ .job-board__filter-style {
      &::before {
        content: "";

        background-color: var(--color-blue);
      }
    }
  }
}

.job-board__filter-checkbox {
  width: 20px;

  margin-right: 10px;
}

.job-board__filter-input {
  padding: 0 0 12px;
  border-bottom: 1px solid var(--color-blue-dark);
}

.job-board__filter-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.job-board__filter-checkbox ~ .job-board__filter-style::before {
  .job-board__filter-checkbox {
    opacity: 0;
  }
}
.job-board__filter-checkbox ~ .job-board__filter-style::before {
  content: "\2713";
  position: absolute;
  text-align: center;
  color: transparent;
  top: 4px;
  line-height: 1em;
  width: 1em;
  height: 1em;
  border: 2px inset var(--color-blue-dark);
  border-radius: 0.25em;
  margin-right: 10px;
  display: inline-block;
}
.job-board__filter-checkbox:checked ~ .job-board__filter-style::before {
  color: var(--color-blue);
  border: 2px solid var(--color-blue);
}

.job-board__filter-label-text {
  padding-left: 25px;
}

.btn.job-board__filter-cv-btn {
  margin-top: 29px;

  background: var(--color-yellow);
  padding: 10px;
  font-size: adaptive-calc(18px, 16px);
  width: 100%;
  justify-content: space-between;

  &:hover {
    background: var(--color-blue-dark);
  }

  &.clear-btn {
    background: var(--color-blue-dark);
    color: var(--white);
    &:hover {
      background: var(--color-green);
      color: var(--color-blue-dark);
      span {
        background: var(--color-blue-dark);
      }
    }
  }
}

.job-board__results {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-board__results-grid {
  display: grid;
  grid-template-columns: repeat(3, 313px);
  gap: 41px 18px;

  margin-bottom: 75px;

  @include media(1281) {
    grid-template-columns: repeat(2, 313px);
  }

  @include media(901) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media(769) {
    grid-template-columns: 313px;
  }
}

.job-board__card {
  position: relative;

  display: flex;
  flex-direction: column;

  min-height: 380px;

  padding: 38px 21px 102px;

  color: var(--color-white);
  background-color: var(--color-blue-dark);

  font-family: var(--font-main);
}

.job-board__card-title {
  color: var(--color-white);
  margin-bottom: 21px;
  font-size: adaptive-calc(25px, 20px);
  font-weight: 500;
}

.job-board__card-place {
  margin-bottom: 13px;

  font-size: 17px;
}

.job-board__card-info {
  margin-bottom: 38px;

  font-size: 16px;
  font-weight: 300;
}

.job-board__card-btns-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: auto;
}

.btn.job-board__card-btn {
  margin-right: 10px;

  color: var(--color-white);

  font-size: 17px;
  letter-spacing: -0.34px;
  white-space: nowrap;
}

.job-board__card-decor {
  position: absolute;
  left: -0.5px;
  bottom: -1px;
  // background-image: url("../images/decor/job-card-decor.svg");
  overflow: hidden;

  display: flex;

  width: calc(100% + 0.5px);
  // height: 65px;

  &--top {
    width: auto;

    left: unset;
    bottom: unset;
    top: -1px;
    right: 0;

    background-image: none;

    .job-board__card-decor-img {
      &:last-child {
        transform: none;
        margin-left: 0;
      }
    }
  }
}

.job-board__card-decor-img {
  &:last-child {
    transform: scale(-1, 1);
    margin-left: -1px;
  }
}

.job-board__results-pagination {
  display: flex;
  align-items: center;

  margin-bottom: adaptive-calc(149px, 40px);
}

.job-board__results-pagination-btn {
  font-size: 10.5px;

  transition: opacity ease 250ms;

  &:hover {
    opacity: 0.75;
  }
}

.job-board__results-pagination-bullets {
  display: flex;
  align-items: center;

  margin-left: 14px;
  margin-right: 14px;
}

.job-board__results-pagination-bullet {
  width: 13px;
  height: 13px;

  border: 1px solid var(--color-blue-dark);
  border-radius: 50%;

  transition: transform ease 250ms;

  &:hover {
    transform: scale(1.1);
  }

  &:not(:last-child) {
    margin-right: 11px;
  }

  &.active {
    background-color: var(--color-blue-dark);
  }
}

.job-board__bottom-wrap {
  position: relative;

  width: 100%;
  max-width: 768px;
  align-self: flex-end;
}

.job-board__info-block {
  margin-bottom: adaptive-calc(113px, 40px);
}

.job-board__info-title {
  margin-bottom: 11px;
}

.job-board__info-descr {
  margin-bottom: 28px;

  font-family: var(--font-main);
  font-size: 18px;
}

.job-board__bottom-decor {
  pointer-events: none;

  position: absolute;
  left: 0;
  bottom: 64px;

  transform: scale(-1, 1);

  @include media(601) {
    display: none;
  }
}

.job-board__bottom-decor-img {
  width: vw(281);
  max-width: 281px;
  min-width: 150px;
}
