.head {
  background-color: var(--color-blue-dark);
  min-height: adaptive-calc(400px, 270px);
  position: relative;
  padding-bottom: 30px;

  .dec {
    pointer-events: none;

    img {
      bottom: 0px;
      right: -4px;
      transform-origin: right bottom;
    }
  }

  &__cont {
    margin-right: 75px;
    h1 {
      font-size: adaptive-calc(70px, 30px);
      color: var(--color-white);
      font-weight: 600;
      padding-top: adaptive-calc(256px, 170px);
      letter-spacing: -1px;
    }
    &.head-team__cont {
      @include media(400) {
        margin-right: 0;
      }
    }
  }
}

.show {
  position: relative;

  .show-dec {
    pointer-events: none;

    img {
      position: absolute;
      top: 0;
      left: 0;
      max-width: adaptive-calc(218px, 88px);
      height: auto;
    }
  }

  &__text-block {
    max-width: 969px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    max-width: 280px;
    margin-left: adaptive-calc(5px, 0px);

    h1,
    h2,
    h3,
    h4 {
      font-weight: 600;
      font-size: adaptive-calc(34px, 22px);
      letter-spacing: 0.4px;

      span {
        background-color: var(--color-yellow-dark);
        padding: 2px 3px;
      }
    }
  }

  &__text {
    max-width: 572px;
    margin-top: adaptive-calc(10px, 25px);

    &.w-full {
      max-width: 100%;
    }

    p {
      font-weight: 400;
      font-size: adaptive-calc(18px, 14px);
    }
  }

  &__video {
    // margin-top: adaptive-calc(59px, 30px);
    margin: adaptive-calc(59px, 30px) auto 0;
    max-width: 1160px;
    // width: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*clip-path: polygon(
      20% -1px,
      92% -1px,
      100% 21%,
      100% 80%,
      100% 100%,
      8% 100%,
      0px 78%,
      0 -1px
    );*/

    .plyr {
      width: 100%;
      max-width: adaptive-calc(754px, 444px);
      height: adaptive-calc(424px, 250px);

      video,
      iframe {
        width: 100%;
        max-width: adaptive-calc(754px, 444px);
        // width: adaptive-calc(754px, 444px);
        height: adaptive-calc(424px, 250px);
      }

      &.plyr-hide-controls {
        .plyr__controls {
          opacity: 0;

          @include media(1024) {
            opacity: 1;
          }
        }
      }
    }

    .plyr__poster {
      background-size: cover;
      /*clip-path: polygon(
        20% -1px,
        92% -1px,
        100% 21%,
        100% 80%,
        100% 100%,
        8% 100%,
        0px 78%,
        0 -1px
      );*/
    }

    .plyr:fullscreen video {
      height: 100% !important;
      width: 100% !important;
    }

    /*.plyr--video .plyr__controls {
      display: none;
      width: 80%;
      margin: 0 auto;
    }*/

    .plyr__control--overlaid {
      background: var(--color-green-2);
      border: 0;
      border-radius: 0;
      color: #fff;
      color: var(--plyr-video-control-color, #fff);
      display: none;
      opacity: 1;
      padding: 15px;
    }

    .plyr--full-ui.plyr--video .plyr__control--overlaid {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .plyr__control--overlaid {
      font: inherit;
      line-height: inherit;
      width: 70px;
      height: 70px;

      @media only screen and (max-width: 901px) {
        width: 50px;
        height: 50px;
      }

      @media only screen and (max-width: 769px) {
        width: 40px;
        height: 40px;
      }
    }

    .plyr__control--overlaid {
      &::before {
        content: "";
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 65%;
        border: adaptive-calc(20px, 12px) solid transparent;
        border-left: adaptive-calc(25px, 16px) solid var(--color-white);
      }
    }

    .plyr__control--overlaid svg {
      display: none;
    }

    .plyr--video .plyr__control:hover {
      border-radius: 100%;
      background: var(--color-blue);
    }

    @include media(551) {
      max-width: 100%;
    }
  }
}

.career {
  .career-dec {
    pointer-events: none;

    img {
      position: absolute;
      top: 0;
      left: 0;
      max-width: adaptive-calc(218px, 88px);
      height: auto;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1062px;
    margin-left: 97px;

    &.wide {
      justify-content: center;

      margin: 0 auto;

      .career__video {
        max-width: 770px;
        height: adaptive-calc(450px, 300px);

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__text {
    max-width: 474px;
    width: 100%;
    margin-right: 40px;

    h2,
    h3,
    h4 {
      margin-bottom: adaptive-calc(15px, 9px);
      font-size: adaptive-calc(50px, 30px);
      font-size: adaptive-calc(44px, 26px);

      font-weight: 600;

      span {
        background-color: var(--color-green);
        padding: 2px 2px;
      }
    }

    p {
      margin-bottom: adaptive-calc(29px, 18px);
    }
  }

  &__video {
    max-width: 471px;
    width: 100%;
    margin-top: 23px;
    /*clip-path: polygon(
      20% 0,
      85% 0,
      100% 16%,
      100% 80%,
      100% 100%,
      16% 100%,
      0 83%,
      0 0
    );*/
  }
  .plyr--video {
    --plyr-color-main: var(--color-yellow);
    /*clip-path: polygon(
      20% 0,
      85% 0,
      100% 16%,
      100% 80%,
      100% 100%,
      16% 100%,
      0 83%,
      0 0
    );*/
  }

  .plyr video {
    // height: adaptive-calc(430px, 250px);
  }

  .plyr .plyr__video-wrapper {
    // height: adaptive-calc(430px, 250px);
    aspect-ratio: auto;
  }

  .plyr:fullscreen video {
    height: 100% !important;
  }

  .plyr__poster {
    background-size: cover;
  }
  .plyr--video.plyr--stopped .plyr__controls {
    display: none;
  }

  .plyr--full-ui.plyr--video .plyr__control--overlaid {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .plyr__control--overlaid {
    background: var(--color-yellow-2);
    border: 0;
    border-radius: 0;
    color: #fff;
    display: none;
    opacity: 1;
  }

  .plyr__control--overlaid {
    &::before {
      content: "";
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 66%;
      border: adaptive-calc(20px, 12px) solid transparent;
      border-left: adaptive-calc(25px, 16px) solid var(--color-white);
    }
  }
  .plyr__control--overlaid svg {
    display: none;
  }

  .plyr .plyr__control--overlaid {
    font: inherit;
    line-height: inherit;
    width: 70px;
    height: 70px;

    @media only screen and (max-width: 901px) {
      width: 50px;
      height: 50px;
    }

    @media only screen and (max-width: 769px) {
      width: 40px;
      height: 40px;
    }
  }

  .plyr--video .plyr__control:hover {
    border-radius: 100%;
    background: var(--color-blue);
  }
}

.title-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  h2 {
    // width: 42%;
    width: 37%;
    font-weight: 600;
  }

  &__cont {
    width: 58%;
    padding-left: 5px;

    p {
      margin-bottom: adaptive-calc(23px, 17px);
    }

    .btn-block {
      margin-top: 10px;
    }
  }
}

.team-card {
  &__img {
    max-width: 278px;
    clip-path: polygon(
      16% 0%,
      100% 0%,
      100% 0,
      100% 83%,
      84% 100%,
      0 100%,
      0 100%,
      0% 16%
    );
    overflow: hidden;
    transition: transform 250ms ease;
    img {
      object-fit: cover;
      transition: transform 300ms ease;
    }
  }
  &__name {
    h3 {
      margin-top: adaptive-calc(18px, 12px);
      font-family: Lexend;
      font-weight: 500;
      font-size: adaptive-calc(25px, 18px);
      line-height: 1.3;
    }
  }

  &__position {
    h3 {
      font-size: adaptive-calc(21px, 14px);
      line-height: adaptive-calc(24px, 18px);
      color: var(--color-red);
      font-weight: 400;
    }
  }

  &__links {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }

  &__link-box {
    a {
      display: inline-block;
      margin-right: adaptive-calc(6px, 5px);

      &:last-child {
        margin-right: 3px;
      }
    }
  }

  &__link-none {
    a {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }

  &__link {
    i {
      &::before {
        color: var(--color-blue-dark);
      }
    }

    .icon-email {
      font-size: adaptive-calc(22px, 18px);
    }

    .icon-twitter {
      font-size: adaptive-calc(22px, 18px);
    }

    .icon-linkedin {
      font-size: adaptive-calc(19px, 17px);
    }
  }

  &__link i:hover {
    &::before {
      transition: all 0.3s ease-in-out;
      color: var(--color-blue);
    }
  }
}

.slider-control {
  position: relative;
  max-width: 190px;
  margin: 0 auto;
  height: 13px;

  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      display: none;
    }
  }
  .slider-control__prev,
  .slider-control__next {
    i {
      transition: all 0.3s ease-in-out;
      color: var(--color-blue-dark);
    }

    &:hover i {
      transform: scale(1.2);
    }
  }

  .slider-control__pagination {
    bottom: 0;
  }

  .slider-control__next {
    right: 0;
  }

  .slider-control__prev {
    left: 0;
  }

  .swiper-pagination-bullet {
    border: 1px solid var(--color-blue-dark);
    margin: 0 7px 0 0 !important;
  }
  .swiper-pagination-bullet-active {
    background: var(--color-blue-dark);
  }

  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 51%;
  }
}

.soc {
  p {
    font-size: adaptive-calc(17px, 16px);
    font-weight: 700;
  }

  &__box {
    margin-top: 15px;
    display: flex;
    a {
      margin-right: 18px;
      &:last-child {
        margin-right: 0;
      }
      i {
        font-size: 20px;
        transition: all 0.3s;
        &::before {
          color: var(--color-blue);
        }
      }
      &:hover i::before {
        color: var(--color-green-2);
      }
    }
  }
}

.candidate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: adaptive-calc(33px, 15px) 13px adaptive-calc(17px, 15px) 13px;
  background-color: var(--color-blue-dark);
  max-width: 278px;
  min-height: adaptive-calc(200.76px, 150px);
  clip-path: polygon(
    0 0,
    83% 0%,
    100% 22%,
    100% 100%,
    100% 100%,
    15% 100%,
    0% 80%,
    0 0
  );

  h4 {
    text-align: center;
    color: var(--color-white);
    font-size: adaptive-calc(32px, 21px);
    line-height: 1.4;
    font-weight: 500;
  }

  a,
  button {
    margin-top: adaptive-calc(27px, 18px);
    color: var(--color-white);

    span {
      &::before {
        border-left: 13px solid var(--color-blue-dark);
      }
    }
  }
}
