@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --color-white: #fff;
  --color-blue: #0280ad;
  --color-blue-2: #0f80ad;
  --color-blue-dark: #01013d;
  --color-yellow: #fda200;
  --color-yellow-2: #ffa300;
  --color-yellow-dark: #fea32d;
  --color-green: #00a217;
  --color-green-2: #219e2a;
  --color-red: #a7005c;
  --color-gray: #5e5b72;

  --header-height: 162px;
  --header-height-scrolled: calc(var(--header-height) - 24px);
  --font-main: "Lexend", sans-serif;

  --plyr-color-main: var(--color-blue);
}
