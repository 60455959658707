.resources-section {
  padding: 79px 0 adaptive-calc(195px, 100px);
  min-height: 600px;
  .cont {
    width: 94.2%;
    max-width: 1356px;
    overflow: hidden;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    &.cont {
      overflow: visible;
    }

    .beefup.job-board__filter-accordion {
      position: relative;
    }

    .beefup__body {
      position: absolute;
      margin-top: 12px !important;
      width: 100%;
      border: 1px solid var(--color-blue-dark);
      padding: 8px !important;
      background: #fff;
      z-index: 10;

      a {
        margin-bottom: 4px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.resources-cont {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: min(max(20px + 25 * ((100vw - 768px)/672), 20px), 45px);

  @include media(769) {
    grid-template-columns: 1fr;
  }
}

.recources-section__title {
  margin-bottom: adaptive-calc(39px, 20px);

  text-align: center;
}

.resources-slider {
  width: 572px;
  margin: 0 auto;

  @include media(768) {
    width: 100%;
  }
}

.resources-slider__card {
  margin: 0 8px;

  &:hover {
    .btn {
      &.yellow {
        color: var(--color-yellow-2);
      }
      &.blue {
        color: var(--color-blue);
      }
      &.red {
        color: var(--color-red);
      }
    }
  }
}

.resources-slider__card-img {
  width: 100%;
  height: 260px;
  object-fit: cover;

  margin-bottom: adaptive-calc(23px, 15px);

  @include media(768) {
    height: auto;
  }
}

.resources-slider__card-type {
  margin-bottom: 1px;

  font-size: adaptive-calc(20px, 17px);

  &.yellow {
    color: var(--color-yellow);
  }

  &.blue {
    color: var(--color-blue);
  }

  &.green {
    color: var(--color-green);
  }

  &.red {
    color: var(--color-red);
  }
}

.resources-slider__card-title {
  max-width: 376px;

  margin-bottom: 8px;

  font-size: adaptive-calc(26px, 20px);
  line-height: 1.73;
}

.resources-pagination {
  display: flex;
  align-items: center;
  grid-column: 1 / -1;

  width: fit-content;

  margin: 0 auto;
}

.resources-pagination-btn {
  margin-left: 14px;

  font-size: 10.5px;

  color: var(--color-blue-dark);

  transition: opacity ease 250ms;

  cursor: pointer;

  &.prev {
    margin-right: 14px;
    margin-left: 0;
  }

  &:hover {
    opacity: 0.75;
  }
}

.resources-pagination-pages {
  display: flex;
  align-items: center;

  .active {
    display: flex;
    align-items: center;

    height: 13px;

    margin-right: 7px;

    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;

    transform: translateY(0.5px);
  }

  .resources-pagination-bullet {
    width: 13px;
    height: 13px;

    border: 1px solid var(--color-blue-dark);
    border-radius: 50%;

    transition: transform ease 250ms;

    cursor: pointer;

    &:not(:last-child) {
      margin-right: 7px;
    }

    &:hover {
      transform: scale(1.1);
    }

    &.active {
      background-color: var(--color-blue-dark);
    }
  }
}

.recources-section__decor {
  pointer-events: none;
  display: none;
  position: absolute;
  bottom: 150px;

  width: vw(222);
  max-width: 222px;

  &--interim {
    .recources-section__decor-img {
      transform: translateY(45%) scale(-1, 1);
    }
  }

  &--interview {
    right: 0;

    .recources-section__decor-img {
      transform: translateY(30%);
    }
  }
}

.signup-section {
  padding-bottom: 100px;

  .cont {
    width: 67%;
    max-width: 964px;
  }
}

.signup-section__title {
  margin-bottom: adaptive-calc(33px, 20px);

  font-size: adaptive-calc(50px, 25px);
}

.signup-section__form {
  display: flex;
  margin-bottom: 21px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-blue-dark);
  // margin-left: adaptive-calc(26px, 0px);
}

.signup-section__input {
  margin-right: 10px;
  padding: 0;
  color: var(--color-blue-dark);
  caret-color: var(--color-blue-dark);

  &::placeholder {
    color: var(--color-blue-dark);
  }

  &:focus {
    border-color: transparent;
  }
}

.btn.signup-section__btn {
  white-space: nowrap;

  span {
    @include media(551) {
      margin-left: 0;
    }
  }
}

.signup-section__btn-text {
  @include media(551) {
    display: none;
  }
}

.signup-section__form-descr {
  font-size: 12px;
  margin-left: auto;
  width: fit-content;
  transition: all 0.4s ease-in-out;
  &:hover {
    color: var(--color-red);
  }
}

.signup-section__decor {
  position: absolute;
  bottom: -1px;

  width: vw(204);
  max-width: 204px;
}

.interim-resources-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @include media(1281) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media(769) {
    grid-template-columns: 1fr;
  }
}

.resources__filters {
  padding-bottom: 0 !important;
}

.interim-resources-list {
  width: 100%;
  // max-width: 768px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  height: fit-content;

  @include media(769) {
    grid-template-columns: 1fr;
  }

  // display: grid;
  // grid-template-columns: 1fr;
  // grid-gap: 20px;
}

.resources-list-item {
  // margin-bottom: 100px;
  // display: flex;
  // min-height: 195px;

  img {
    margin-bottom: 16px;
  }

  @include media(551) {
    // flex-direction: column;
    // height: auto;
    margin-bottom: 50px;
  }
}

.resources-list-item__title {
  line-height: 1.4em;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.resources-list-item__descr {
  margin-bottom: 10px;
}

.resources-list-item {
  display: flex;
  flex-direction: column;
}

.resources-list-item__text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.resources-list-item__btn {
  // margin-top: auto;
}

// .resources-list-item__img {
//   width: 30%;
//   min-width: 250px;

//   object-fit: cover;

//   margin-right: 20px;

//   @include media(551) {
//     width: 100%;
//     height: 230px;
//   }
// }

// .resources-list-item__title {
//   @include max-line-leng(2);

//   margin-bottom: 7px;

//   line-height: 1.23;
// }

// .resources-list-item__text {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
// }

// .resources-list-item__date {
//   margin-bottom: 7px;

//   font-size: 12px;
// }

// .resources-list-item__descr {
//   @include max-line-leng(3);

//   margin-bottom: 7px;
// }

// .resources-list-item__btn {
//   margin-top: auto;
//   margin-left: auto;
// }

.resources-list-item__author {
  margin-top: auto;
  margin-bottom: 4px;
}
