.show-about {
  padding: adaptive-calc(58px, 50px) 0px adaptive-calc(87px, 50px);
}

.values {
  background-color: var(--color-blue-dark);
  position: relative;

  &.js-values-dynamic-title {
    .values__title {
      h2 {
        position: absolute;
        left: 0;
        padding-left: adaptive-calc(76px, 55px);

        @include media(769) {
          position: static !important;
          padding-left: 0;
        }
      }
    }
  }

  .decor {
    pointer-events: none;

    img {
      &:first-child {
        width: adaptive-calc(140px, 35px);
        height: auto;
        bottom: -1px;
        left: -1px;
        transform-origin: top left;
      }

      &:last-child {
        width: adaptive-calc(140px, 35px);
        height: auto;
        top: -1px;
        right: -5px;
        transform-origin: bottom right;
      }
    }
  }

  &__inner {
    padding: adaptive-calc(86px, 50px) 0px adaptive-calc(70px, 50px);
  }

  &__title {
    h2 {
      text-align: center;
      color: var(--color-white);
      font-weight: 600;
      font-size: adaptive-calc(34px, 22px);
      letter-spadaptive-calcing: -1px;
    }
  }

  &__block {
    margin-top: 48px;
  }

  &__nav {
    h2 {
      color: #ffffff5c;
    }
  }

  &__item {
    h2,
    .tab__item-text {
      color: var(--color-white);

      *,
      p {
        color: var(--color-white);
      }
    }

    //.tab__item-text {
    //  overflow: auto;
    //
    //  max-height: 190px;
    //}
  }
}

.cards-about {
  position: relative;
  padding: adaptive-calc(150px, 100px) 0px adaptive-calc(189px, 100px);

  @include media(1100) {
    padding-top: 160px;
  }

  .decor {
    pointer-events: none;

    img {
      top: 3px;
      right: -7px;
      transform-origin: bottom right;
      width: 126.72px;
      height: auto;

      @include media(1350) {
        top: -20px;
        right: -20px;
      }
    }
  }
}

.team-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__links {
    flex: 1;
    align-items: flex-end;
  }
}

.team-s {
  padding-bottom: adaptive-calc(157px, 80px);
  .team-s-box {
    margin-bottom: adaptive-calc(64px, 30px);
    h2 {
      margin-top: 2px;
    }
    &__cont {
      p {
        margin-bottom: adaptive-calc(35px, 17px);
      }
    }
  }

  &__slider {
    max-width: 100%;
    padding-bottom: 52px;
    margin: 0 auto;
    clip-path: inset(0 0 0 0);

    @include media(1024) {
      clip-path: none;
      max-width: 278px;
    }
  }

  &__slide {
    /*    opacity: 0.5;*/
    height: auto;

    @include media(1024) {
      opacity: 0.5;
    }
  }
  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide-next,
  .swiper-slide-prev {
    opacity: 1;
  }

  &__slider.swiper {
    overflow: visible;
  }

  &__control {
    margin-top: 2px;
  }

  .slider-control__prev {
    left: 0;
  }

  .slider-control__next {
    right: 0;
  }

  + .clients.clients-about {
    margin-top: 0;
  }
}

.progress {
  &-section {
    padding: adaptive-calc(104px, 50px) 0 adaptive-calc(40px, 20px);
    background-color: var(--color-blue-dark);

    &.firefox,
    &.safari {
      .progress-item:nth-child(odd) {
        .progress-item_box {
          &::before {
            transform: translateX(calc(-50% - 4px));
          }

          @include media(769) {
            &::before {
              left: -33px !important;
              top: 20px !important;
              transform: translateX(-50%) !important;
            }
          }
        }
      }

      .progress-item:nth-child(even) {
        .progress-item_box {
          &::before {
            transform: translateX(calc(50% - 4px));
          }

          @include media(769) {
            &::before {
              left: -33px !important;
              top: 20px !important;
              transform: translateX(-50%) !important;
            }
          }
        }
      }
    }

    .decor {
      pointer-events: none;

      img {
        width: adaptive-calc(140px, 40px);
        height: auto;

        &:first-child {
          bottom: -1px;
          left: -1px;
          transform-origin: top left;
        }

        &:last-child {
          top: -1px;
          right: -5px;
          transform-origin: bottom right;
        }
      }
    }

    h2 {
      color: var(--color-white);
      text-align: center;
      margin-bottom: adaptive-calc(82px, 40px);
    }
  }

  &-chain {
    position: relative;
    max-width: 1062px;
    margin: 0 auto;
  }

  &-items {
    max-height: 456px;
    overflow: auto;

    // padding: 0 10px;

    @media only screen and (max-width: 769px) {
      max-height: 230px;
    }
  }

  &-item {
    display: flex;

    .color-1 {
      h4 {
        color: var(--color-yellow);
      }

      &::before {
        background-color: var(--color-yellow);
      }
    }

    .color-2 {
      h4 {
        color: var(--color-green);
      }
      &::before {
        background-color: var(--color-green);
      }
    }

    .color-3 {
      h4 {
        color: var(--color-blue);
      }
      &::before {
        background-color: var(--color-blue);
      }
    }

    .color-4 {
      h4 {
        color: var(--color-red);
      }
      &::before {
        background-color: var(--color-red);
      }
    }

    &_box {
      width: 50%;
    }

    span {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 23px;
        height: 23px;
        border-radius: 50%;
      }
    }

    h4 {
      width: 50%;
      flex-shrink: 0;
      color: var(--color-white);
      font-size: adaptive-calc(34px, 22px);
      line-height: 1.7;
      position: relative;
    }

    p {
      flex-shrink: 0;

      width: 50%;
      color: var(--color-white);
    }

    &:not(:first-child) {
      margin-top: adaptive-calc(140px, 40px);
    }

    &:not(:last-of-type) {
      margin-bottom: adaptive-calc(12px, 60px);
    }

    &:nth-child(odd) {
      span {
        position: relative;
        &::before {
          left: 100%;
          top: 38px;
          transform: translateX(-50%);
        }
      }
      h4 {
        width: 63%;
        margin-left: auto;
        padding-right: 106px;
        line-height: 1.5;
        margin-top: 11px;
      }

      p {
        padding-left: 63px;
        padding-right: 10px;
      }
    }

    &:nth-child(even) {
      span {
        position: relative;
        &::before {
          right: 100%;
          top: 35px;
          transform: translateX(50%);
        }
      }
      h4 {
        text-align: left;
        position: relative;
        padding-left: 63px;
      }
      p {
        padding-right: 57px;
      }
      margin-left: auto;
      flex-direction: row-reverse;
    }

    @media only screen and (max-width: 769px) {
      max-width: 100%;
      flex-direction: column;

      padding-right: 10px;
      p {
        padding: 0;
        width: 100%;
      }

      &_box {
        width: 100%;
      }
      span {
        position: relative;
        &::before {
          width: 18px;
          height: 18px;
        }
      }

      &:nth-child(odd),
      &:nth-child(even) {
        span {
          &::before {
            left: -33px;
            top: 20px;
            transform: translateX(-50%);
          }
        }
        h4 {
          width: 100%;
          text-align: left;
        }
        margin-left: 45px;
        text-align: left;
        flex-direction: column;
      }
      &:nth-child(even) {
        span::before {
          top: 10px;
        }
        h4 {
          padding-left: 0;
        }
      }
      &:nth-child(odd) {
        h4 {
          margin-left: inherit;
        }
        p {
          padding-left: 0;
        }
      }
    }
  }

  &-line {
    width: 4px;
    height: 100%;
    position: absolute;
    background-color: var(--color-white);
    top: 0;
    left: calc(50% - 5px);
    transform: translateX(-25%);
    overflow: hidden;

    //.fill {
    //      width: 100%;
    //      height: 10%;
    //      background-color: var(--color-blue);
    //}

    @media only screen and (max-width: 769px) {
      left: 10px;
      transform: none;
    }
  }
}

.clients-about {
  margin: adaptive-calc(166px, 60px) 0 adaptive-calc(76px, 25px);
}

.clients {
  &__box {
    margin-bottom: adaptive-calc(85px, 40px);
    justify-content: space-between;
  }
  &__box-cont {
    p {
      margin-bottom: adaptive-calc(20px, 10px);
    }

    .btn-block {
      a {
        margin-top: 16px;
      }
    }
  }

  &-slider {
    /*max-width: 244px;*/
    max-width: 100%;
    // overflow: hidden;

    clip-path: inset(0 0 0 0);

    @include media(1024) {
      /*clip-path: none;*/
      /*max-width: 244px;*/
    }
  }
  &-slider.swiper {
    overflow: visible;

    .swiper-wrapper {
      align-items: center;
    }
  }

  &-slide {
    // transition: opacity ease 250ms;

    img {
      // max-width: 244px;
      width: 100%;
      max-height: 150px;
      object-fit: contain;
    }

    // &.swiper-slide-active,
    // &.swiper-slide-prev,
    // &.swiper-slide-next,
    // &.swiper-slide-next {
    //   opacity: 1;
    // }
  }
}

.video-sec {
  padding: adaptive-calc(135px, 50px) 0 adaptive-calc(220px, 120px);

  position: relative;

  .decor {
    pointer-events: none;

    img {
      &:first-child {
        width: adaptive-calc(237.24px, 100px);
        height: auto;
        top: 0;
        right: 0px;
        transform-origin: top right;

        @include media(1280) {
          top: 20px;
        }
      }

      &:last-child {
        width: adaptive-calc(195.5px, 100px);
        height: auto;
        bottom: 0;
        left: 0px;
        transform-origin: bottom left;
      }
    }
  }

  &__box {
    margin-bottom: adaptive-calc(91px, 40px);
  }
  &__cont {
    p {
      margin-bottom: adaptive-calc(30px, 20px);
      max-width: 545px;
    }
  }
}
