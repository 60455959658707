.our-process {
  position: relative;

  padding: 140px 0;

  color: var(--color-white);
  background-color: var(--color-blue-dark);

  &.slider-is-visible {
    .our-process__line {
      opacity: 1;
      visibility: visible;
    }

    // .our-process__back-btn {
    //   opacity: 1;
    //   visibility: visible;
    // }

    .our-process__main-content {
      opacity: 0;
      visibility: hidden;
    }
  }

  .cont {
    position: relative;

    width: 67%;
  }
}

.our-process__line {
  content: "";
  position: absolute;
  // top: 385px;

  display: block;

  width: 100%;
  height: 5px;

  background-color: var(--color-white);

  transition: opacity 250ms ease, visibility 250ms ease;

  opacity: 0;
  visibility: hidden;
}

.our-process__main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: opacity 250ms ease, visibility 250ms ease;
}

.our-process__title {
  margin-bottom: 34px;

  color: var(--color-white);
}

.our-process__descr {
  max-width: 572px;

  margin-bottom: 67px;

  font-family: var(--font-main);
  font-size: adaptive-calc(18px, 14px);
  line-height: 1.77;
  text-align: center;

  * {
    color: var(--color-white);
    font-family: var(--font-main);
    font-size: adaptive-calc(18px, 14px);
    line-height: 1.77;
    text-align: center;
  }
}

.our-process__btns-wrap {
  display: flex;
}

.btn.our-process__btn {
  &:not(:last-child) {
    margin-right: 50px;
  }

  span::before {
    border-left: 13px solid var(--color-blue-dark);
  }
}

.our-process__decor {
  position: absolute;

  width: vw(139);
  max-width: 139px;

  &--top-left {
    top: -1px;
    left: 0;
  }

  &--right-bottom {
    bottom: -1px;
    right: 0;
  }
}

.our-process__slider {
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 1;

  width: 100%;

  opacity: 0;
  visibility: hidden;

  transition: opacity 250ms ease, visibility 250ms ease;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  @include media(769) {
    top: 50%;
    transform: translateY(-50%);
  }

  .swiper-slide {
    width: 278px;
  }
}

.our-process__slide {
  &.orange {
    .our-process__slide-num {
      color: var(--color-yellow-2);

      &::after {
        background-color: var(--color-yellow-2);
      }
    }
  }

  &.green {
    .our-process__slide-num {
      color: var(--color-green-2);

      &::after {
        background-color: var(--color-green-2);
      }
    }
  }

  &.blue {
    .our-process__slide-num {
      color: var(--color-blue-2);

      &::after {
        background-color: var(--color-blue-2);
      }
    }
  }
}

.our-process__slide-num {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: fit-content;

  margin-bottom: 44px;

  font-size: 70px;

  &::after {
    content: "";
    display: block;

    width: 23px;
    height: 23px;

    margin-top: 29px;
    border-radius: 50%;

    background-color: var(--color-white);
  }
}

.our-process__slide-text {
  font-family: var(--font-main);
  font-size: 18px;
}

.btn.our-process__back-btn {
  margin: 20px auto 0;
}
