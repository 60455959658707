.btn-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.btn {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: adaptive-calc(22px, 16px);
  font-weight: 600;
  line-height: 1.4090909090909092em;
  margin-right: adaptive-calc(35px, 22px);
  cursor: pointer;
  @extend %default-transition;

  &:last-child {
    margin-right: 0;
  }

  &.blue {
    &:hover {
      color: var(--color-blue);
    }

    span {
      background-color: var(--color-blue);
    }
  }

  &.yellow {
    &:hover {
      color: var(--color-yellow-2);
    }

    span {
      background-color: var(--color-yellow-2);
    }
  }

  &.red {
    &:hover {
      color: var(--color-red);
    }

    span {
      background-color: var(--color-red);
    }
  }

  &.green {
    &:hover {
      color: var(--color-green-2);
    }

    span {
      background-color: var(--color-green-2);
    }
  }

  &.size-2 {
    font-size: adaptive-calc(17px, 12px);
    font-weight: 700;
    line-height: 1.2941176470588236em;

    span {
      width: adaptive-calc(24px, 18px);
      height: adaptive-calc(24px, 18px);
      margin-left: adaptive-calc(12px, 8px);

      &::before {
        border-left-width: adaptive-calc(8px, 7px);
        border-top-width: adaptive-calc(5px, 4px);
        border-bottom-width: adaptive-calc(5px, 4px);
        margin-left: 1px;
      }
    }
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: adaptive-calc(32px, 25px);
    height: adaptive-calc(32px, 25px);
    background-color: var(--color-blue-dark);
    margin-left: adaptive-calc(12px, 24px);

    &::before {
      position: absolute;
      content: "";
      display: inline-block;
      border-left: 13px solid var(--color-white);
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      margin-left: 2px;
    }

    &.icon-dark {
      &::before {
        border-left-color: var(--color-blue-dark);
      }
    }
  }

  &.invert {
    color: var(--color-white);

    span {
      ::before {
        border-left: 13px solid var(--color-blue-dark);
      }
    }
  }
}

.swiper-button-lock {
  display: none;
}
